import React from "react";
import "./Table.css";
import { Link } from "react-router-dom";
import { formatDate } from "../../helper/utils";
import { RotatingLines } from "react-loader-spinner";

export default function Table(props) {
    const { fields, data, loading, onDelete, deleteModalVisible } = props;
    const onDeleteData = (submissionId) => {
        const data = {
            deleteModalVisible: !deleteModalVisible,
            submissionId
        };
        onDelete(data);
    }
    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        {fields.map((field, i) => (
                            <th key={i} scope="col">
                                {field}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td colSpan={fields.length}>
                                <RotatingLines
                                    strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="50"
                                    height="50"
                                    visible={loading}
                                    className="img-thumbnail mt-5"
                                />
                            </td>
                        </tr>
                    )}
                    {!loading &&
                        data?.length > 0 &&
                        data.map((item, key) => (
                            <tr key={key}>
                                <td>{formatDate(item?.created_at) || ""}</td>
                                <td>{item?.status}</td>
                                <td>
                                    <Link
                                        to={`/pengajuan-seminar-skripsi/${item.id}`}
                                        className="btn btn-secondary"
                                    >
                                        Lihat
                                    </Link>
                                </td>
                                <td>
                                    {
                                        ['PENDING', 'REVISI'].includes(item.status) && (
                                            <Link
                                                to={`/pengajuan-seminar-skripsi/update/${item.id}`}
                                                className="btn btn-primary"
                                            >
                                                Ubah
                                            </Link>
                                        )
                                    }
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => onDeleteData(item.id)}
                                    >
                                        Hapus
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
