import React, { useEffect, useRef, useState } from "react";
import { BackButton, Footer, ModalPopup, Navbar } from "../../components";
import { useAuth } from "../../util/ProtectedRoute";
import { Submission } from "../../services";
import { useNavigate, useParams } from "react-router-dom";
import {
    downloadReport,
    formatDate,
    getImage,
} from "../../helper/utils";
import ListDocument from "./ListDocument";
import ListReport from "./ListReport";
import { toast } from "react-toastify";

export default function DetailSubmission() {
    const submission_service = new Submission();

    const { id } = useParams();
    const navigate = useNavigate();
    const ctx = useAuth();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    const [reportUrl, setReportUrl] = useState("");
    const [showDocument, setShowDocument] = useState(false);
    const [typeDocument, setTypeDocument] = useState("");
    const [loading, setLoading] = useState(true);
    const [submission, setSubmission] = useState(null);
    const [irsImage, setIrsImage] = useState(null);
    const [showDocumentList, setShowDocumentList] = useState(false);
    const [showIrsFile, setShowIrsFile] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState("");

    useEffect(() => {
        (async () => getSubmission())();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getSubmission = async () => {
        try {
            const resp = await submission_service.detail(id);
            const irsFile = await getImage(resp.data.irs_file);
            let file =
                "https://drive.google.com/u/0/uc?id=1H9r5GfQQt7K8cGABWkxtsbLb3drgFH-u&export=download";
            if (user?.level === "DSN" && resp.data.laporan_file) {
                file = await downloadReport(resp.data.laporan_file);
            }

            setReportUrl(file);
            setSubmission({ ...resp.data });
            setIrsImage(irsFile);
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
            navigate(-1);
        }
    };

    const handleShowDocument = (type) => {
        setShowDocument(!showDocument);
        setTypeDocument(type);
    };

    const handleIrsFile = () => {
        setShowIrsFile(!showIrsFile);
    };
    const setData = (status) => {
        setStatus(status);
        setShowModal(!showModal);
    };
    const catatan_dosen_p1 = submission?.catatan_penilaian?.find(x => x.id_dosen == submission?.dosen_p1?.id)?.catatan;
    const catatan_dosen_p2 = submission?.catatan_penilaian?.find(x => x.id_dosen == submission?.dosen_p2?.id)?.catatan;
    const catatan_dosen_uji1 = submission?.catatan_penilaian?.find(x => x.id_dosen == submission?.dosen_uji1?.id)?.catatan;
    const catatan_dosen_uji2 = submission?.catatan_penilaian?.find(x => x.id_dosen == submission?.dosen_uji2?.id)?.catatan;
    const data = {
        "Nama Mahasiswa": submission?.mahasiswa?.nama,
        NPM: submission?.mahasiswa?.nim,
        "Program Studi": submission?.mahasiswa?.jurusan?.nama,
        "Tanggal Pengajuan":
            submission?.created_at && formatDate(submission?.created_at),
        "Jenis Pengajuan": submission?.tipe,
        "IPK Terakhir": submission?.ipk,
        "Jumlah SKS Lulus": submission?.sks_lulus,
        "Bidang Penelitian": submission?.bidang_penelitian?.name,
        "Topik Yang Diajukan": submission?.topik,
        "Deskripsi Rencana Penelitian": (
            <div dangerouslySetInnerHTML={{ __html: submission?.deskripsi }} />
        ),
        "Judul Dalam Bahasa Indonesia": submission?.judul_id,
        "Judul Dalam Bahasa Inggris": submission?.judul_en,
        "Skema Penelitian": submission?.skema_penelitian,
        "Abstract Dalam Bahasa Indonesia": (
            <div dangerouslySetInnerHTML={{ __html: submission?.abstrak_id }} />
        ),
        "Abstract Dalam Bahasa Inggris": (
            <div dangerouslySetInnerHTML={{ __html: submission?.abstrak_en }} />
        ),
        "Dosen Ketua Grup Riset": submission?.dosen_ketua?.nama,
        "Pengambilan Ke": submission?.pengambilan_ke,
        "Dosen Pembimbing Sebelumnya":
            submission?.dosen_pembimbing_sebelumnya?.nama,
        "Alasan Mengulang": submission?.alasan_mengulang,
        "Dosen Pembimbing 1": submission?.dosen_p1?.nama,
        "Dosen Pembimbing 2": submission?.dosen_p2?.nama,
        "Dosen Penguji 1": submission?.dosen_uji1?.nama,
        "Dosen Penguji 2": submission?.dosen_uji2?.nama,
        "Jadwal Sidang":
            submission?.tanggal_sidang &&
            `${formatDate(
                new Date(submission?.tanggal_sidang).toISOString(),
                true
            )} WIB`,
        "Ruang Sidang": submission?.ruang,
        Status: submission?.status,
        "Catatan Dosen Pembimbing 1": catatan_dosen_p1 ? <div dangerouslySetInnerHTML={{ __html: catatan_dosen_p1}}/> : "-",
        "Catatan Dosen Pembimbing 2": catatan_dosen_p2 ? <div dangerouslySetInnerHTML={{ __html: catatan_dosen_p2}}/> : "-",
        "Catatan Dosen Penguji 1": catatan_dosen_uji1 ? <div dangerouslySetInnerHTML={{ __html: catatan_dosen_uji1}}/> : "-",
        "Catatan Dosen Penguji 2": catatan_dosen_uji2 ? <div dangerouslySetInnerHTML={{ __html: catatan_dosen_uji2}}/> : "-",
        "Bukti Pengisian IRS Siak-NG": (
            <>
                <button
                    className="btn btn-info text-white"
                    onClick={handleIrsFile}
                >
                    Lihat
                </button>
            </>
        ),
    };

    const handleBackButton = () => {
        if (showDocument) {
            return setShowDocument(!showDocument);
        }
        navigate(-1);
    };
    const onSubmit = async () => {
        setLoading(true);

        try {
            await submission_service.scheduleDosenPenguji(
                submission.id,
                status
            );
            toast.success("Data berhasil disubmit");
            setLoading(false);
            setShowModal(!showModal);
            navigate('/homepage')
        } catch (e) {
            console.log({ e });
            toast.error(e.message);
            setLoading(false);
        }
    };
    return (
        <React.Fragment>
            {user.level === "DSN" && (
                <ModalPopup
                    visible={showModal}
                    onClose={() => setShowModal(!showModal)}
                    height={300}
                >
                    <div className="mt-5 content">
                        <h2>
                            Apakah anda yakin ingin{" "}
                            {status ? "menyetujui" : "menolak"}{" "}
                            <b>{submission?.mahasiswa?.nama}</b> pada sidang
                            ini?
                        </h2>
                        <div className="d-flex justify-content-end">
                            <button
                                type="button"
                                className="btn btn-info mx-3"
                                disabled={loading}
                                onClick={onSubmit}
                            >
                                Ya
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => setShowModal(!showModal)}
                                disabled={loading}
                            >
                                Tidak
                            </button>
                        </div>
                    </div>
                </ModalPopup>
            )}
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>{submission?.judul_id}</label>
            </div>
            <div className="container my-3">
                <ModalPopup
                    visible={showIrsFile}
                    onClose={() => setShowIrsFile(!showIrsFile)}
                    height={600}
                >
                    <div className="mt-5 content">
                        <img
                            src={irsImage}
                            className="img img-thumbnail"
                            alt="irs_file"
                        />
                    </div>
                </ModalPopup>
                <BackButton onClick={handleBackButton} />
                {showDocument && typeDocument === "document" ? (
                    <ListDocument submissionId={id} />
                ) : showDocument && typeDocument === "report" ? (
                    <ListReport
                        submissionId={id}
                        fileReport={submission?.laporan_file}
                    />
                ) : (
                    <React.Fragment>
                        <div className="d-flex justify-content-evenly">
                            <button
                                className="btn btn-info col-md-4 text-white fw-bold"
                                onClick={() => handleShowDocument("document")}
                                disabled={!(submission?.status === "TERJADWAL")}
                            >
                                Dokumen
                            </button>
                            <button
                                className="btn btn-info col-md-4 text-white fw-bold"
                                onClick={() => handleShowDocument("report")}
                                disabled={
                                    user?.level === "MHS"
                                        ? !(submission?.status === "LULUS")
                                        : !(submission?.status !== "PENDING")
                                }
                            >
                                Laporan
                            </button>
                        </div>
                        {!loading &&
                            submission &&
                            Object.entries(data).map(([key, value]) => (
                                <div
                                    className="border-2 border-top mt-4"
                                    key={key}
                                >
                                    <div className="container pt-4">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <label className="fw-bold">
                                                    {key}
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                {value ? value : "-"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        {user.level === "DSN" && (
                            <div className="row gap-2 my-4">
                                <button className="btn btn-info col-md-6 text-white fw-bold" onClick={() => setData(true)} >
                                    SETUJUI
                                </button>
                                <button className="btn btn-danger col-md-5 text-white fw-bold" onClick={() => setData(false)}>
                                    TOLAK
                                </button>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
            <Footer />
        </React.Fragment>
    );
}
