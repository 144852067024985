import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Footer,
    Navbar,
    Input,
    Select,
    TextArea,
    ModalPopup,
    PrivacyPolicy,
} from "../../components";
import { checkPassword, GENDER_LIST, onKeyPress } from "../../helper";
import { Reference, Auth } from "../../services";

export default function ValidateRegister() {
    const { id } = useParams();
    const navigate = useNavigate();

    const auth = new Auth();
    const reference = new Reference();

    const [studyProgram, setStudyProgram] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [shown, setShown] = useState({
        password: false,
        confirmPassword: false,
    });
    const [payload, setPayload] = useState({
        fullname: "",
        nim: "",
        study_program: "",
        batch_year: 0,
        birth_place: "",
        birth_date: 0,
        gender: "",
        address: "",
        phone: "",
        password: "",
        confirm_password: "",
    });
    const [invalidPassword, setInvalidPassword] = useState({
        format: false,
        confirmPassword: false,
    });

    useEffect(() => {
        (async () => getStudyProgramList())();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getStudyProgramList = async () => {
        try {
            const resp = await reference.study_program_list();
            const sp = resp.data.list.map((item) => ({
                value: item.id,
                label: item.nama,
                code: item.kode,
            }));
            setStudyProgram([...sp]);
        } catch (e) {
            console.log({ e });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const resp = await auth.submit_biodata(id, payload);
            if (resp.status === 200) {
                navigate("/login?success_registered=true");
            }
        } catch (e) {
            console.error({ e });
            toast.error(`${e.message}`);
        }
    };

    const handlePayload = (state, value) => {
        if (state === "password" && !checkPassword(value) && value.length > 0) {
            setInvalidPassword({ ...invalidPassword, format: true });
            return;
        }
        if (state === "password") {
            setInvalidPassword({ ...invalidPassword, format: false });
        }
        if (state === "confirm_password") {
            if (value !== payload.password && value.length > 0) {
                setInvalidPassword({
                    ...invalidPassword,
                    confirmPassword: true,
                });
                return;
            } else {
                setInvalidPassword({
                    ...invalidPassword,
                    confirmPassword: false,
                });
            }
        }

        payload[state] = value;
        setPayload({ ...payload });
    };

    const enabledButton = () => {
        const passwordIsValid = Object.values(invalidPassword).every(
            (v) => v === false
        );
        const payloadIsValid = Object.values(payload).every(
            (v) => v !== "" && v !== null
        );

        return passwordIsValid && payloadIsValid;
    };

    const onClose = (visible) => {
        setModalVisible(visible);
    };

    return (
        <React.Fragment>
            <ModalPopup visible={modalVisible} onClose={onClose}>
                <PrivacyPolicy />
            </ModalPopup>
            <Navbar isLandingPage={false} />
            <div className="d-flex align-items-center justify-content-center my-2">
                <div className="col-md-4">
                    <div
                        className="d-flex align-items-center justify-content-center col-md-12 bg-blue text-white text-uppercase"
                        style={{ height: 43, fontSize: 12 }}
                    >
                        <label className="">VALIDASI REGISTRASI AKUN</label>
                    </div>
                    <div className="my-2 text-center font-weight-bold">
                        <span className="text-danger">
                            Silahkan lengkapi biodata Anda di bawah ini!
                        </span>
                    </div>
                    <form onSubmit={onSubmit}>
                        <Input
                            required
                            type="text"
                            label="Nama Lengkap"
                            onChangeValue={(value) =>
                                handlePayload("fullname", value)
                            }
                        />
                        <Input
                            required
                            type="text"
                            label="NPM"
                            onChangeValue={(value) =>
                                handlePayload("nim", value)
                            }
                        />
                        <Select
                            required
                            options={studyProgram}
                            label="Program Studi"
                            onChangeValue={(value) =>
                                handlePayload("study_program", value)
                            }
                        />
                        <Input
                            required
                            type="text"
                            label="Tahun Angkatan"
                            onChangeValue={(value) =>
                                handlePayload("batch_year", value)
                            }
                        />
                        <Input
                            required
                            type="text"
                            label="Tempat Lahir"
                            onChangeValue={(value) =>
                                handlePayload("birth_place", value)
                            }
                        />
                        <Input
                            required
                            type="date"
                            label="Tanggal Lahir"
                            onChangeValue={(value) =>
                                handlePayload("birth_date", value)
                            }
                        />
                        <Select
                            options={GENDER_LIST}
                            label="Jenis Kelamin"
                            onChangeValue={(value) =>
                                handlePayload("gender", value)
                            }
                        />
                        <TextArea
                            value={payload["address"]}
                            label="Alamat"
                            onChangeValue={(value) =>
                                handlePayload("address", value)
                            }
                        />
                        <Input
                            required
                            type="text"
                            label="Telepon/Hp"
                            onChangeValue={(value) =>
                                handlePayload("phone", value)
                            }
                            onKeyPress={onKeyPress}
                        />
                        <label className="form-label font-weight-bold">
                            Password
                        </label>
                        <Input
                            required
                            className={
                                invalidPassword.format
                                    ? "mb-0 input-group"
                                    : "mb-3 input-group"
                            }
                            type={`${shown.password ? "text" : "password"}`}
                            label=""
                            onChangeValue={(value) =>
                                handlePayload("password", value)
                            }
                        >
                            <button
                                type="button"
                                className="input-group-text"
                                id="input-group-right-example"
                                onClick={() =>
                                    setShown({
                                        ...shown,
                                        password: !shown.password,
                                    })
                                }
                            >
                                {shown.password ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-eye"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-eye-slash"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>
                                )}
                            </button>
                        </Input>
                        {invalidPassword.format && (
                            <React.Fragment>
                                <label
                                    className="font-weight-bold text-sm text-danger"
                                    style={{ fontSize: 11 }}
                                >
                                    * Password minimal terdiri dari 7 karakter
                                    berupa kombinasi huruf kapital, huruf kecil,
                                    simbol, dan angka
                                </label>
                                <br />
                            </React.Fragment>
                        )}

                        <label className="form-label font-weight-bold">
                            Ulangi Password
                        </label>
                        <Input
                            required
                            type={`${
                                shown.confirmPassword ? "text" : "password"
                            }`}
                            label=""
                            className={
                                invalidPassword.format
                                    ? "mb-0 input-group"
                                    : "mb-3 input-group"
                            }
                            onChangeValue={(value) =>
                                handlePayload("confirm_password", value)
                            }
                        >
                            <button
                                type="button"
                                className="input-group-text"
                                id="input-group-right-example"
                                onClick={() =>
                                    setShown({
                                        ...shown,
                                        confirmPassword: !shown.confirmPassword,
                                    })
                                }
                            >
                                {shown.confirmPassword ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-eye"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-eye-slash"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>
                                )}
                            </button>
                        </Input>

                        {invalidPassword.confirmPassword && (
                            <label
                                className="font-weight-bold text-sm text-danger"
                                style={{ fontSize: 11 }}
                            >
                                * Password tidak sama
                            </label>
                        )}
                        <div className="text-center my-3">
                            <label style={{ fontSize: 11 }}>
                                Dengan mendaftar, Anda telah menyetujui
                                <span
                                    className="text-decoration-none link-primary"
                                    onClick={() => setModalVisible(true)}
                                >
                                    {" "}
                                    Kebijakan Privasi.
                                </span>
                            </label>
                        </div>
                        <div className="text-center my-3">
                            <label style={{ fontSize: 12 }}>
                                Setelah mengisi biodata ini, Anda harus menunggu SBA memverifikasi data Anda. Jika data Anda sudah diverifikasi, Anda akan mendapatkan email konfirmasi untuk dapat login ke dalam sistem.
                            </label>
                        </div>
                        <button
                            disabled={!enabledButton()}
                            type="submit"
                            className={`submit-login d-flex align-items-center justify-content-center col-md-12 btn bg-primary font-weight-bold text-white my-3 ${
                                !enabledButton() ? "disabled" : ""
                            }`}
                        >
                            SIMPAN
                        </button>
                    </form>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}
