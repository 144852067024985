import { BackButton, Input, Navbar, Select } from "../../../../components";
import React, { useEffect } from "react";
import { useAuth } from "../../../../util/ProtectedRoute";
import "./View.css";
import { useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dokumen } from "../../../../services";
import { toast } from "react-toastify";
import { onKeyPress } from "../../../../helper";

export default function View() {
    const { id } = useParams();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get("action");
    const isRead = action === "read";

    const dokumen_service = new Dokumen();
    const [loading, setLoading] = useState(true);   

    const ctx = useAuth();
    const [payload, setPayload] = useState({});
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }
    const generateTitle = () => {
        switch (action) {
            case "read":
                return "Detail";
            case "update":
                return "Ubah";
            default:
                return "Tambah";
        }
    };

    useEffect(() => {
        const getDokumen = async () => {
            if (["update", "read"].includes(action)) {
                try {
                    const resp = await dokumen_service.detail(id);
                    if (!resp.data) {
                        throw new Error("Data not found");
                    }

                    const dokumen = resp.data;
                    setPayload({ ...dokumen });
                    setLoading(false);
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    // navigate(-1);
                }
            } else {
                setLoading(false);
            }
        };

        getDokumen();
    }, []);

    const handlePayload = (state, value) => {
        setPayload({ ...payload, [state]: value });
    };    

    const RenderForm = (data) => {
        const { type, state } = data;

        switch (type) {
            case "text":
            case "file":  
                return (       
                    <>                
                        <Input
                            {...(type !== "file" ? { value: payload[state] } : {})}
                            type={type === 'number' ? 'text' : type}
                            {...(type === "number" ? { onKeyPress: onKeyPress } : {})}
                            required
                            className="my-3 input-group"
                            onChangeValue={(value) => {                            
                                const val = type === 'number' && value.length > 0 && value[value.length - 1] !== '.' ? Number(value) : value;
                                handlePayload(state, val)
                            }}
                            accept=".doc, .docx, .pdf"
                        >                     
                        </Input>
                        {type === 'file' && (
                            <span className="text-secondary text-sm">*Unggah dokumen dalam format word dan pdf</span>
                        )}
                    </>                 
                );            
            default:
                return <></>;
        }
    };

    let columns = useMemo(() => {
        return [
            {
                label: "Nama Dokumen",
                value: "",
                type: "text",
                state: "nama",
            },
            {
                label: "File",
                value: "",
                type: "file",
                state: "file",
            },
        ];
    });    

    const onSubmit = async (e) => {
        console.log({ action })
        if (isRead) {
            return;
        }

        if (!["update", "add"].includes(action)) {
            return;
        }

        if (action === "update") {
            await updateDokumen();
        } else if (action === "add") {
            await addDokumen();
        }
    };

    const addDokumen = async () => {        
        try {            
            await dokumen_service.add(payload);
            toast.success("Data berhasil ditambahkan");
            navigate("/management/dokumen");
        } catch (e) {
            console.log(e);
            toast.error(e.message);
        }
    };

    const updateDokumen = async () => {
        try {
            const data = {
                id: payload.id,
                nama: payload.nama,
                file: payload.file,
            };

            await dokumen_service.update(data);
            toast.success("Data berhasil diubah");
            navigate("/management/dokumen");
        } catch (e) {
            console.log(e);
            toast.error(JSON.stringify(e));
        }
    };
    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <span className=" col-md-6 rounded-1 fs-4 text-uppercase text-center bg-blue text-white">
                        Menu {generateTitle()} Dokumen
                    </span>
                </div>

                {!loading &&
                    columns.map((col, key) => (
                        <div className="row col-md-9 my-4" key={key}>
                            <div className="col-md-4 justify-content-end pt-2 label">
                                <span className="fw-bold">{col.label}</span>
                            </div>
                            <div className="col-md-8">{RenderForm(col)}</div>
                        </div>
                    ))}
                {!isRead && (
                    <div className="row col-md-9 my-4 ">
                        <button
                            className="col-md-8 offset-md-4 btn btn-info text-white w-full text-uppercase fw-bold"
                            onClick={onSubmit}
                        >
                            Simpan
                        </button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
