import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GENDER_LIST } from "../../../helper";
import { Auth, Reference, Users } from "../../../services";
import { Input, Select, TextArea } from "../../Form";
import { useLocalStorage } from "../../../util/useLocalStorage";
import { RotatingLines } from "react-loader-spinner";

export function UpdateBiodata(props) {
    const users = new Users();
    const reference = new Reference();
    const auth = new Auth();

    const { user, setUserData } = props;
    const [loading, setLoading] = useState({
        studyProgram: true,
        submit: false,
    });
    // eslint-disable-next-line no-unused-vars
    const [_, setUser] = useLocalStorage("user", null); 
    
    const [studyProgram, setStudyProgram] = useState(null);
    const [payload, setPayload] = useState({
        fullname: user?.fullname,
        nim: user?.nim,
        study_program: user?.study_program?.id,
        batch_year: user?.batch_year,
        birth_place: user?.birth_place,
        birth_date: user?.birth_date,
        gender: user?.gender,
        address: user?.address,
        phone: user?.phone,
        email: user?.email,
    });

    const [defaultValue, setDefaultValue] = useState({
        study_program: {},
        gender: {},
    });

    useEffect(() => {
        (async () => getStudyProgramList())();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getStudyProgramList = async () => {
        try {
            const resp = await reference.study_program_list();
            const sp = resp.data.list.map((item) => ({
                value: item.id,
                label: item.nama,
                code: item.kode,
            }));

            const defaultStudyProgram = sp.find(
                (item) => item.value === payload.study_program
            );
            const defaultGender = GENDER_LIST.find(
                (item) => item.value === payload.gender
            );

            setDefaultValue({
                study_program: defaultStudyProgram,
                gender: defaultGender,
            });
            setStudyProgram([...sp]);
            setLoading({ ...loading, studyProgram: false });
        } catch (e) {
            console.log({ e });
        }
    };

    const enabledButton = () => {
        const payloadIsValid = Object.values(payload).every(
            (v) => v !== "" && v !== null
        );
        return (payloadIsValid && !loading.submit);
    };

    const onSubmit = async (e) => {
        setLoading({ ...loading, submit: true });
        e.preventDefault();
        if (enabledButton()) {
            try {
                await users.update_user(payload);

                const token = await localStorage.getItem("token");
                const resp = await auth.check_user_token(token);
                setUser({ ...resp.data });
                setUserData({ ...resp.data });

                toast.success("Data berhasil diubah");
                setLoading({ ...loading, submit: false });
            } catch (e) {
                console.error({ e });
                toast.error(`${e.message}`);
                setLoading({ ...loading, submit: false });
            }
        }
    };

    const RenderForm = (data) => {
        const { type, state, options } = data;
        const disabled = ["nim", "email"];

        switch (type) {
            case "text":
            case "date":
            case "email":
                return (
                    <Input
                        required
                        type={type}
                        value={payload[state]}
                        onChangeValue={(value) =>
                            setPayload({ ...payload, [state]: value })
                        }
                        disabled={disabled.includes(state)}
                    />
                );
            case "select":
                return (
                    <Select
                        required
                        defaultValue={defaultValue[state]}
                        options={options}
                        onChangeValue={(value) =>
                            setPayload({ ...payload, [state]: value })
                        }
                    />
                );
            case "textarea":
                return (
                    <TextArea
                        value={payload[state]}
                        onChangeValue={(value) =>
                            setPayload({ ...payload, [state]: value })
                        }
                    />
                );
            default:
                return <>test</>;
        }
    };

    const data = [
        {
            label: "Nama Lengkap:",
            value: payload.fullname,
            type: "text",
            state: "fullname",
        },
        {
            label: "NPM:",
            value: user?.nim,
            type: "text",
            state: "nim",
        },
        {
            label: "Program Studi:",
            value: payload.study_program,
            type: "select",
            state: "study_program",
            options: studyProgram,
        },
        {
            label: "Tahun Angkatan:",
            value: payload.batch_year,
            type: "text",
            state: "batch_year",
        },
        {
            label: "Tempat Lahir:",
            value: payload.birth_place,
            type: "text",
            state: "birth_place",
        },
        {
            label: "Tanggal Lahir:",
            value: payload.birth_date,
            type: "date",
            state: "birth_date",
        },
        {
            label: "Jenis Kelamin:",
            value: "L",
            type: "select",
            state: "gender",
            options: GENDER_LIST,
        },
        {
            label: "Alamat:",
            value: payload.address,
            type: "textarea",
            state: "address",
        },
        {
            label: "Telepon/Hp:",
            value: payload.phone,
            type: "text",
            state: "phone",
        },
        {
            label: "Alamat Email:",
            value: payload.email,
            type: "email",
            state: "email",
        },
    ];

    return (
        <div className="container">
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                height="50"
                visible={loading.studyProgram}
                className="img-thumbnail mt-5"
            />
            {!loading.studyProgram && (
                <form onSubmit={onSubmit}>
                    {data.map((item, i) => (
                        <div className="row my-3" key={i}>
                            <div className="col-md-4 font-weight-bold">
                                {item.label}
                            </div>
                            <div className="col-md-6">{RenderForm(item)}</div>
                        </div>
                    ))}
                    <div className="text-center my-5">
                        <button
                            type="submit"
                            className="btn bg-bluesky"
                            disabled={!enabledButton()}
                        >
                            SIMPAN
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
}
