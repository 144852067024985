import React, { useEffect, useState } from "react";
import "./Mahasiswa.css";
import book from "../../../images/book.png";
import { useNavigate } from "react-router-dom";
import Table from "../../Table";
import { Submission } from "../../../services";
import { SUBMISSION_TYPE } from "../../../helper/constants";
import ModalPopup from "../../ModalPopup/ModalPopup";
import { toast } from "react-toastify";

export default function Mahasiswa({ user }) {
    const navigate = useNavigate();
    const submission = new Submission();
    const [thesisSeminarSubmissions, setThesisSeminarSubmissions] = useState(null);
    const [thesisSubmissions, setThesisSubmissions] = useState(null);
    const [loadingThesisSeminar, setLoadingThesisSeminar] = useState(true);
    const [loadingThesis, setLoadingThesis] = useState(true);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [submissionId, setSubmissionId ] = useState('');

    useEffect(() => {
        (async () => getThesisSeminarSubmissionList())();
        (async () => getThesisSubmissionList())();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getThesisSeminarSubmissionList = async () => {
        try {
            const resp = await submission.list(1, 1000, SUBMISSION_TYPE.THESIS_SEMINAR);   
            setThesisSeminarSubmissions([...resp.data.list ])
            setLoadingThesisSeminar(false);

        } catch (e) {
            console.log({ e });
            setLoadingThesisSeminar(false);
        }
    };
    const getThesisSubmissionList = async () => {
        try {
            const resp = await submission.list(1, 1000, SUBMISSION_TYPE.THESIS);   
            setThesisSubmissions([...resp.data.list ]);
            setLoadingThesis(false);

        } catch (e) {
            console.log({ e });
            setLoadingThesis(false);
        }
    };
    const deleteSubmission = async () => {
        try {
            setLoadingDelete(true);
            await submission.delete(submissionId);   
            await getThesisSeminarSubmissionList()
            await getThesisSubmissionList();

            setDeleteModal(!deleteModal);
            setLoadingDelete(false);
            toast.success('Data berhasil dihapus')

        } catch (e) {
            console.log({ e });
            toast.error(JSON.stringify(e))
            setLoadingDelete(false);
        }
    };

    const goToSubmission = (path) => {
        const validUserBiodata = Object.values(user).every(
            (v) => v !== "" && v !== null && v !== undefined
        );
        
        if (!validUserBiodata) {
            return toast.error('Harap lengkapi biodata terlebih dahulu sebelum melakukan pengajuan.')
        }
        navigate(path);
    }
    return (
        <div className="my-2">
            <ModalPopup visible={deleteModal} onClose={() => setDeleteModal(!deleteModal)} height={150} width={500}>
                <label>Kamu yakin ingin menghapus pengajuan ini?</label>
                <div className="text-end mt-5">
                    <button type="button" className="btn btn-info mx-3" onClick={() => deleteSubmission()} disabled={loadingDelete}>Ya</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setDeleteModal(!deleteModal)} disabled={loadingDelete}>Batal</button>
                </div>
            </ModalPopup>
            <div className="d-flex flex-row justify-content-around">
                <div className="col-lg-5">
                    <div className="card shadow-lg">
                        <div className="bg-green text-center py-3 card-mahasiswa">
                            <img src={book} alt="book" />
                            <br />
                            <label
                                className="text-white"
                                style={{ fontSize: 40 }}
                            >
                                Seminar Skripsi
                            </label>
                        </div>
                        <div className="card-body text-center">
                            <button
                                onClick={() => goToSubmission('/pengajuan-seminar-skripsi')}
                                className="btn bg-green col-md-6 font-weight-bold"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-plus-circle-fill mb-1"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                </svg>
                                <label className="px-1">Buat Pengajuan</label>
                            </button>
                            <Table
                                loading={loadingThesisSeminar}
                                fields={[
                                    "Tanggal",
                                    "Status",
                                    "Detail",
                                    "Ubah",
                                    "Hapus",
                                ]}
                                data={thesisSeminarSubmissions}
                                onDelete={({ submissionId, deleteModalVisible }) => {
                                    setSubmissionId(submissionId)
                                    setDeleteModal(deleteModalVisible)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-5">
                    <div className="card shadow-lg">
                        <div className="bg-green text-center py-3 card-mahasiswa">
                            <img src={book} alt="book" />
                            <br />
                            <label
                                className="text-white"
                                style={{ fontSize: 40 }}
                            >
                                Skripsi
                            </label>
                        </div>
                        <div className="card-body text-center">
                            <button
                                onClick={() => goToSubmission('/pengajuan-skripsi')}                                
                                className="btn bg-green col-md-6 font-weight-bold"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-plus-circle-fill mb-1"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                </svg>
                                <label className="px-1">Buat Pengajuan</label>
                            </button>
                            <Table
                                loading={loadingThesis}
                                fields={[
                                    "Tanggal",
                                    "Status",
                                    "Detail",
                                    "Ubah",
                                    "Hapus",
                                ]}
                                data={thesisSubmissions}
                                onDelete={({ submissionId, deleteModalVisible }) => {
                                    setSubmissionId(submissionId)
                                    setDeleteModal(deleteModalVisible)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
