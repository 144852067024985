import React from 'react';
import ReactSelect from 'react-select';

export default function Select(props) {

    const onChange = (e) => {
        onChangeValue(e.value);
    };

    const { options, label, onChangeValue, defaultValue } = props;   
    return (

        <div className="mb-3">
            {label?.length > 0 && <label htmlFor={label} className="form-label">{label}</label>}
            <ReactSelect 
                defaultValue={defaultValue}
                options={options} 
                onChange={onChange} 
                placeholder={label}
                {...props}
            />
        </div>
    )
}