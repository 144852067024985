import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Footer, Input, Navbar } from "../../components";
import { checkPassword } from "../../helper";
import { Auth } from "../../services";
import { useEffect } from "react";

export default function Login() {
    const navigate = useNavigate();
    const auth = new Auth();
    const [searchParams] = useSearchParams();
    const [showAlert, setShowAlert] = useState(
        Boolean(searchParams.get("success_registered"))
    );
    const [alertMessage, setAlertMessage] = useState(
        <Alert
            type="primary"
            message={
                <span className="font-weight-bold" style={{ fontSize: 11 }}>
                    SUKSES!! <br />
                    Registrasi akun berhasil, data Anda sudah tersimpan.
                    Silahkan tunggu validasi dari SBA untuk dapat login.
                </span>
            }
            showAlert={true}
        />
    );

    const [payload, setPayload] = useState({
        email: "",
        password: "",
    });

    const [passwordShown, setPasswordShown] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);

    useEffect(() => {
        const checkUserToken = async () => {
            try {
                const userToken = await localStorage.getItem('token');
                await auth.check_user_token(userToken);
                return navigate('/homepage');

            } catch (e) {
                localStorage.clear();
                console.error({ e });
            }
        };

        checkUserToken();
    }, [])
    const onSubmit = async (e) => {
        e.preventDefault();
        if (!enabledButton()) {
            return;
        }
        try {
            const resp = await auth.login(payload);
            if (resp.status === 200) {
                setShowAlert(false);
                localStorage.setItem("token", resp.data.token);
                window.location.href = "/homepage";
            }
        } catch (e) {
            console.error({ e });
            if (e.status === 401) {
                setShowAlert(true);
                setAlertMessage(
                    <Alert
                        type="danger"
                        message={
                            <span
                                className="font-weight-bold"
                                style={{ fontSize: 11 }}
                            >
                                Kata sandi salah. Silahkan masukkan kata sandi
                                dengan benar.
                            </span>
                        }
                        showAlert={true}
                    />
                );

                return;
            }
            toast.error(`Error: ${JSON.stringify(e)}`);
        }
    };
    const enabledButton = () => {
        const payloadIsValid = Object.values(payload).every(
            (v) => v !== "" && v !== null
        );
        return payloadIsValid;
    };
    const handlePayload = (state, value) => {
        if (state === "password" && !checkPassword(value)) {
            setInvalidPassword(true);
            return;
        }
        if (state === "password") {
            setInvalidPassword(false);
        }

        payload[state] = value;
        setPayload({ ...payload });
    };

    return (
        <React.Fragment>
            <Navbar isLandingPage={false} />
            <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "75vh" }}
            >
                <div className="col-md-4">
                    <div
                        className="d-flex align-items-center justify-content-center col-md-12 bg-blue text-white text-uppercase"
                        style={{ height: 43, fontSize: 12 }}
                    >
                        <label className="">MASUK</label>
                    </div>
                    {showAlert && alertMessage}
                    <form onSubmit={onSubmit}>
                        <div className="form mt-4">
                            <Input
                                required
                                type="email"
                                placeholder="Email"
                                onChangeValue={(value) =>
                                    handlePayload("email", value)
                                }
                            />
                            <Input
                                required
                                className="my-3 input-group"
                                type={`${passwordShown ? "text" : "password"}`}
                                placeholder="Password"
                                onChangeValue={(value) =>
                                    handlePayload("password", value)
                                }
                            >
                                <button
                                    type="button"
                                    className="input-group-text"
                                    id="input-group-right-example"
                                    onClick={() =>
                                        setPasswordShown({
                                            passwordShown: !passwordShown,
                                        })
                                    }
                                >
                                    {passwordShown ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-eye"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-eye-slash"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                        </svg>
                                    )}
                                </button>
                            </Input>
                            {invalidPassword && (
                                <label
                                    className="font-weight-bold text-sm text-danger"
                                    style={{ fontSize: 11 }}
                                >
                                    * Password minimal terdiri dari 7 karakter
                                    berupa kombinasi huruf kapital, huruf kecil,
                                    simbol, dan angka
                                </label>
                            )}
                            <div
                                className="forgot-password text-end my-2"
                                style={{ fontSize: 11 }}
                            >
                                <Link
                                    to="/forgot-password"
                                    className="text-decoration-none"
                                >
                                    Lupa kata sandi?
                                </Link>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="btn text-white font-weight-bold submit-login d-flex align-items-center justify-content-center col-md-12 btn bg-bluesky"
                            disabled={!enabledButton()}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-box-arrow-in-right"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                                />
                            </svg>
                            Login
                        </button>
                        <div
                            className="text-center py-2"
                            style={{ fontSize: 12 }}
                        >
                            <span>Belum memiliki akun ChemOns?</span>
                            <Link
                                to="/register"
                                className="text-decoration-none"
                            >
                                {" "}
                                Daftar Akun Disini
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}
