import { getAuthorization } from "../headers";

export default class Notification {   
    list = async ({ page, limit }) => {
        page = page || 1;
        limit = limit || 1000;

        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/notification?page=${page}&limit=${limit}`, {
                headers,
                method: "GET",
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    patch = async (id) => {
        const payload = {
            read: true
        };
        let url = `${process.env.REACT_APP_URL_SERVICE}/notification`;
        if (id) {
            url = `${url}/${id}`;
        }
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(url, {
                headers,
                method: "PATCH",
                body: payload
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}
