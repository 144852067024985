import { BackButton, Input, Navbar, Select } from "../../../../components";
import React, { useEffect } from "react";
import { useAuth } from "../../../../util/ProtectedRoute";
import "./View.css";
import { useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dosen, Reference, BidangPenelitian } from "../../../../services";
import { GENDER_LIST, onKeyPress } from "../../../../helper";
import { toast } from "react-toastify";

export default function View() {
    const { id } = useParams();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get("action");
    const isRead = action === "read";

    const references = new Reference();
    const dosen_service = new Dosen();
    const bidang_penelitian = new BidangPenelitian();
    const [loading, setLoading] = useState(true);

    const ctx = useAuth();
    const [payload, setPayload] = useState({});
    const [passwordShown, setPasswordShown] = useState({
        password: false,
    });
    const [studyProgram, setStudyProgram] = useState([]);
    const [risetGroup, setRisetGroup] = useState([]);
    const [defaultValue, setDefaultValue] = useState({
        jenis_kelamin: {},
        jurusan: {},
        riset_grup: {},
    });
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    useEffect(() => {
        const getStudyProgram = async () => {
            try {
                const resp = await references.study_program_list();
                const sp = resp.data.list.map((item) => ({
                    value: item.id,
                    label: item.nama,
                    code: item.kode,
                }));
                setStudyProgram([...sp]);
                await getRisetGroup()
                await getDosen(sp);
            } catch (e) {
                console.log({ e });
            }
        };
        const getRisetGroup = async () => {
            try {
                const resp = await bidang_penelitian.list({ page: 1, limit: 1000 });
                const sp = resp.data.list.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
                setRisetGroup([...sp]);
            } catch (e) {
                console.log({ e });
            }
        };

        const getDosen = async (sp) => {
            if (["update", "read"].includes(action)) {
                try {
                    const resp = await dosen_service.detail(id);
                    if (!resp.data) {
                        throw new Error("Data not found");
                    }

                    const dosen = resp.data;
                    dosen.tanggal_lahir = dosen?.tanggal_lahir?.split("T")[0];

                    defaultValue.jurusan =
                        sp?.length > 0 &&
                        sp.find((sp) => sp.value === dosen.id_jurusan);
                    defaultValue.jenis_kelamin =
                        GENDER_LIST?.length > 0 &&
                        GENDER_LIST.find(
                            (sp) => sp.value === dosen.jenis_kelamin
                        );


                    setPayload({ ...dosen });
                    setDefaultValue({ ...defaultValue });
                    setLoading(false);
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    // navigate(-1);
                }
            } else {
                setLoading(false);
            }
        };

        getStudyProgram();
    }, []);

    const generateDefaultValue = (state) => {
        switch (state) {
            case "jenis_kelamin":
                return GENDER_LIST?.length > 0
                    ? GENDER_LIST.find((item) => item.value === payload[state])
                    : null;
            case "id_jurusan":
                return (
                    studyProgram.find(
                        (item) => item.value === payload[state]
                    ) || null
                );
            case "riset_grup":
                return (
                    risetGroup.find(
                        (item) => item.value === payload[state]
                    ) || null
                );
            default:
                return null;
        }
    };

    const RenderForm = (data) => {
        const { type, state, options } = data;

        switch (type) {
            case "text":
            case "date":
            case "email":
            case "number":
            case "password":

                return type === 'password'
                    ?
                    (
                        <Input
                            required
                            className="my-3 input-group"
                            type={`${passwordShown[state] ? "text" : "password"}`}
                            placeholder="Password"
                            onChangeValue={(value) =>
                                setPayload({ ...payload, [state]: value })
                            }
                        >
                            <button
                                type="button"
                                className="input-group-text"
                                id="input-group-right-example"
                                onClick={() =>
                                    setPasswordShown({
                                        ...passwordShown,
                                        [state]: !passwordShown[state]
                                    })
                                }
                            >
                                {passwordShown ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-eye"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-eye-slash"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>
                                )}
                            </button>
                        </Input>
                    )
                    :
                    (
                        <Input
                            disabled={isRead}
                            required
                            // type={type === 'number' ? 'text' : type}
                            // value={payload[state]}
                            // onChangeValue={(value) => {
                            //     const val = type === 'number' ? Number(value) : value;
                            //     setPayload({ ...payload, [state]: val })
                            // }}
                            value={payload[state]}
                            type={type === 'number' ? 'text' : type}
                            {...(type === "number" ? { onKeyPress: onKeyPress } : {})}
                            onChangeValue={(value) => {
                                const val = type === 'number' && value.length > 0 && value[value.length - 1] !== '.' ? Number(value) : value;
                                setPayload({ ...payload, [state]: val })
                            }}
                        />
                    );
            case "select":
                return (
                    <Select
                        defaultValue={generateDefaultValue(state)}
                        isDisabled={isRead}
                        required
                        options={options}
                        onChangeValue={(value) =>
                            setPayload({ ...payload, [state]: value })
                        }
                    />
                );
            default:
                return <>test</>;
        }
    };

    let columns = useMemo(() => {
        return [
            {
                label: "NIP",
                value: "",
                type: "text",
                state: "nidn",
            },
            {
                label: "Nama",
                value: "",
                type: "text",
                state: "nama",
            },
            {
                label: "Riset Group",
                value: "",
                type: "select",
                state: "riset_grup",
                options: risetGroup,
            },
            {
                label: "Jurusan",
                value: "",
                type: "select",
                state: "id_jurusan",
                options: studyProgram,
            },
            {
                label: "Tahun Masuk",
                value: "",
                type: "number",
                state: "tahun_masuk",
            },
            {
                label: "Jenis Kelamin",
                value: "",
                type: "select",
                state: "jenis_kelamin",
                options: GENDER_LIST,
            },
            {
                label: "Tempat Lahir",
                value: "",
                type: "text",
                state: "tempat_lahir",
            },
            {
                label: "Tanggal Lahir",
                value: "",
                type: "date",
                state: "tanggal_lahir",
            },
            {
                label: "Alamat",
                value: "",
                type: "text",
                state: "alamat",
            },
            {
                label: "No Telp",
                value: "",
                type: "text",
                state: "no_telp",
            },
            {
                label: "Email",
                value: "",
                type: "email",
                state: "email",
            },
        ];
    });

    if (action === "add") {
        columns = [
            ...columns,
            {
                label: "Password",
                value: "",
                type: "password",
                state: "password",
            },
        ];
    }

    const generateTitle = () => {
        switch (action) {
            case "read":
                return "Detail";
            case "update":
                return "Ubah";
            default:
                return "Tambah";
        }
    };

    const onSubmit = async (e) => {
        if (isRead) {
            return;
        }

        if (!["update", "add"].includes(action)) {
            return;
        }

        if (action === "update") {
            await updateDosen();
        } else if (action === "add") {
            await addDosen();
        }
    };

    const addDosen = async () => {
        try {
            const data = {
                fullname: payload.nama,
                study_program: payload.jurusan,
                batch_year: Number(payload.tahun_masuk),
                research_group: payload.riset_grup,
                birth_place: payload.tempat_lahir,
                birth_date: payload.tanggal_lahir,
                gender: payload.jenis_kelamin,
                address: payload.alamat,
                email: payload.email,
                password: payload.password,
                phone: payload.no_telp,
                nidn: payload.nidn,
            };

            await dosen_service.add(data);

            toast.success("Data berhasil ditambahkan");
            navigate("/management/dosen");
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
    };

    const updateDosen = async () => {
        try {
            const data = {
                id: id,
                fullname: payload.nama,
                study_program: payload.id_jurusan,
                batch_year: Number(payload.tahun_masuk),
                research_group: payload.riset_grup,
                birth_place: payload.tempat_lahir,
                birth_date: payload.tanggal_lahir,
                gender: payload.jenis_kelamin,
                address: payload.alamat,
                phone: payload.no_telp,
                nidn: payload.nidn,
                email: payload.email,
            };
            await dosen_service.update(data);
            toast.success("Data berhasil diubah");
            navigate("/management/dosen");
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
    };
    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <span className=" col-md-6 rounded-1 fs-4 text-uppercase text-center bg-blue text-white">
                        Menu {generateTitle()} dosen
                    </span>
                </div>

                {!loading && columns.map((col, key) => (
                    <div className="row col-md-9 my-4" key={key}>
                        <div className="col-md-4 justify-content-end pt-2 label">
                            <span className="fw-bold">{col.label}</span>
                        </div>
                        <div className="col-md-8">{RenderForm(col)}</div>
                    </div>
                ))}
                {!isRead && (
                    <div className="row col-md-9 my-4 ">
                        <button
                            className="col-md-8 offset-md-4 btn btn-info text-white w-full text-uppercase fw-bold"
                            onClick={onSubmit}
                        >
                            Simpan
                        </button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
