import React, { useEffect, useState } from "react";
import { Input, Select } from "../../../../components";
import { Submission } from "../../../../services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function Schedule({ dosenList, id, submission: submissionData  }) {
    
    dosenList = dosenList.filter(item => (item.value !== submissionData?.dosen_p1?.id) && (item.value !== submissionData?.dosen_p2?.id))
    const navigate = useNavigate();
    const submission = new Submission();
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState({
        datetime: submissionData?.tanggal_sidang && moment(submissionData.tanggal_sidang).format('YYYY-MM-DD'),
        time: submissionData?.tanggal_sidang && moment(submissionData.tanggal_sidang).format('HH:mm:ss'),
        dosen1: submissionData?.dosen_uji1?.id,
        dosen2: submissionData?.dosen_uji2?.id,
        room: submissionData?.ruang
    });
    
    console.log({ payload })

    const handlePayload = (state, value) => {
        setPayload({ ...payload, [state]: value });
    };

    const generateDefaultValue = (state) => {
        switch(state) {
            case 'dosen1':
                return dosenList.find(item => item.value === submissionData?.dosen_uji1?.id)
            case 'dosen2':                
                return dosenList.find(item => item.value === submissionData?.dosen_uji2?.id)
            default: 
                return null
        }
    }

    const RenderForm = (form) => {
        const { type, label, state, options } = form;
        switch (type) {
            case "date":
            case "text":
            case "time":
                return (
                    <Input
                        value={payload[state]}
                        type={type}
                        label={label}
                        onChangeValue={(value) => handlePayload(state, value)}
                    />
                );
            case "select":
                return (
                    <Select
                        options={options || []}
                        label={label}
                        defaultValue={generateDefaultValue(state)}
                        onChangeValue={(value) => {
                            handlePayload(state, value);
                            // if (state === "dosen1") {
                            //     const dosen2 = dosenList.filter(
                            //         (dosen) => dosen.value !== value
                            //     );
                            //     setDosenUji2List([...dosen2]);
                            // }
                        }}
                    />
                );

            default:
                return <></>;
        }
    };

    const onSubmit = async () => {
        if (!enabledButton()) {
            return
        }
        
        setLoading(true);
        try {
            const data = { ...payload };
            data.datetime = moment(data.datetime).format('YYYY-MM-DD');
            data.datetime = `${data.datetime}T${data.time}`;
            data.datetime = `${data.datetime}`;            

            data.datetime = new Date(data.datetime).toISOString();

            delete data.time;
            
            await submission.schedule(id, data);
            setLoading(false);
            toast.success('Data berhasil disimpan');
            navigate('/homepage');
        } catch(e) {
            console.log(e);
            toast.error(e.message);
            setLoading(false);
        }
        
    };

    const enabledButton = () => {
        const payloadIsValid = Object.values(payload).every(
            (v) => v !== "" && v !== null
        );
        return payloadIsValid;
    };

    const forms = [        
        {
            label: "Dosen Penguji 1",
            type: "select",
            state: "dosen1",
            options: dosenList,
        },
        {
            label: "Dosen Penguji 2",
            type: "select",
            state: "dosen2",
            options: dosenList,
        },
        {
            label: "Tanggal Sidang",
            type: "date",
            state: "datetime",
        },
        {
            label: "Jam",
            type: "time",
            state: "time",
        },
        {
            label: "Ruang",
            type: "text",
            state: "room",
        },
    ];

    return (
        <div className="container mt-3">
            {dosenList?.length > 0 && forms?.length > 0 && forms.map((form, key) => {
                return (
                    <React.Fragment key={key}>
                        {RenderForm(form)}
                    </React.Fragment>
                );
            })}

            <button
                type="button"
                className="btn btn-info col-md-12 my-3 text-white fw-bold"
                disabled={!enabledButton() || loading}
                onClick={onSubmit}
            >
                SIMPAN
            </button>
        </div>
    );
}
