import moment from "moment";
import { getAuthorization } from "../headers";

export default class Submission {
    submit_submission = async (payload) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            const fd = new FormData();
            for (const [key, value] of Object.entries(payload)) {
                fd.append(key, value);
            }
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission`, {
                headers,
                method: "POST",
                body: fd,
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    list = async (page, limit, type, superVisorId, examinerId, keyword = '') => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            page = page || 1;
            limit = limit || 10;
            let query = type ? `&type=${type}` : '';          
            if (superVisorId) {
                query = `${query}&id_supervisor=${superVisorId}`;
            }
            if (examinerId) {
                query = `${query}&id_examiner=${examinerId}`;
            }
            if (keyword) {
                query = `${query}&keyword=${keyword}`
            }
              
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission?page=${page}&limit=${limit}&current_user=true${query}`, {
                headers,
                method: "GET",
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    list_with_filter = async (filter) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            const q = new URLSearchParams(filter).toString();
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission?${q}`, {
                headers,
                method: "GET",
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    downloadFile = async (filter, type) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            filter = {
                ...filter,
                file_type: type
            };            
            const q = new URLSearchParams(filter).toString();
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/export?file_type=${type}&${q}`, {
                headers,
                method: "GET",
            })
                .then((res) => {
                    return resolve(res.blob())
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    detail = async (id) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/${id}`, {
                headers,
                method: "GET",
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    update = async (payload) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            const fd = new FormData();
            for (const [key, value] of Object.entries(payload)) {
                fd.append(key, value);
            }
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/${payload.id}`, {
                headers,
                method: "PUT",
                body: fd
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    delete = async (id) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/${id}`, {
                headers,
                method: "DELETE",
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    verif = async (id, payload) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/${id}`, {
                headers: {
                    ...headers,
                    'Content-Type': 'application/json'
                },
                method: "PATCH",
                body: JSON.stringify(payload)
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    schedule_list = async (page, limit) => {
        return new Promise((resolve, reject) => {            
            const currentDate = moment(new Date()).format('YYYY-MM-DD');
            let query = (page && limit) ? `page=${page}&limit=${limit}` : 'all=true';             
            fetch(`${process.env.REACT_APP_URL_SERVICE}/schedules?${query}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "GET"
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    schedule = async (id, payload) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/schedule/${id}`, {
                headers: {
                    ...headers,
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(payload)
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    scheduleDosenPenguji = async (id, status) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/schedule/${id}?status=${status}`, {
                headers: {
                    ...headers,
                    'Content-Type': 'application/json'
                },
                method: "PATCH"
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    list_grading = async (id) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/grading/${id}`, {
                headers: {
                    ...headers,
                    'Content-Type': 'application/json'
                },
                method: "GET",
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    console.log('err: ', err)
                    reject(err);
                });
        });
    }

    grading = async (id, payload) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/grading/${id}`, {
                headers: {
                    ...headers,
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(payload)
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    console.log('err: ', err)
                    reject(err);
                });
        });
    }

    updateGrading = async (gradingID, payload) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/grading/${gradingID}`, {
                headers: {
                    ...headers,
                    'Content-Type': 'application/json'
                },
                method: "PUT",
                body: JSON.stringify(payload)
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    console.log('err: ', err)
                    reject(err);
                });
        });
    }

    uploadSubmissionReport = async (id, payload) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/report/${id}`, {
                headers: {
                    ...headers,
                },
                method: "PUT",
                body: payload
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    console.log('err: ', err)
                    reject(err);
                });
        });
    }

    // pengajuan dokumen
    list_documents = async (id) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/document/${id}`, {
                headers: {
                    ...headers,
                    'Content-Type': 'application/json'
                },
                method: "GET",
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    console.log('err: ', err)
                    reject(err);
                });
        });
    }

    upload_document = async (submissionId, documentId, payload) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/submission/document/${submissionId}/${documentId}`, {
                method: "POST",
                headers: {
                    ...headers,
                },
                body: payload,
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

}
