import { BackButton, CKEditor, Input, Navbar, Select } from "../../components";
import React, { useEffect } from "react";
import { useAuth } from "../../util/ProtectedRoute";
import { useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Submission } from "../../services";
import { toast } from "react-toastify";
import { SUBMISSION_STATUS_TYPE } from "../../helper/constants";
import { onKeyPress } from "../../helper";

export default function Grading() {
    
    const queryParams = new URLSearchParams(window.location.search);
    const isDosenPembimbing = queryParams.get("isDosenPembimbing");

    const { id } = useParams();

    const navigate = useNavigate();

    const submission = new Submission();
    const [loading, setLoading] = useState(true);

    const ctx = useAuth();
    const [payload, setPayload] = useState({
        
        penguasaan_ilmu: 0,
        susunan_bahasa: 0,
        sidang: 0,
        catatan: "",
    });
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    useEffect(() => {
        getSubmissionGrading();
    }, []);

    const getSubmissionGrading = async () => {
        try {
            const userID = user?.level === 'SBA' ? user.user_id : user.dosen_id;
            setLoading(true);
            const resp = await submission.list_grading(id);
            if (resp.data.list.length > 0) {
                let data = resp.data.list.find(
                    (data) => data.id_dosen_penguji === userID
                );

                if (!data) {
                   data = resp.data.list[0]; 
                }

                console.log({ data })
                setPayload({
                    ...payload,
                    penguasaan_ilmu: data.penguasaan_ilmu,
                    susunan_bahasa: data.susunan_bahasa,
                    sidang: data.sidang,
                    catatan: data.catatan,
                });

            }
            setLoading(false);
        } catch (e) {
            console.log("error: ", e);
            navigate(-1);
        }
    };

    const RenderForm = (data) => {
        const { type, state, options, label } = data;

        switch (type) {
            case "text":
                return (
                    <>
                        <label className="fw-bold">{label}</label>
                        <Input                        
                            onKeyPress={onKeyPress}
                            disabled={[
                                "rata_rata_sidang_skripsi",
                                "total_nilai",
                            ].includes(state)}
                            required
                            type={type}
                            value={payload[state]}
                            onChangeValue={(value) =>
                                setPayload({ ...payload, [state]: Number(value) })
                            }
                        />
                    </>
                );
            case "ckeditor":
                return (
                    <CKEditor
                        value={payload[state]}
                        label={label}
                        onChangeValue={(value) => {
                            setPayload({ ...payload, [state]: value })
                        }}
                    >                        
                    </CKEditor>
                )
            case "select":
                return (
                    <>
                        <label className="fw-bold">{label}</label>
                        <Select
                            required
                            options={options}
                            defaultValue={SUBMISSION_STATUS_TYPE.find(
                                (item) => item.value === payload[state]
                            )}
                            onChangeValue={(value) =>
                                setPayload({ ...payload, [state]: value })
                            }
                        />
                    </>
                );
            default:
                return <>test</>;
        }
    };

    let columns = useMemo(() => {
        return [
            // {
            //     label: "Penguasaan Ilmu Ketentuan Dan Kemandirian",
            //     value: "",
            //     type: "text",
            //     state: "penguasaan_ilmu",
            // },  
            {
                label: "Susunan Bahasa Penyajian Pola Pikir Materi",
                value: "",
                type: "text",
                state: "susunan_bahasa",
            }, 
            {
                label: "Nilai sidang skripsi",
                value: "",
                type: "text",
                state: "sidang",
            },                  
        ];
    });

    if (isDosenPembimbing) {
        columns = [
            {
                label: "Penguasaan Ilmu Ketentuan Dan Kemandirian",
                value: "",
                type: "text",
                state: "penguasaan_ilmu",
            },   
            {
                label: "Susunan Bahasa Penyajian Pola Pikir Materi",
                value: "",
                type: "text",
                state: "susunan_bahasa",
            },      
        ]
    }
    columns = [
        ...columns,                         
        {
            label: "Catatan",
            value: "",
            type: "ckeditor",
            state: "catatan",
        },  
    ]

    const onSubmit = async (e) => {        
        doSubmission();
    };

    const doSubmission = async () => {
        try {
            // const data = {...payload, keterangan: 'LULUS'}
            await submission.grading(id, payload);
            await getSubmissionGrading();
            toast.success("Data berhasil dinilai");
        } catch (e) {
            console.log("error: ", e);
            toast.error(JSON.stringify(e));
        }
    };

    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>
                {!loading && (
                    <>
                        <div className="d-flex justify-content-center align-items-center">
                            <span className=" btn btn-info rounded-2 fs-4 text-center text-white fw-bold">
                                Input Nilai
                            </span>
                        </div>
                        {columns.map((col, key) => (
                            <div className="row col-md-12 my-4" key={key}>
                                <div className="col-md-12">
                                    {RenderForm(col)}
                                </div>
                            </div>
                        ))}
                        <div className="row col-md-9 my-4 ">
                            <button
                                className="col-md-8 offset-md-4 btn btn-info text-white w-full text-uppercase fw-bold"
                                onClick={onSubmit}
                            >
                                Simpan
                            </button>
                        </div>
                    </>
                )}
            </div>
        </React.Fragment>
    );
}
