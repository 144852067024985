import React from 'react';
import './TextArea.css';

export default function TextArea({
    label = "",
    onChangeValue,
    value = "",
    ...props
}) {

    const onChange = (e) => {
        onChangeValue(e.target.value);
    };

    return (
        <div className="mb-3">
            {label?.length > 0 && <label htmlFor={label} className="form-label">{label}</label>}
            <textarea
                required 
                value={value}
                className="form-control" 
                onChange={onChange} 
                {...props}
            />
        </div>
    )
}