const GENDER_LIST = [
    {
        value: "L",
        label: "Laki-laki",
    },
    {
        value: "P",
        label: "Perempuan",
    },
];

const SUBMISSION_TYPE = {
    THESIS_SEMINAR: "SEMINAR",
    THESIS: "SIDANG",
};

const RESEARCH_SPACES = [
    {
        value: "IP",
        label: "Intensifikasi Proses",
    },
    {
        value: "EBER",
        label: "Energi Berkelanjutan",
    },
    {
        value: "RPKA",
        label: "Rekayasa Proses dan Konversi Produk Alam",
    },
    {
        value: "BIOP",
        label: "Teknologi Bioproses",
    },
];

const RESEARCH_SCHEMAS = [
    {
        value: "Sendiri",
        label: "Sendiri",
    },
    {
        value: "Berkelompok",
        label: "Berkelompok",
    },
];

const SUBMISSION_STATUS = [
    {
        label: 'DISETUJUI',
        value: 'DISETUJUI',
        text: `Selamat pengajuan matakuliah spesial Anda telah <b>disetujui</b>.<br/>Untuk melihat status pengajuan Anda silahkan masuk ke web ChemOns Teknik Kimia UI.`
    },
    {
        label: 'DITOLAK',
        value: 'DITOLAK',
        text: `Mohon maaf pengajuan matakuliah spesial Anda <b>ditolak</b> karena karena belum memenuhi syarat pengajuan matakuliah spesial di DTK FTUI.<br/>Untuk melihat status pengajuan Anda silahkan masuk ke web ChemOns Teknik Kimia UI.`
    },
    {
        label: 'REVISI',
        value: 'REVISI',
        text: `Pengajuan matakuliah spesial Anda memerlukan <b>revisi</b>.<br/>Untuk melihat status pengajuan Anda silahkan masuk ke web ChemOns Teknik Kimia UI.`
    }
];

const SBA_STATUS = [
    {
        label: 'ACTIVE',
        value: 'ACTIVE'
    },
    {
        label: 'INACTIVE',
        value: 'INACTIVE'
    }
];

const SUBMISSION_STATUS_TYPE = [
    {
        label: 'LULUS',
        value: 'LULUS'
    },
    {
        label: 'TIDAK LULUS',
        value: 'TIDAK_LULUS'
    },
    {
        label: 'REVISI',
        value: 'REVISI'
    }
]

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const TABLE_STYLE = {
    headRow: {
        style: {
            fontWeight: "bold",
        },
    },
    rows: {
        style: {
            backgroundColor: "#F0EDED",
            marginTop: 10,
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
};

const CONFIG_TYPE = {
    page: 'HALAMAN',
    file_limit: 'LIMITASI_FILE'
};

const PAGE = {
    homepage: 'c74e4d06-e396-41e3-a777-0e1e95566976',
    about: '72f9c4a8-8dc9-4182-854b-1f990bd529ba'
};

const LIST_FILE = {
    'kb': Array.from({ length: 1000 }, (_, i) => ({ label: i + 1, value: i + 1 })), // sampai 1000
    'mb': Array.from({ length: 100 }, (_, i) => ({ label: i + 1, value: i + 1 })), // sampai 100
};

const MHS_STATUS = [
    {
        label: 'PENDING',
        value: 'PENDING'
    },
    {
        label: 'ACTIVE',
        value: 'ACTIVE'
    },
    {
        label: 'INACTIVE',
        value: 'INACTIVE'
    }
];

export { GENDER_LIST, SUBMISSION_TYPE, RESEARCH_SCHEMAS, RESEARCH_SPACES, MAX_FILE_SIZE, SUBMISSION_STATUS, TABLE_STYLE, SBA_STATUS, SUBMISSION_STATUS_TYPE, CONFIG_TYPE, PAGE, LIST_FILE, MHS_STATUS};
