import React, { useState } from "react";
import { Input, Select } from "../../../Form";

export default function Filter({ limit, page, onSubmitFilter, studyProgram, risetGrup }) {
    const [filter, setFilter] = useState({
        from: '',
        until: '',
        study_program: '',
        type: '',
        research_space: '',
        limit,
        page
    });

    const onChangeFilter = (state, value) => {
        setFilter({ ...filter, [state]: value });
    };


    const { from, until, study_program, type, research_space } = filter;
    const forms = [
        {
            state: "from",
            value: from,
            type: "date",
            label: 'Dari tanggal'
        },
        {
            state: "until",
            value: until,
            type: "date",
            label: 'Sampai tanggal'
        },
        {
            state: "study_program",
            value: study_program,
            type: "select",
            label: 'Program studi',
            options: studyProgram
        },
        {
            state: "type",
            value: type,
            type: "select",
            label: 'Jenis matkul',
            options: [
                {
                    label: 'SIDANG',
                    value: 'SIDANG'
                },
                {
                    label: 'SEMINAR',
                    value: 'SEMINAR'
                }
            ]
        },
        {
            state: "research_space",
            value: research_space,
            type: "select",
            label: 'Bidang penelitian',
            options: risetGrup
        },
    ];

    const RenderForm = ({ state, val, type, label, options }) => {
        switch (type) {
            case "select":
                return (
                    <Select
                        options={options}
                        label={label}
                        onChangeValue={(value) => {
                            console.log({ value })
                            onChangeFilter(state, value)
                        }}
                    />
                );
            default:
                return (
                    <Input
                        label={label}
                        type={type}
                        onChangeValue={(value) => {
                            console.log({ value })
                            if (type === 'date') {
                                value = new Date(value).toISOString();
                            }
                            onChangeFilter(state, value)
                        }}
                    ></Input>
                );
        }
    };
    return (
        <div className="d-flex flex-row gap-2 mx-3">
            {forms.map((form, key) => (
                <React.Fragment key={key}>
                    {RenderForm(form)}
                </React.Fragment>
            ))}
            <div className="d-flex flex-col align-items-center">
                <input
                    type="button"
                    className="btn btn-warning mt-3"
                    value="Submit"
                    onClick={() => {
                        onSubmitFilter(filter);
                    }}
                />
            </div>
        </div>
    );
}
