import React, { useState } from "react";
import { toast } from "react-toastify";
import { checkPassword } from "../../../helper";
import { Input } from "../../Form";
import { Auth } from "../../../services";

export function ChangePassword(props) {
    const auth = new Auth();
    const { user } = props;

    const [payload, setPayload] = useState({
        user_id: user?.user_id,
        old_password: "",
        password: "",
        confirm_password: "",
    });

    const [shown, setShown] = useState({
        old_password: "",
        password: "",
        confirm_password: "",
    });

    const [invalidPassword, setInvalidPassword] = useState({
        format: false,
        confirmPassword: false,
    });

    const handlePayload = (state, value) => {
        if (state === "password") {
            if (!checkPassword(value) && value.length > 0) {
                setInvalidPassword({ ...invalidPassword, format: true });
            } else {
                setInvalidPassword({ ...invalidPassword, format: false });
            }
        }

        if (state === "confirm_password") {
            if (value !== payload.password) {
                setInvalidPassword({
                    ...invalidPassword,
                    confirmPassword: true,
                });
            } else {
                setInvalidPassword({
                    ...invalidPassword,
                    confirmPassword: false,
                });
            }
        }

        setPayload({ ...payload, [state]: value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (enabledButton()) {
            try {
                await auth.set_password(payload);
                toast.success("Password berhasil diubah");
            } catch (e) {
                console.error({ e });
                toast.error(`${e.message}`);
            }
        }
    };

    const enabledButton = () => {
        const passwordIsValid = Object.values(invalidPassword).every(
            (v) => v === false
        );
        const payloadIsValid = Object.values(payload).every(
            (v) => v !== "" && v !== null
        );

        return passwordIsValid && payloadIsValid;
    };

    const data = [
        {
            label: "Kata Sandi Lama:",
            value: payload.old_password,
            state: "old_password",
            placeholder: "Masukkan Kata Sandi Lama Anda",
        },
        {
            label: "Kata Sandi Baru:",
            value: payload.password,
            state: "password",
            placeholder: "Masukkan Kata Sandi Baru Anda",
        },
        {
            label: "Ulang Kata Sandi Baru:",
            value: payload.confirm_password,
            state: "confirm_password",
            placeholder: "Ulang Kata Sandi Baru Anda",
        },
    ];

    return (
        <div className="container">
            <form onSubmit={onSubmit}>
                {data.map((item, i) => (
                    <div className="row my-3" key={i}>
                        <div className="col-md-4 font-weight-bold">
                            {item.label}
                        </div>
                        <div className="col-md-6">
                            <Input
                                required
                                className="mb-0 input-group"
                                type={shown[item.state] ? "text" : "password"}
                                value={payload[item.state]}
                                onChangeValue={(value) =>
                                    handlePayload(item.state, value)
                                }
                            >
                                <button
                                    type="button"
                                    className="input-group-text"
                                    id="input-group-right-example"
                                    onClick={() =>
                                        setShown({
                                            ...shown,
                                            [item.state]: !shown[item.state],
                                        })
                                    }
                                >
                                    {shown[item.state] ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-eye"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-eye-slash"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                        </svg>
                                    )}
                                </button>
                            </Input>
                            {item.state === "password" &&
                                invalidPassword.format && (
                                    <React.Fragment>
                                        <label
                                            className="font-weight-bold text-sm text-danger"
                                            style={{ fontSize: 11 }}
                                        >
                                            * Password minimal terdiri dari 7
                                            karakter berupa kombinasi huruf
                                            kapital, huruf kecil, simbol, dan
                                            angka
                                        </label>
                                        <br />
                                    </React.Fragment>
                                )}
                            {item.state === "confirm_password" &&
                                invalidPassword.confirmPassword && (
                                    <React.Fragment>
                                        <label
                                            className="font-weight-bold text-sm text-danger"
                                            style={{ fontSize: 11 }}
                                        >
                                            * Password tidak sama
                                        </label>
                                        <br />
                                    </React.Fragment>
                                )}
                        </div>
                    </div>
                ))}
                <div className="text-center my-5">
                    <button
                        type="submit"
                        className="btn bg-bluesky"
                        disabled={!enabledButton()}
                    >
                        SIMPAN
                    </button>
                </div>
            </form>
        </div>
    );
}
