import {
    BackButton,
    Input,
    Navbar,
    Select,
    TextArea,
} from "../../../../components";
import React, { useEffect } from "react";
import { useAuth } from "../../../../util/ProtectedRoute";
import "./View.css";
import { useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Mahasiswa, Reference } from "../../../../services";
import { toast } from "react-toastify";
import { GENDER_LIST, MHS_STATUS, onKeyPress } from "../../../../helper";

export default function View() {
    const { id } = useParams();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get("action");
    const isRead = action === "read";

    const mahasiswa_service = new Mahasiswa();
    const reference = new Reference();
    const [loading, setLoading] = useState(true);
    const [studyProgram, setStudyProgram] = useState([]);
    const ctx = useAuth();
    const [payload, setPayload] = useState({});
    const [defaultValue, setDefaultValue] = useState({
        id_jurusan: {},
        jenis_kelamin: {},
        status: {},
    });
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    useEffect(() => {
        const getStudyProgram = async () => {
            try {
                const resp = await reference.study_program_list(1, 100000);
                const sp = resp.data.list.map((item) => ({
                    value: item.id,
                    label: item.nama,
                    code: item.kode,
                }));
                setStudyProgram([...sp]);

                getMahasiswa(sp);
            } catch (e) {}
        };

        const getMahasiswa = async (sp) => {
            if (["update", "read"].includes(action)) {
                try {
                    const resp = await mahasiswa_service.detail(id);
                    if (!resp.data) {
                        throw new Error("Data not found");
                    }

                    const mahasiswa = resp.data;
                    mahasiswa.tanggal_lahir =
                        mahasiswa.tanggal_lahir.split("T")[0];

                    mahasiswa.id_jurusan = mahasiswa.jurusan.id;
                    defaultValue.id_jurusan =
                        sp?.length > 0 &&
                        sp.find((sp) => sp.value === mahasiswa.jurusan.id);
                    defaultValue.jenis_kelamin =
                        GENDER_LIST?.length > 0 &&
                        GENDER_LIST.find(
                            (sp) => sp.value === mahasiswa.jenis_kelamin
                        );
                    defaultValue.status = MHS_STATUS?.length > 0 &&
                        MHS_STATUS.find(
                            (sp) => sp.value === mahasiswa.user?.status
                        );
                    setPayload({ ...mahasiswa });
                    setDefaultValue({ ...defaultValue });
                    setLoading(false);
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    // navigate(-1);
                }
            } else {
                setLoading(false);
            }
        };

        // getMahasiswa();
        getStudyProgram();
    }, []);

    const RenderForm = (data) => {
        const { type, state, options } = data;

        switch (type) {
            case "text":
            case "date":
            case "email":
            case "password":
            case "number":
                return (
                    <Input
                        disabled={isRead}
                        required
                        // type={type}
                        
                        // value={payload[state]}
                        // onChangeValue={(value) =>
                        //     setPayload({ ...payload, [state]: type === 'number' ? Number(value) : value })
                        // }
                        value={payload[state]}
                        type={type === 'number' ? 'text' : type}
                        {...(type === "number" ? { onKeyPress: onKeyPress } : {})}
                        onChangeValue={(value) => {

                            const val = type === 'number' && value.length > 0 && value[value.length - 1] !== '.' ? Number(value) : value;
                            setPayload({ ...payload, [state]: val })
                        }}
                    />
                );
            case "select":
                return (
                    <Select
                        required
                        defaultValue={defaultValue[state]}
                        options={options}
                        onChangeValue={(value) => {
                            setPayload({ ...payload, [state]: value });
                        }}
                        isDisabled={isRead}
                    />
                );
            case "textarea":
                return (
                    <TextArea
                        value={payload[state]}
                        onChangeValue={(value) =>
                            setPayload({ ...payload, [state]: value })
                        }
                        disabled={isRead}
                    />
                );
            default:
                return <>test</>;
        }
    };

    const columns = useMemo(() => {
        let col = [
            {
                label: "NIM",
                value: "",
                type: "text",
                state: "nim",
            },
            {
                label: "Nama",
                value: "",
                type: "text",
                state: "nama",
            },
            {
                label: "Jurusan",
                value: "",
                type: "select",
                state: "id_jurusan",
                options: studyProgram,
            },
            {
                label: "Tahun Masuk",
                value: "",
                type: "number",
                state: "tahun_masuk",
            },
            {
                label: "Jenis Kelamin",
                value: "",
                type: "select",
                state: "jenis_kelamin",
                options: GENDER_LIST,
            },
            {
                label: "Tempat Lahir",
                value: "",
                type: "text",
                state: "tempat_lahir",
            },
            {
                label: "Tanggal Lahir",
                value: "",
                type: "date",
                state: "tanggal_lahir",
            },
            {
                label: "Alamat",
                value: "",
                type: "textarea",
                state: "alamat",
            },
            {
                label: "No Telp",
                value: "",
                type: "text",
                state: "no_telp",
            },
            {
                label: "Status User",
                value: "",
                type: "select",
                state: "status",
                options: MHS_STATUS,
            },
        ];

        if (action === "add") {
            col = [
                ...col,
                {
                    label: "Email",
                    value: "",
                    type: "email",
                    state: "email",
                },
                {
                    label: "Password",
                    value: "",
                    type: "password",
                    state: "password",
                },
                {
                    label: "Ulangi Password",
                    value: "",
                    type: "password",
                    state: "confirm_password",
                },
            ];
        }

        return col;
    });

    const generateTitle = () => {
        switch (action) {
            case "read":
                return "Detail";
            case "update":
                return "Ubah";
            default:
                return "Tambah";
        }
    };

    const onSubmit = async (e) => {
        if (isRead) {
            return;
        }

        if (!["update", "add"].includes(action)) {
            return;
        }

        if (action === "update") {
            await updateMahasiswa();
        } else if (action === "add") {
            await addMahasiswa();
        }
    };

    const addMahasiswa = async () => {
        try {
            if (payload.password !== payload.confirm_password) {
                toast.error('Password tidak sama, harap periksa password kembali');
                return
            }
            const data = {
                id: payload.id,
                fullname: payload.nama,
                study_program: payload.id_jurusan,
                batch_year: payload.tahun_masuk,
                birth_place: payload.tempat_lahir,
                birth_date: payload.tanggal_lahir,
                gender: payload.jenis_kelamin,
                address: payload.alamat,
                email: payload.email,
                password: payload.password,
                phone: payload.no_telp,
                nim: payload.nim,
            };

            await mahasiswa_service.add(data);

            toast.success("Data berhasil ditambahkan");
            navigate("/management/mahasiswa");
        } catch (e) {
            toast.error(JSON.stringify(e));
        }
    };

    const updateMahasiswa = async () => {
        try {
            const data = {
                id: payload.id,
                fullname: payload.nama,
                study_program:
                    typeof payload.id_jurusan !== "string"
                        ? payload.id_jurusan.id
                        : payload.id_jurusan,
                batch_year: payload.tahun_masuk,
                birth_place: payload.tempat_lahir,
                birth_date: payload.tanggal_lahir,
                gender:
                    typeof payload.jenis_kelamin !== "string"
                        ? payload.jenis_kelamin.id
                        : payload.jenis_kelamin,
                address: payload.alamat,
                email: "omama@getnada.com",
                phone: payload.no_telp,
                status: payload.status,
            };
            await mahasiswa_service.update(data);
            toast.success("Data berhasil diubah");
            navigate("/management/mahasiswa");
        } catch (e) {
            toast.error(e);
        }
    };
    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <span className=" col-md-6 rounded-1 fs-4 text-uppercase text-center bg-blue text-white">
                        Menu {generateTitle()} mahasiswa
                    </span>
                </div>

                {!loading &&
                    columns.map((col, key) => (
                        <div className="row col-md-9 my-4" key={key}>
                            <div className="col-md-4 justify-content-end pt-2 label">
                                <span className="fw-bold">{col.label}</span>
                            </div>
                            <div className="col-md-8">{RenderForm(col)}</div>
                        </div>
                    ))}
                {!isRead && (
                    <div className="row col-md-9 my-4 ">
                        <button
                            className="col-md-8 offset-md-4 btn btn-info text-white w-full text-uppercase fw-bold"
                            onClick={onSubmit}
                        >
                            Simpan
                        </button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
