import React from "react";
import "./FAB.css";
import whatsapp from "../../images/whatsapp.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function FAB({}) {
    const tooltip = (
        <Tooltip placement="tooltip">
            Ada kendala? silakan kontak kami
        </Tooltip>
      );
    return (
        <div className="wrapper">
            
            <OverlayTrigger placement="top" overlay={tooltip}>
                <a
                    className="btn btn-secondary"
                    href="https://wa.me/6287803824644"
                    target="_blank"
                >
                    <img src={whatsapp} alt="contact-us" />
                </a>
            </OverlayTrigger>
        </div>
    );
}
