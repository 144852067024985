import { BackButton, Input, Navbar } from "../../../../components";
import React, { useEffect } from "react";
import { useAuth } from "../../../../util/ProtectedRoute";
import "./View.css";
import { useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Prodi, Reference } from "../../../../services";
import { toast } from "react-toastify";

export default function View() {
    const { id } = useParams();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get("action");
    const isRead = action === "read";

    const prodi_service = new Prodi();
    const [loading, setLoading] = useState(true);

    const ctx = useAuth();
    const [payload, setPayload] = useState({});    
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    useEffect(() => {        
        const getProdi = async (sp) => {
            if (["update", "read"].includes(action)) {
                try {
                    const resp = await prodi_service.detail(id);
                    if (!resp.data) {
                        throw new Error("Data not found");
                    }

                    const prodi = resp.data;

                    setPayload({ ...prodi });
                    setLoading(false);
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    // navigate(-1);
                }
            } else {
                setLoading(false);
            }
        };

        getProdi();
    }, []);

    const RenderForm = (data) => {
        const { type, state } = data;

        switch (type) {
            case "text":
                return (
                    <Input
                        disabled={isRead}
                        required
                        type={type}
                        value={payload[state]}
                        onChangeValue={(value) =>
                            setPayload({ ...payload, [state]: value })
                        }
                    />
                );
            default:
                return <>test</>;
        }
    };

    const columns = useMemo(() => {
        return [
            {
                label: "Kode",
                value: "",
                type: "text",
                state: "kode",
            },
            {
                label: "Nama",
                value: "",
                type: "text",
                state: "nama",
            },            
        ];
    });   

    const generateTitle = () => {
        switch (action) {
            case "read":
                return "Detail";
            case "update":
                return "Ubah";
            default:
                return "Tambah";
        }
    };

    const onSubmit = async (e) => {
        if (isRead) {
            return;
        }

        if (!["update", "add"].includes(action)) {
            return;
        }

        if (action === "update") {
            await updateProdi();
        } else if (action === "add") {
            await addProdi();
        }
    };

    const addProdi = async () => {
        try {
            const data = {
                kode: payload.kode,
                nama: payload.nama
            }

            await prodi_service.add(data);

            toast.success("Data berhasil ditambahkan");
            navigate("/management/prodi");
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
    };

    const updateProdi = async () => {
        try {
            await prodi_service.update(payload);
            toast.success("Data berhasil diubah");
            navigate("/management/prodi");
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
    };
    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <span className=" col-md-6 rounded-1 fs-4 text-uppercase text-center bg-blue text-white">
                        Menu {generateTitle()} prodi
                    </span>
                </div>

                {!loading && columns.map((col, key) => (
                    <div className="row col-md-9 my-4" key={key}>
                        <div className="col-md-4 justify-content-end pt-2 label">
                            <span className="fw-bold">{col.label}</span>
                        </div>
                        <div className="col-md-8">{RenderForm(col)}</div>
                    </div>
                ))}
                {!isRead && (
                    <div className="row col-md-9 my-4 ">
                        <button
                            className="col-md-8 offset-md-4 btn btn-info text-white w-full text-uppercase fw-bold"
                            onClick={onSubmit}
                        >
                            Simpan
                        </button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
