import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
    Biodata,
    ForgotPassword,
    Homepage,
    Landing,
    Login,
    Register,
    Submission,
    UpdateSubmission,
    DetailSubmission,

    DetailSubmisionSBA,
    Dosen,
    ViewDosen,
    Prodi,
    ViewProdi,
    SBA,
    ViewSBA,
    Mahasiswa,
    ViewMahasiswa,
    ViewDokumen,
    ScheduleList,

    BidangPenelitian,
    ViewBidangPenelitian
} from "./pages";
import ValidateRegister from "./pages/ValidateRegister/ValidateRegister";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./util/ProtectedRoute";
import SetPassword from "./pages/SetPassword/SetPassword";
import { SUBMISSION_TYPE } from "./helper/constants";
import Grading from "./pages/Grading";
import { Dokumen } from "./pages/Management/Dokumen";
import { File, Page, ValidasiMahasiswa, ViewPage, ViewValidasiMahasiswa } from "./pages/Management";
import About from "./pages/About/About";

function App() {
    const routes = [
        {
            element: <Landing />,
            path: "/",
        },
        {
            element: <Login />,
            path: "/login",
        },
        {
            element: <Register />,
            path: "/register",
        },
        {
            element: <ValidateRegister />,
            path: "/validate-register/:id",
        },
        {
            element: <ForgotPassword />,
            path: "/forgot-password",
        },
        {
            element: <SetPassword />,
            path: "/set-password/:id",
        },
        {
            element: <ScheduleList />,
            path: "/schedule",
        },
        {
            element: <About />,
            path: "/about",
        },
    ];

    const auth_routes = [
        {
            element: <Homepage />,
            path: "/homepage",
        },
        {
            element: <Biodata />,
            path: "/biodata",
        },
        {
            element: <Submission type={SUBMISSION_TYPE.THESIS_SEMINAR} />,
            path: "/pengajuan-seminar-skripsi",
        },
        {
            element: <Submission type={SUBMISSION_TYPE.THESIS} />,
            path: "/pengajuan-skripsi",
        },
        {
            element: <DetailSubmission />,
            path: "/pengajuan-seminar-skripsi/:id",
        },
        {
            element: <UpdateSubmission />,
            path: "/pengajuan-seminar-skripsi/update/:id",
        },
        // SBA

        {
            element: <DetailSubmisionSBA />,
            path: "/sba/pengajuan-seminar-skripsi/:id",
        },

        // Dosen
        {
            element: <Dosen />,
            path: "/management/dosen"
        },
        {
            element: <ViewDosen />,
            path: "/management/dosen/:id"
        },
        {
            element: <Grading />,
            path: "/submission/grading/:id"
        },

        // Prodi
        {
            element: <Prodi />,
            path: "/management/prodi"
        },
        {
            element: <ViewProdi />,
            path: "/management/prodi/:id"
        },

        // SBA
        {
            element: <SBA />,
            path: "/management/sba"
        },
        {
            element: <ViewSBA />,
            path: "/management/sba/:id"
        },

        // Mahasiswa
        {
            element: <Mahasiswa />,
            path: "/management/mahasiswa"
        },
        {
            element: <ViewMahasiswa />,
            path: "/management/mahasiswa/:id"
        },
        // Dokumen
        {
            element: <Dokumen />,
            path: "/management/dokumen"
        },
        {
            element: <ViewDokumen />,
            path: "/management/dokumen/:id"
        },
        // Page   
        {
            element: <Page />,
            path: "/management/page"
        },
        {
            element: <ViewPage />,
            path: "/management/page/:id"
        },
        // Limitasi File 
        {
            element: <File />,
            path: "/management/file"
        },
        // Bidang Penelitian
        {
            element: <BidangPenelitian />,
            path: "/management/bidang-penelitian"
        },
        {
            element: <ViewBidangPenelitian />,
            path: "/management/bidang-penelitian/:id"
        },
        // Validasi Mahasiswa        
        {
            element: <ValidasiMahasiswa />,
            path: "/management/validasi-mahasiswa"
        },
        {
            element: <ViewValidasiMahasiswa />,
            path: "/management/validasi-mahasiswa/:id"
        }
    ];

    return (
        <BrowserRouter>
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                pauseOnHover={false}
            />
            <Routes>
                {routes.map((item, i) => (
                    <Route key={i} path={item.path} element={item.element} />
                ))}
                {auth_routes.map((item, i) => (
                    <Route
                        key={i}
                        path={item.path}
                        element={
                            <ProtectedRoute>{item.element}</ProtectedRoute>
                        }
                    />
                ))}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
