import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "../services";
import { useLocalStorage } from "./useLocalStorage";
import { FAB } from "../components";

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {

    const [user, setUser] = useLocalStorage('user', null); 
    const setUserData = (data) => {
        setUser({ ...data });
    };

    const value = useMemo(() => ({ // eslint-disable-line react-hooks/exhaustive-deps
        user,
        setUserData,
    })); 


    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
};
export const useAuth = () => {
    return useContext(AuthContext);
};

const ProtectedRoute = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useLocalStorage('user', null); 

    const auth = new Auth();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const checkUserToken = async () => {
            const userToken = localStorage.getItem('token');
            if (!userToken || userToken === 'undefined') {
                setIsLoggedIn(false);
                // await localStorage.clear();
                return navigate('/login');
            }
            // check validation user token
            try {
                const resp = await auth.check_user_token(userToken);
                setUser({ ...resp.data });

            } catch (e) {
                console.error({ e });
                // await localStorage.clear();
                return navigate('/login');
            }

            setIsLoggedIn(true);
        };

        checkUserToken()
    }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AuthProvider>
            { user?.level === 'SBA' && <FAB/>}
            {
                isLoggedIn ? props.children : null
            }
        </AuthProvider>
    );
}
export default ProtectedRoute;