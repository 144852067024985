import { CKEditor, Input, Select } from "../../../../components";
import React, { useEffect } from "react";
import { useAuth } from "../../../../util/ProtectedRoute";
import { useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Submission } from "../../../../services";
import { toast } from "react-toastify";
import { SUBMISSION_STATUS_TYPE } from "../../../../helper/constants";
import { onKeyPress } from "../../../../helper";

export default function Grading({ id }) {
    const navigate = useNavigate();

    const submission = new Submission();
    const [loading, setLoading] = useState(false);

    const ctx = useAuth();
    const [summary, setSummary] = useState({
        grade: '',
        total_nilai_sidang: ''
    });

    const [payload, setPayload] = useState([
        {
            id: "fed3b216-2ba7-407b-a0a4-c7365a61ac1e",
            id_pengajuan: "2a4f1231-3e8f-4bf1-bc1c-978aedafdaba",
            id_dosen_penguji: "e2185fec-9265-4687-a08a-6bccff05ecf9",
            penguasaan_ilmu: 0,
            susunan_bahasa: 0,
            sidang: 0,
            catatan: "",
        },
        {
            id: "303d3cf7-a7c0-4925-8f22-5b4923cf1c3b",
            id_pengajuan: "2a4f1231-3e8f-4bf1-bc1c-978aedafdaba",
            id_dosen_penguji: "310b467c-aa82-47cd-b76b-33ec29107618",
            penguasaan_ilmu: 0,
            susunan_bahasa: 0,
            sidang: 0,
            catatan: "",
        },
        {
            id: "303d3cf7-a7c0-4925-8f22-5b4923cf1c3b",
            id_pengajuan: "2a4f1231-3e8f-4bf1-bc1c-978aedafdaba",
            id_dosen_penguji: "310b467c-aa82-47cd-b76b-33ec29107618",
            penguasaan_ilmu: 0,
            susunan_bahasa: 0,
            sidang: 0,
            catatan: "",
        },
        {
            id: "303d3cf7-a7c0-4925-8f22-5b4923cf1c3b",
            id_pengajuan: "2a4f1231-3e8f-4bf1-bc1c-978aedafdaba",
            id_dosen_penguji: "310b467c-aa82-47cd-b76b-33ec29107618",
            penguasaan_ilmu: 0,
            susunan_bahasa: 0,
            sidang: 0,
            catatan: "",
        },
    ]);
    const [gradingList, setGradingList] = useState([]);
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    useEffect(() => {
        getSubmissionGrading();
    }, []);

    const getSubmissionGrading = async () => {
        try {
            const resp = await submission.list_grading(id);
            if (resp.data.list.length > 0) {
                setSummary({
                    grade: resp.data.grade,
                    total_nilai_sidang: resp.data.total_nilai_sidang
                })
                var counterPembimbing = 1;
                var counterPenguji = 1;
                for (let i in resp.data.list) {
                    payload[i].id =
                        resp.data.list[i].id;
                    payload[i].id_pengajuan =
                        resp.data.list[i].id_pengajuan;
                    payload[i].id_dosen_penguji =
                        resp.data.list[i].id_dosen_penguji;
                    payload[i].penguasaan_ilmu =
                        resp.data.list[i].penguasaan_ilmu;
                    payload[i].susunan_bahasa =
                        resp.data.list[i].susunan_bahasa;
                    payload[i].sidang = resp.data.list[i].sidang;
                    payload[i].catatan = resp.data.list[i].catatan;
                    if (resp.data.list[i].is_pembimbing) {
                        resp.data.list[i].counter_pembimbing = counterPembimbing;
                        counterPembimbing++;
                    } else {
                        resp.data.list[i].counter_penguji = counterPenguji;
                        counterPenguji++;
                    }

                    if (Number(i) === resp.data.list.length - 1) {
                        setPayload([...payload]);
                    }
                }
            }

            console.log({ resp })
            setGradingList(resp.data.list);
        } catch (e) {
            console.log("error: ", e);
            navigate(-1);
        }
    };

    const RenderForm = (data, index, isPembimbing) => {
        const { type, state, options, label, forPembimbing } = data;

        const isPembimbingForm =
            forPembimbing && state !== "sidang" && isPembimbing;
        const isPengujiForm =
            !forPembimbing && state === "sidang" && !isPembimbing;
        const susunanBahasaForm = state === "susunan_bahasa";

        switch (type) {
            case "text":
                if (isPembimbingForm || isPengujiForm || susunanBahasaForm) {
                    return (
                        <>
                            <label className="fw-bold">{label}</label>
                            <Input
                                required
                                onKeyPress={(e) => {
                                    if (state !== "catatan") {
                                        onKeyPress(e);
                                    }
                                }}
                                disabled={[
                                    "rata_rata_sidang_skripsi",
                                    "total_nilai",
                                ].includes(state)}
                                type={type}
                                value={payload[index][state]}
                                onChangeValue={(value) => {
                                    payload[index][state] =
                                        state === "catatan"
                                            ? value
                                            : Number(value);
                                    setPayload([...payload]);
                                }}
                            />
                        </>
                    );
                }
                return null;
            case "ckeditor":
                return (
                    <CKEditor
                        value={payload[index][state]}
                        label={label}
                        onChangeValue={(value) => {
                            payload[index][state] = value;
                            setPayload([...payload]);
                        }}
                    ></CKEditor>
                );
            case "select":
                console.log(
                    SUBMISSION_STATUS_TYPE.find(
                        (item) => item.value === payload[index][state]
                    )
                );
                return (
                    <>
                        <label className="fw-bold">{label}</label>
                        <Select
                            required
                            options={options}
                            defaultValue={SUBMISSION_STATUS_TYPE.find(
                                (item) => item.value === payload[index][state]
                            )}
                            onChangeValue={(value) => {
                                payload[index][state] = value;
                                setPayload([...payload]);
                            }}
                        />
                    </>
                );
            default:
                return <>test</>;
        }
    };

    let columns = useMemo(() => {
        return [
            {
                label: "Penguasaan Ilmu Ketentuan Dan Kemandirian",
                value: "",
                type: "text",
                state: "penguasaan_ilmu",
                forPembimbing: true,
            },
            {
                label: "Susunan Bahasa Penyajian Pola Pikir Materi",
                value: "",
                type: "text",
                state: "susunan_bahasa",
                forPembimbing: true,
            },
            {
                label: "Nilai Sidang Skripsi",
                value: "",
                type: "text",
                state: "sidang",
                forPembimbing: false,
            },
            {
                label: "Catatan",
                value: "",
                type: "ckeditor",
                state: "catatan",
                forPembimbing: true,
            },
        ];
    });

    const onSubmit = async (gradingID, index) => {
        console.log({ gradingID })
        doSubmission(gradingID, index);
    };

    const doSubmission = async (gradingID, index) => {
        try {
            setLoading(true);
            const data = payload[index];
            await submission.updateGrading(gradingID, data);

            await getSubmissionGrading();
            toast.success("Data berhasil dinilai");
            setLoading(false);
        } catch (e) {
            console.log("error: ", e);
            toast.error(JSON.stringify(e));
            setLoading(false);
        }
    };

    const summaryForms = [
        {
            value: summary.total_nilai_sidang,
            label: 'Total Nilai Sidang Skripsi',
        },
        {
            value: summary.grade,
            label: 'Grade',
        }
    ];

    return (
        <React.Fragment>
            <div className="container">
                {gradingList?.length > 0 &&
                    gradingList.map((grading, key) => {
                        const isPembimbing = grading.is_pembimbing;
                        const buttonColor = isPembimbing
                            ? "btn-primary"
                            : "btn-danger";
                        const buttonText = isPembimbing
                            ? `Dosen Pembimbing ${grading.counter_pembimbing}`
                            : `Dosen Penguji ${grading.counter_penguji}`;
                        return (
                            <React.Fragment key={key}>
                                <div className="d-flex justify-content-center align-items-center">
                                    <span
                                        className={`btn ${buttonColor} rounded-2 h3 text-center text-white fw-bold mt-5`}
                                    >
                                        {buttonText}
                                    </span>
                                </div>
                                {columns.map((col, keyColumn) => (
                                    <div
                                        className="row col-md-12 my-4"
                                        key={keyColumn}
                                    >
                                        <div className="col-md-12">
                                            {RenderForm(col, key, isPembimbing)}
                                        </div>
                                    </div>
                                ))}
                                <div className="row col-md-9 my-4 ">
                                    <button
                                        className="col-md-8 offset-md-4 btn text-white w-full text-uppercase fw-bold bg-purple"
                                        onClick={() =>
                                            onSubmit(grading.id, key)
                                        }
                                        disabled={loading}
                                    >
                                        UBAH
                                    </button>
                                </div>
                            </React.Fragment>
                        );
                    })}
                {
                    summaryForms.map((item, key) => (
                        <React.Fragment key={key}>
                            <label className="fw-bold">{item.label}</label>
                            <Input
                                required
                                disabled
                                type={'text'}
                                value={item.value}
                            />
                        </React.Fragment>
                    ))
                }
            </div>
        </React.Fragment>
    );
}
