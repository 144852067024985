import React from "react";
import { Footer, Navbar, SBA, Mahasiswa, Dosen } from "../../components";
import { useAuth } from "../../util/ProtectedRoute";
import "./Homepage.css";
import { useNavigate } from "react-router-dom";

export default function Homepage() {
    const navigate = useNavigate();
    const ctx = useAuth();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    const RenderContent = () => {
        switch (user.level) {
            case "SBA":
                return <SBA />;
            case "MHS":
                return <Mahasiswa user={user} />;
            case "DSN":
                return <Dosen />;
            default:
                // localStorage.clear();
                navigate("/login");
        }
    };
    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="">{RenderContent()}</div>
            <Footer />
        </React.Fragment>
    );
}
