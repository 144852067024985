export default class Reference {
    study_program_list = async (page, limit, keyword = '') => {
        return new Promise((resolve, reject) => {
            let query = (page && limit) ? `page=${page}&limit=${limit}` : 'all=true';
            if (keyword) {
                query = `${query}&keyword=${keyword}`;
            }
            fetch(`${process.env.REACT_APP_URL_SERVICE}/reference/jurusan?includeDeleted=false&${query}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(res => {
                    res.json()
                        .then(data => {
                            if (data.status && data.status === 200) {
                                return resolve(data);
                            }
                            reject(data);
                        });
                })
                .catch(err => {
                    reject(err);
                });
        })
    }

    dosen_list = async (page, limit, nidn = '', researchGroup = '', keyword = '') => {
        return new Promise((resolve, reject) => {
            let query = (page && limit) ? `page=${page}&limit=${limit}` : 'all=true';
            if (nidn) {
                query = `${query}&keyword=${nidn}`;
            }
            if (researchGroup) {
                query = `${query}&research_group=${researchGroup}`;
            }
            if (keyword) {
                query = `${query}&keyword=${keyword}`;
            }
            fetch(`${process.env.REACT_APP_URL_SERVICE}/reference/dosen?${query}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(res => {
                    res.json()
                        .then(data => {
                            if (data.status && data.status === 200) {
                                return resolve(data);
                            }
                            reject(data);
                        });
                })
                .catch(err => {
                    reject(err);
                });
        })
    }

    research_space = async (page, limit) => {
        return new Promise((resolve, reject) => {
            let query = (page && limit) ? `page=${page}&limit=${limit}` : 'all=true';
            fetch(`${process.env.REACT_APP_URL_SERVICE}/reference/research-space?includeDeleted=false&${query}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(res => {
                    res.json()
                        .then(data => {
                            if (data.status && data.status === 200) {
                                return resolve(data);
                            }
                            reject(data);
                        });
                })
                .catch(err => {
                    reject(err);
                });
        })
    }
}