import React from 'react';
import './Alert.css';

export default function Alert({
    message,
    type = '',
    showAlert = false,
    className = ""
}) {
    return (
        <div className="d-flex align-items-center justify-content-center mt-3">
            {showAlert && (
                <div className={`col-md-8 alert alert-${type} alert-dismissible fade show text-center ${className}`} role="alert">
                    {message}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}
        </div>
    )
}