import { getAuthorization } from "../headers";

export default class Dokumen {
    list = async (page, limit, keyword = '') => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            let query = (page && limit) ? `page=${page}&limit=${limit}` : 'all=true';             
            if (keyword) {
                query = `${query}&keyword=${keyword}`;
            }
            fetch(`${process.env.REACT_APP_URL_SERVICE}/dokumen?${query}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
            })            
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }

    detail = async (id) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/dokumen/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }   

    update = async (payload) => {        
        delete payload.nidn;

        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            const fd = new FormData();
            for (const [key, value] of Object.entries(payload)) {
                fd.append(key, value);
            }
            fetch(`${process.env.REACT_APP_URL_SERVICE}/dokumen/${payload.id}`, {
                headers,
                method: "PUT",
                body: fd,
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }   

    
    add = async (payload) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            const fd = new FormData();
            for (const [key, value] of Object.entries(payload)) {
                fd.append(key, value);
            }
            fetch(`${process.env.REACT_APP_URL_SERVICE}/dokumen`, {
                headers,
                method: "POST",
                body: fd,
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    
    delete = async (id) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/dokumen/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }
}