import React from "react";
import {
    BackButton,
    CKEditor,
    Footer,
    Input,
    Navbar,
    Select,
} from "../../components";
import { useAuth } from "../../util/ProtectedRoute";
import { useState } from "react";
import { useEffect } from "react";
import { Reference, Submission as SubmissionService } from "../../services";
import { toast } from "react-toastify";
import {
    RESEARCH_SCHEMAS,
    SUBMISSION_TYPE,
} from "../../helper/constants";
import { useNavigate, useParams } from "react-router-dom";
import { getImage } from "../../helper/utils";

export default function UpdateSubmission() {
    const { id } = useParams();
    const navigate = useNavigate();
    const STATE_NOT_REQUIRED = [
        "research_plan",
        "title_en",
        "abstract_id",
        "abstract_en",
    ];
    const STATE_FOR_REPEATERS = ["last_guiding_prof", "retake_reason"];

    const reference = new Reference();
    const submission = new SubmissionService();
    const ctx = useAuth();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }
    const [irsImageFile, setIrsImageFile] = useState("");
    const [payload, setPayload] = useState({
        type: '',
        irs: null, // form upload
        ipk: "",
        sks: "",
        research_space: "",
        research_topic: "",
        research_plan: "",
        title_id: "",
        title_en: "",
        research_schema: "",
        abstract_id: "",
        abstract_en: "",
        guiding_prof_1: "",
        guiding_prof_2: "",
        take_no: "",
        last_guiding_prof: "",
        retake_reason: "",
        id: "",
    });

    const [dosen, setDosen] = useState(null);
    const [risetGrup, setRisetGrup] = useState([{}]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (async () => getRisetGrup())();
        (async () => getDosenList())();
        (async () => getSubmission())();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getRisetGrup = async () => {
        try {
            const resp = await reference.research_space(1, 10000);
            let data = [
                {
                    value: "",
                    label: "Tidak ada pilihan",
                },
            ];
            data = [
                ...data,
                ...resp.data.list.map((item) => ({
                    value: item.id,
                    label: item.name,
                })),
            ];
            setRisetGrup([...data])

            return data;
        } catch (e) {
            console.log({ e });
        }
    };
    const getSubmission = async () => {
        try {
            const { data } = await submission.detail(id);
            // check only can edit when status is DITOLAK or PENDING
            if (!['DITOLAK', 'PENDING'].includes(data.status)) {
                navigate(-1)
            }
            const image = await getImage(data?.irs_file);

            setIrsImageFile(image);
            setPayload({
                type: data?.tipe,
                irs: data?.irs_file,
                ipk: data?.ipk,
                sks: data?.sks_lulus,
                research_space: data?.bidang_penelitian?.id,
                research_topic: data?.topik,
                research_plan: data?.deskripsi,
                title_id: data?.judul_id,
                title_en: data?.judul_en,
                research_schema: data?.skema_penelitian,
                abstract_id: data?.abstrak_id,
                abstract_en: data?.abstrak_en,
                guiding_prof_1: data?.dosen_p1?.id,
                guiding_prof_2: data?.dosen_p2?.id,
                take_no: data?.pengambilan_ke,
                last_guiding_prof: data?.dosen_pembimbing_sebelumnya?.id,
                retake_reason: data?.alasan_mengulang,
                lead_prof: data?.dosen_ketua?.id,
                id: id,
            });
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
            navigate(-1);
        }
    };
    const getDosenList = async () => {
        try {
            const resp = await reference.dosen_list();
            const data = resp.data.list.map((item) => ({
                value: item.id,
                label: item.nama,
                nidn: item.nidn,
            }));
            setDosen([...data]);
        } catch (e) {
            console.log({ e });
        }
    };

    const handlePayload = (state, value) => {
        payload[state] = value
        setPayload({ ...payload });
    };

    const RenderForm = (form) => {
        const { type, label, state, options } = form;
        const fontSize = 10;
        switch (type) {
            case "text":
            case "file":
                return (
                    <React.Fragment>
                        {state === 'irs' && payload[state] && (
                            <img src={irsImageFile} className="img img-thumbnail" alt="irs_file" />
                        )}
                        <Input
                            {...(type !== "file" ? { value: payload[state] } : {})}
                            type={type}
                            label={label}
                            onChangeValue={(value) => handlePayload(state, value)}
                        >
                            {STATE_NOT_REQUIRED.includes(state) && (
                                <label
                                    className="text-secondary text-sm-start"
                                    style={{ fontSize }}
                                >
                                    *Bisa Menyusul
                                </label>
                            )}
                            {STATE_FOR_REPEATERS.includes(state) && (
                                <label
                                    className="text-secondary text-sm-start"
                                    style={{ fontSize }}
                                >
                                    *Bagi yang mengulang
                                </label>
                            )}
                            {state === "irs" && (
                                <label
                                    className="text-secondary text-sm-start"
                                    style={{ fontSize }}
                                >
                                    *Unggah bukti IRS Siak-NG dalam format jpg,jpeg,
                                    png atau PDF (cukup dengan screenshot, foto atau
                                    pdf). Contoh bukti IRS di SIAK-NG klik disini.
                                </label>
                            )}
                        </Input>
                    </React.Fragment>

                );
            case "ckeditor":
                return (
                    <CKEditor
                        value={payload[state]}
                        label={label}
                        onChangeValue={(value) => handlePayload(state, value)}
                    >
                        {STATE_NOT_REQUIRED.includes(state) && (
                            <label className="text-secondary text-sm-start">
                                *Bisa Menyusul
                            </label>
                        )}
                        {STATE_FOR_REPEATERS.includes(state) && (
                            <label className="text-secondary text-sm-start">
                                *Bagi yang mengulang
                            </label>
                        )}
                    </CKEditor>
                );
            case "select":
                return (
                    <Select
                        defaultValue={generateDefaultValue(state)}
                        required
                        options={options}
                        label={label}
                        onChangeValue={(value) => handlePayload(state, value)}
                    />
                );

            default:
                return <></>;
        }
    };

    const enabledButton = () => {
        const formRequired = { ...payload };

        if (Number(payload['take_no']) === 1) {
            delete formRequired.retake_reason;
            delete formRequired.last_guiding_prof;
        }
        const payloadIsValid = Object.values(formRequired).every(
            (v) => v !== "" && v !== null
        );
        return payloadIsValid;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!enabledButton()) {
            return;
        }
        try {
            setLoading(true);
            const resp = await submission.update(payload);
            if (resp.status === 200) {
                toast.success("Data berhasil diubah");
                navigate("/homepage");
            }
        } catch (e) {
            console.error({ e });
            toast.error(`Error: ${JSON.stringify(e)}`);
        }

        setLoading(false);
    };

    const renderTitle = () => {
        if (payload?.type === SUBMISSION_TYPE.THESIS_SEMINAR) {
            return "Seminar Skripsi";
        }
        return "Skripsi";
    };

    const generateDefaultValue = (state) => {
        switch (state) {
            case 'guiding_prof_1':
            case 'guiding_prof_2':
            case 'last_guiding_prof':
            case 'lead_prof':
                return dosen?.length > 0 ? dosen.find(item => item.value === payload[state]) : null;
            case 'research_space':
                const defaultValue = risetGrup.find(item => item.value === payload[state])
                console.log({ defaultValue })
                return risetGrup.find(item => item.value === payload[state]) || null;
            case 'research_schema':
                return RESEARCH_SCHEMAS.find(item => item.value === payload[state]) || null;
            default:
                return null
        }
    }
    const forms = [
        {
            label: "IPK Terakhir",
            type: "text",
            state: "ipk",
        },
        {
            label: "Jumlah SKS Lulus",
            type: "text",
            state: "sks",
        },
        {
            label: "Bidang Penelitian",
            type: "select",
            state: "research_space",
            options: risetGrup,
        },
        {
            label: "Topik Yang Diajukan",
            type: "text",
            state: "research_topic",
        },
        {
            label: "Deskripsi Rencana Penelitian",
            type: "ckeditor",
            state: "research_plan",
        },
        {
            label: "Judul Dalam Bahasa Indonesia",
            type: "text",
            state: "title_id",
        },
        {
            label: "Judul Dalam Bahasa Inggris",
            type: "text",
            state: "title_en",
        },
        {
            label: "Skema Penelitian",
            type: "select",
            state: "research_schema",
            options: RESEARCH_SCHEMAS,
        },
        {
            label: "Abstrak Dalam Bahasa Indonesia",
            type: "ckeditor",
            state: "abstract_id",
        },
        {
            label: "Abstrak Dalam Bahasa Inggris",
            type: "ckeditor",
            state: "abstract_en",
        },
        {
            label: "Dosen Pembimbing 1",
            type: "select",
            state: "guiding_prof_1",
            options: dosen,
        },
        {
            label: "Dosen Pembimbing 2",
            type: "select",
            state: "guiding_prof_2",
            options: dosen,
        },
        {
            label: "Dosen Ketua Grup Riset",
            type: "select",
            state: "lead_prof",
            options: dosen,
        },
        {
            label: "Pengambilan Ke",
            type: "text",
            state: "take_no",
        },
        {
            label: "Dosen Pembimbing Sebelumnya",
            type: "select",
            state: "last_guiding_prof",
            options: dosen,
        },
        {
            label: "Alasan Mengulang",
            type: "text",
            state: "retake_reason",
        },
        {
            label: "Unggah bukti pengisian IRS Matakuliah Spesial di Siak -NG",
            type: "file",
            state: "irs",
        },
    ];

    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>Ubah Pengajuan {renderTitle()}</label>
            </div>
            <div className="container my-3">
                <BackButton />
                {risetGrup?.length > 0 && forms.map((form, key) => {
                    return (
                        <React.Fragment key={key}>
                            {!loading && (
                                RenderForm(form)
                            )}
                        </React.Fragment>
                    );
                })}
                <div className="row">
                    <button
                        onClick={onSubmit}
                        disabled={!enabledButton() && !loading}
                        type="button"
                        className="row btn btn-info text-white fw-bold"
                    >
                        KIRIM
                    </button>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}
