import { BackButton, CKEditor, Input, Navbar } from "../../../../components";
import React, { useEffect } from "react";
import { useAuth } from "../../../../util/ProtectedRoute";
import "./View.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Config } from "../../../../services";
import { toast } from "react-toastify";

export default function View() {
    const { id } = useParams();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get("action");
    const isRead = action === "read";

    const config_service = new Config();
    const [loading, setLoading] = useState(true);

    const ctx = useAuth();
    const [data, setData] = useState({});
    const [payload, setPayload] = useState({});
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    useEffect(() => {
        const getConfig = async () => {
            if (["update", "read"].includes(action)) {
                try {
                    const resp = await config_service.detail(id);
                    if (!resp.data) {
                        throw new Error("Data not found");
                    }

                    const dokumen = resp.data;
                    setData({ ...dokumen });
                    setLoading(false);
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    // navigate(-1);
                }
            } else {
                setLoading(false);
            }
        };

        getConfig();
    }, []);

    const handlePayload = (value) => {
        setPayload({ value });
    };

    const onSubmit = async (e) => {
        if (isRead) {
            return;
        }

        await updateConfig();        
    };

    const updateConfig = async () => {
        try {   
            setLoading(true);
            const { name, datatype, description } = data.item     
            const p = {
                value: payload.value,
                id,
                name,
                datatype,
                description
                
            };

            await config_service.update(p);
            toast.success("Data berhasil diubah");
            navigate("/management/page");
        } catch (e) {
            toast.error(e);
            setLoading(true);
        }
    };
    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>
                {!loading && (                    
                    <React.Fragment>
                        {
                            isRead 
                            ? (
                                <div dangerouslySetInnerHTML={{ __html: data?.item?.value }} />
                            )
                            : (

                                <CKEditor
                                    withImage
                                    value={data?.item?.value}
                                    label={data?.item?.name}
                                    onChangeValue={(value) => {
                                        handlePayload(value)
                                    }
                                    }
                                >                            
                                </CKEditor>
                            )
                        }
                    </React.Fragment>
                )}
                {!isRead && (
                    <div className="row col-md-9 my-4 ">
                        <button
                            className="col-md-8 offset-md-4 btn btn-info text-white w-full text-uppercase fw-bold"
                            onClick={onSubmit}
                        >
                            Simpan
                        </button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
