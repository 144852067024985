import React from "react";
import Clock from 'react-live-clock';
import { Footer, Navbar } from "../../components";
import { Config } from "../../services";
import './Landing.css';
import { useEffect } from "react";
import { useState } from "react";
import { PAGE } from "../../helper/constants";

export default function Landing() {
    const [loading, setLoading] = useState(true);
    const [config, setConfig] = useState({});
    const config_service = new Config();

    useEffect(() => {
        const getConfig = async () => {
            try {
                const id = PAGE.homepage;
                const resp = await config_service.detail(id);
                if (!resp.data) {
                    throw new Error("Data not found");
                }

                const configs = resp.data;
                setConfig({ ...configs });
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                // navigate(-1);
            }
        };

        getConfig();
    }, []);   

    return (
        <React.Fragment>
            <Navbar isLandingPage={true}/>
            <div className={`d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}>
                <label>Selamat Datang Di Chemons Fakultas Teknik Universitas Indonesia</label>
            </div>
            <div className="d-flex align-items-end justify-content-end time text-blue mr-2">
                <Clock format={'DD-MM-YYYY h:mm'} ticking={true} />
            </div>
            <div className="container body" dangerouslySetInnerHTML={{ __html: config?.item?.value }}>                                
            </div>
            <Footer/>
        </React.Fragment>
    )

}