import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import "./Navbar.css";
import { formatDate, getImage } from "../../helper/utils";
import { Notification as NotificationService } from "../../services";
import avatar from "../../images/avatar.png";

export default function Navbar({ isLandingPage, isLogin = false, user = {} }) {
    const notification_service = new NotificationService();
    const navigate = useNavigate();
    const [image, setImage] = useState("");
    const [unreadNotification, setUnreadNotification] = useState(0);
    const [notification, setNotification] = useState(null);
    const [notifLoading, setNotifLoading] = useState(false);
    const routes = [
        {
            label: "Beranda",
            path: isLogin ? "/homepage" : "/",
        },
        {
            label: "Jadwal Sidang",
            path: "/schedule",
        },
        {
            label: "Tentang",
            path: "/about",
        },
    ];
    const pathName = window.location.pathname;

    useEffect(() => {
        (async () => getUserImage())();
        (async () => getNotification())();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        (async () => getUserImage())();
    }, [user])
    const isActive = (path) => {
        return path === pathName
            ? "btn btn-primary text-white bg-blue font-weight-bold"
            : "btn btn-outline-primary border-0";
    };

    const logout = () => {
        localStorage.clear();
        navigate("/login");
    };

    const getUserImage = async () => {
        const img = await getImage(user?.profile_picture);
        setImage(img);
    };

    const getNotification = async () => {
        try {
            const resp = await notification_service.list({
                page: 1,
                limit: 20,
            });
            setNotification([...resp.data.list]);
            setUnreadNotification(resp.data.unread);
        } catch (e) {
            console.log({ e });
        }
    };

    const readNotif = async (id, url) => {
        try {
            await notification_service.patch(id);
            await getNotification();
            navigate(url);
        } catch (e) {
            console.log({ e });
        }
    };

    const goToBiodata = () => {
        navigate("/biodata");
    };

    const col = user?.level === 'SBA' ? 1 : 1;
    const managementMenus = [
        {
            label: 'User',
            path: '/management/sba'
        },
        {
            label: 'Prodi',
            path: '/management/prodi'
        },
        {
            label: 'Dosen',
            path: '/management/dosen'
        },
        {
            label: 'Mahasiswa',
            path: '/management/mahasiswa'
        },
        {
            label: 'Dokumen',
            path: '/management/dokumen'
        },
        {
            label: 'Bidang Penelitian',
            path: '/management/bidang-penelitian'
        },
        {
            label: 'Validasi Mahasiswa',
            path: '/management/validasi-mahasiswa'
        },
    ];

    const settingsMenus = [
        {
            label: 'Halaman',
            path: '/management/page'
        },
        {
            label: 'Limitasi File',
            path: '/management/file'
        },
    ];

    const handleAllReadNotification = async () => {
        try {
            await notification_service.patch();
            await getNotification();

        } catch (e) {
            console.log({ e });
        }
    }

    return (
        <>
            <div className="d-flex col-md-12 bd-highlight bg-white mb-3">
                <div className="d-flex p-2 bd-highlight col-md-2">
                    <img src={logo} alt="logo" className="object-fit-contain" />
                </div>
                <div className="d-flex col-md-6 p-2 flex-grow-1 bd-highlight flex-wrap">
                    <div className="col-12 row align-items-center justify-content-around">
                        {routes.map((route, i) => (
                            <React.Fragment key={i}>
                                <div className={`col-md-${col} text-blue`}>
                                    <Link
                                        to={route.path}
                                        className={`nav-text ${isActive(
                                            route.path
                                        )}`}
                                    >
                                        {route.label}
                                    </Link>
                                </div>
                            </React.Fragment>
                        ))}
                        {user?.level === 'SBA' && (
                            <React.Fragment>
                                <div className="col-md-2">
                                    <div className="dropdown">
                                        <Link
                                            className={`nav-text ${isActive('s')} dropdown-toggle`}
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Manajemen
                                        </Link>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            {managementMenus.map((mj, key) => (
                                                <li key={key}>
                                                    <Link className="dropdown-item text-center text-blue " to={mj.path}>
                                                        {mj.label}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="dropdown">
                                        <Link
                                            className={`nav-text ${isActive('s')} dropdown-toggle`}
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Pengaturan
                                        </Link>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            {settingsMenus.map((mj, key) => (
                                                <li key={key}>
                                                    <Link className="dropdown-item text-center text-blue " to={mj.path}>
                                                        {mj.label}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="d-flex col-md-4 p-2 bd-highlight">
                    <div className="row col-md-12 align-items-center justify-content-end">
                        {isLandingPage && (
                            <React.Fragment>
                                <div className="col-md-6"></div>
                                <div className="col-md-3">
                                    <Link
                                        to="/login"
                                        className="btn btn-outline-primary"
                                    >
                                        Masuk
                                    </Link>
                                </div>
                                <div className="col-md-3">
                                    <Link
                                        to="/register"
                                        className="btn btn-warning text-blue"
                                    >
                                        Daftar
                                    </Link>
                                </div>
                            </React.Fragment>
                        )}
                        {isLogin && (
                            <React.Fragment>
                                <div className="col-md-2">
                                    <div className="btn-group">
                                        <button
                                            type="button"
                                            className="btn btn-secondary bg-navbar text-black d-flex justify-content-center align-items-center"
                                            data-bs-toggle="dropdown"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="25"
                                                height="27"
                                                fill="currentColor"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                                            </svg>
                                            <div
                                                style={{
                                                    fontSize: 16,
                                                    width: 25,
                                                    height: 27,
                                                }}
                                                className="rounded-circle bg-warning d-flex justify-content-center align-items-center font-weight-bold text-white"
                                            >
                                                <label>
                                                    {unreadNotification}
                                                </label>
                                            </div>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-wrapper" style={{ width: notification?.length < 1 ? 500 : 'auto' }}>
                                            <li className="border-bottom caption py-2 row">
                                                <span className="fw-bold col-md-8">
                                                    Notifikasi
                                                </span>
                                                <a className="fw-bold col-md-4 text-sm py-2 link-primary pe-auto" style={{ fontSize: 10 }} onClick={handleAllReadNotification}>
                                                    Mark as all read
                                                </a>
                                            </li>
                                            {notification?.length > 0 &&
                                                notification.map(
                                                    (notif, key) => (
                                                        <li
                                                            key={key}
                                                            className="border-bottom dropdown-item"
                                                            style={{
                                                                background:
                                                                    notif.read
                                                                        ? "#FFF"
                                                                        : "#E7E5E5",
                                                            }}
                                                            onClick={() =>
                                                                readNotif(
                                                                    notif.id,
                                                                    notif.url
                                                                )
                                                            }
                                                        >
                                                            <span className="dropdown-item fw-bold notif-title">
                                                                {notif.title}
                                                            </span>
                                                            <div className="notify-body">
                                                                <span className="dropdown-item notif-body">
                                                                    {
                                                                        notif.content
                                                                    }
                                                                </span>
                                                                <span className="dropdown-item notif-body text-end fw-bold">
                                                                    {formatDate(
                                                                        notif.created_at
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="btn-group">
                                        <button
                                            type="button"
                                            className="btn btn-secondary bg-navbar text-black d-flex justify-content-center align-items-center"
                                            data-bs-toggle="dropdown"
                                        >
                                            <img
                                                src={
                                                    user?.profile_picture
                                                        ? image
                                                        : avatar
                                                }
                                                height={27}
                                                width={27}
                                                className="rounded-circle"
                                                alt="profile_image"
                                            />
                                            <span className="name text-capitalize">
                                                {user?.fullname}
                                            </span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                                <div className="dropdown-item">
                                                    <div
                                                        className="text-decoration-none text-black"
                                                        onClick={goToBiodata}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-person-vcard"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z" />
                                                            <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z" />
                                                        </svg>
                                                        <span
                                                            className="px-3 font-weight-bold"
                                                            style={{
                                                                fontSize: 15,
                                                            }}
                                                        >
                                                            Biodata
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                    className="dropdown-item"
                                                    onClick={logout}
                                                >
                                                    <span>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-box-arrow-right"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                                                            />
                                                        </svg>
                                                        <Link
                                                            to="#"
                                                            className="text-decoration-none px-3 font-weight-bold text-black"
                                                            style={{
                                                                fontSize: 15,
                                                            }}
                                                        >
                                                            Logout
                                                        </Link>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
