import React from "react";
import { BackButton, Footer, Input, ModalPopup, Navbar } from "../../../components";
import { useAuth } from "../../../util/ProtectedRoute";
import { useMemo } from "react";
import { BidangPenelitian as BidangPenelitianService, Mahasiswa } from "../../../services";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { TABLE_STYLE } from "../../../helper/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDebounce } from "../../../helper/utils";

export default function ValidasiMahasiswa() {
    const mahasiswa_service = new Mahasiswa();
    const ctx = useAuth();
    const navigate = useNavigate();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    const [modalVisible, setModalVisible] = useState({
        visible: false,
        action: '',
        mahasiswaId: ''
    });
    const [mahasiswa, setMahasiswa] = useState([]);
    const [action, setAction] = useState('');
    const [pagination, setPagination] = useState({
        limit: 5,
        page: 1,
        total: 0,
    });
    // With mahasiswa
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5); //change to 10 after you remove paginationRowsPerPageOptions
    const [currentPage, setCurrentPage] = useState(1);
    const [keyword, setKeyword] = useState('');
    const debounceSearchTerm = useDebounce(keyword, 500);


    useEffect(() => {
        getMahasiswa(1, rowsPerPage, debounceSearchTerm)
        setCurrentPage(1)
    }, [debounceSearchTerm]);

    useEffect(() => {
        getMahasiswa(pagination.page, pagination.limit, true);
    }, []);

    const getMahasiswa = async (page, limit, kw) => {
        setLoading(true);
        try {
            const resp = await mahasiswa_service.list(page, limit, '', true, kw);
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            setMahasiswa([...resp.data.list]);
            setPagination({ ...pagination, total: Number(resp.data.total) });
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        getMahasiswa(page, rowsPerPage);
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        if (!mahasiswa.length) return;
        getMahasiswa(1, newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const onValidate = async (id, action) => {
        try {
            const captions = action === 'activate' ? 'divalidasi' : 'ditolak';
            await mahasiswa_service.validate(id, action);
            toast.success(`Data berhasil ${captions}`);
            setModalVisible({
                ...modalVisible,
                visible: !modalVisible.visible
            })
            getMahasiswa(pagination.page, pagination.limit, '');
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
    }

    const columns = useMemo(() => [
        {
            name: "No",
            width: "50px",
            selector: (row) => (
                <span className="col col-sm-1">
                    {pagination.page < 1 ? row.no : row.no}
                </span>
            ),
        },
        {
            name: "Nama",
            selector: (row) => <span>{row?.nama}</span>,
        },
        {
            name: "NPM",
            selector: (row) => <span>{row?.nim}</span>,
        },
        {
            name: "Email",
            selector: (row) => <span>{row?.user?.email}</span>,
        },
        {
            name: "Aksi",
            selector: (row) => (
                <div className="d-flex flex-row gap-3">
                    <span className="btn btn-dark text-white text-sm" onClick={() => navigate(`/management/validasi-mahasiswa/${row.id}?action=read`)}>Lihat</span>
                    <button className="btn btn-success text-white text-sm" onClick={() => {
                        // onValidate(row.id, 'activate')
                        // setMahasiswaId(row.id);
                        // setAction('activate');
                        setModalVisible({
                            visible: true,
                            action: 'activate',
                            mahasiswaId: row.id
                        });
                    }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-check-circle"
                            viewBox="0 0 16 16"
                        >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                    </button>
                    <button className="btn btn-danger text-white text-sm" onClick={() => {
                        // onValidate(row.id, 'deactivate')
                        // setMahasiswaId(row.id);
                        // setAction('deactivate');
                        // setModalVisible(!modalVisible);
                        setModalVisible({
                            visible: true,
                            action: 'deactivate',
                            mahasiswaId: row.id
                        });
                    }}>
                        <svg version="1.1"
                            fill="currentColor" id="cross-11" xmlns="http://www.w3.org/2000/svg" width="11px" height="11px" viewBox="0 0 11 11">
                            <path d="M2.2,1.19l3.3,3.3L8.8,1.2C8.9314,1.0663,9.1127,0.9938,9.3,1C9.6761,1.0243,9.9757,1.3239,10,1.7&#xA;&#x9;c0.0018,0.1806-0.0705,0.3541-0.2,0.48L6.49,5.5L9.8,8.82C9.9295,8.9459,10.0018,9.1194,10,9.3C9.9757,9.6761,9.6761,9.9757,9.3,10&#xA;&#x9;c-0.1873,0.0062-0.3686-0.0663-0.5-0.2L5.5,6.51L2.21,9.8c-0.1314,0.1337-0.3127,0.2062-0.5,0.2C1.3265,9.98,1.02,9.6735,1,9.29&#xA;&#x9;C0.9982,9.1094,1.0705,8.9359,1.2,8.81L4.51,5.5L1.19,2.18C1.0641,2.0524,0.9955,1.8792,1,1.7C1.0243,1.3239,1.3239,1.0243,1.7,1&#xA;&#x9;C1.8858,0.9912,2.0669,1.06,2.2,1.19z" />
                        </svg>
                    </button>
                </div>
            ),

        }
    ]);


    const handleSearch = val => {
        setKeyword(val);
    }
    return (
        <React.Fragment>
            <ModalPopup visible={modalVisible.visible} onClose={() => setModalVisible({
                ...modalVisible,
                visible: !modalVisible.visible
            })} height={150} width={500}>
                <label>Apakah anda yakin {`${modalVisible.action === 'activate' ? 'validasi' : 'tidak validasi'}`} mahasiswa ini?</label>
                <div className="text-end mt-5">
                    <button type="button" className="btn btn-info mx-3" onClick={() => onValidate(modalVisible.mahasiswaId, modalVisible.action)}>Ya</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setModalVisible({
                        ...modalVisible,
                        visible: !modalVisible.visible
                    })}>Batal</button>
                </div>
            </ModalPopup>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>
                <div className="text-end">
                    <Input
                        type="text"
                        placeholder="Cari..."
                        style={{ width: 300, display: "inline" }}
                        className="my-3"
                        onChangeValue={handleSearch}
                    />
                </div>
            </div>
            <DataTable
                pagination
                paginationServer
                columns={columns}
                data={mahasiswa?.length > 0 ? mahasiswa : []}
                progressPending={loading}
                paginationTotalRows={pagination.total}
                selectableRowsNoSelectAll={true} //I needed to remove the select all, because it would not work due to the mouse enter/leave workaround
                paginationDefaultPage={currentPage}
                paginationRowsPerPageOptions={[1, 5, 10, 15, 20]} //you can remove it later, just to have more pages
                paginationPerPage={rowsPerPage}
                onChangePage={handlePageChange}
                customStyles={TABLE_STYLE}
                onChangeRowsPerPage={handleRowsPerPageChange}
            />

            <Footer />
        </React.Fragment>
    );
}
