import React from "react";
import { BackButton, Footer, Input, ModalPopup, Navbar } from "../../../components";
import { useAuth } from "../../../util/ProtectedRoute";
import { useMemo } from "react";
import { BidangPenelitian as BidangPenelitianService } from "../../../services";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { TABLE_STYLE } from "../../../helper/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { debounce } from "../../../util/debounce";

export default function BidangPenelitian() {
    const bidang_penelitian_service = new BidangPenelitianService();
    const ctx = useAuth();
    const navigate = useNavigate();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    const [modalVisible, setModalVisible] = useState(false);
    const [dosenId, setDosenId] = useState('');
    const [keyword, setKeyword] = useState('');
    const [references, setReferences] = useState([]);
    const [pagination, setPagination] = useState({
        limit: 5,
        page: 1,
        total: 0,
    });
    // With references
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5); //change to 10 after you remove paginationRowsPerPageOptions
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getBidangPenelitian(pagination.page, pagination.limit);
    }, []);

    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
        getBidangPenelitian(1, pagination.limit, keyword);
    }, [keyword]);

    const getBidangPenelitian = async (page, limit, search = '') => {
        setLoading(true);
        try {
            const resp = await bidang_penelitian_service.list(page, limit, search);
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            setReferences([...resp.data.list]);
            setPagination({ ...pagination, total: Number(resp.data.total) });
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        getBidangPenelitian(page, rowsPerPage);
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        if (!references.length) return;
        getBidangPenelitian(1, newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const columns = useMemo(() => [
        {
            name: "No",
            width: "50px",
            selector: (row) => (
                <span className="col col-sm-1">
                    {pagination.page < 1 ? row.no : row.no}
                </span>
            ),
        },
        {
            name: "Nama",
            selector: (row) => <span>{row?.name}</span>,
        },
        {
            name: "Deskripsi",
            selector: (row) => <span>{row?.description}</span>,
        },
        {
            name: "Aksi",
            selector: (row) => (
                <div className="d-flex flex-row gap-3">
                    <span className="btn btn-dark text-white text-sm" onClick={() => navigate(`/management/bidang-penelitian/${row.id}?action=read`)}>Lihat</span>
                    <span className="btn btn-danger bg-purple text-white text-sm" onClick={() => navigate(`/management/bidang-penelitian/${row.id}?action=update`)}>Ubah</span>
                    <span className="btn btn-danger text-white text-sm" onClick={() => {
                        setModalVisible(!modalVisible);
                        setDosenId(row.id);
                    }}>Hapus</span>
                </div>
            ),

        }
    ]);

    const deleteDosen = async () => {
        try {
            await bidang_penelitian_service.delete(dosenId);
            toast.success('Data berhasil dihapus');
            getBidangPenelitian(pagination.page, pagination.limit);
            setModalVisible(!modalVisible);
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
    }
    return (
        <React.Fragment>
            <ModalPopup visible={modalVisible} onClose={() => setModalVisible(!modalVisible)} height={150} width={500}>
                <label>Kamu yakin ingin menghapus dosen ini?</label>
                <div className="text-end mt-5">
                    <button type="button" className="btn btn-info mx-3" onClick={() => deleteDosen()}>Ya</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setModalVisible(!modalVisible)}>Batal</button>
                </div>
            </ModalPopup>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>
                <div className="text-end">
                    <Input
                        type="text"
                        placeholder="Cari..."
                        style={{ width: 300, display: "inline" }}
                        className="my-3"
                        onChangeValue={debounce((val) => setKeyword(val), 500)}
                    />

                    <input
                        type="button"
                        className="btn bg-green"
                        value="Tambah Bidang Penelitian"
                        onClick={() => navigate(`/management/bidang-penelitian/add?action=add`)}
                    />
                </div>
            </div>
            <DataTable
                pagination
                paginationServer
                columns={columns}
                data={references?.length > 0 ? references : []}
                progressPending={loading}
                paginationTotalRows={pagination.total}
                selectableRowsNoSelectAll={true} //I needed to remove the select all, because it would not work due to the mouse enter/leave workaround
                paginationDefaultPage={currentPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20]} //you can remove it later, just to have more pages
                paginationPerPage={rowsPerPage}
                onChangePage={handlePageChange}
                customStyles={TABLE_STYLE}
                onChangeRowsPerPage={handleRowsPerPageChange}
            />

            <Footer />
        </React.Fragment>
    );
}
