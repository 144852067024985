import { getAuthorization } from "../headers";

export default class SBA {
    dashboard_stat = async () => {
        return new Promise(async (resolve, reject) => {
            
            const headers = await getAuthorization();
            fetch(`${process.env.REACT_APP_URL_SERVICE}/sba/dashboard`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }

    list = async (page, limit, keyword = '') => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            let query = (page && limit) ? `page=${page}&limit=${limit}&keyword=${keyword}` : 'all=true';             
            fetch(`${process.env.REACT_APP_URL_SERVICE}/user?${query}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
            })            
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }

    detail = async (id) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/user/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }   

    update = async (payload) => {        
        delete payload.nidn;

        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/user/${payload.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }   

    
    add = async (payload) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }

    
    delete = async (id) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/user/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }

    changeStatus = async (payload) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/user/${payload.id}?active=${payload.active}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }  
}