import React from "react";
import { BackButton, Footer, Input, Navbar, Select } from "../../../components";
import { useAuth } from "../../../util/ProtectedRoute";
import { useMemo } from "react";
import { Config as ConfigService } from "../../../services";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { CONFIG_TYPE, LIST_FILE, TABLE_STYLE } from "../../../helper/constants";
import { useNavigate } from "react-router-dom";
import { formatFile } from "../../../helper";
import { toast } from "react-toastify";
import "./File.css";

export default function File() {
    const config_service = new ConfigService();
    const ctx = useAuth();
    const navigate = useNavigate();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    const [dokumen, setDokumen] = useState([]);
    const [action, setAction] = useState("read");

    // With dokumen
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getConfig();
    }, []);

    const [limitType, setLimitType] = useState("kb");
    const [fileId, setFileId] = useState("");
    const [value, setValue] = useState(0);

    const getConfig = async () => {
        setLoading(true);
        try {
            const resp = await config_service.list(CONFIG_TYPE.file_limit);
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            setDokumen([...resp.data.list]);
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };

    const handleAction = async (data) => {
        const isMb = data.item.value >= (1 * 1024 * 1024) ? "mb" : "kb";

        if (action === "read") {
            setAction("update");
            setFileId(data.id);
            setLimitType(isMb)
            return;
        }

        // do update
        try {
            setLoading(true);
            const { name, datatype, description } = data.item;
            const pow = limitType === "kb" ? 1 : 2;
            const p = {
                value: value * (1024 ** pow),
                id: fileId,
                name,
                datatype,
                description,
            };

            await config_service.update(p);
            toast.success("Data berhasil diubah");
            await getConfig();
        } catch (e) {
            toast.error(JSON.stringify(e));
            setLoading(false);
            return;
        }

        setAction("read");
        setFileId("");
    };

    const generateFileLimitRow = (row) => {
        const isMb = row.item.value >= (1 * 1024 * 1024) ? "MB" : "KB";
        if (action === "update" && fileId === row.id) {
            return (
                <div className="d-flex flex-row justify-between">
                    <select onChange={(e) => setValue(Number(e.target.value))}>
                        {LIST_FILE[limitType].map((item, key) => (
                            <option
                                key={key}
                                value={item.value}
                                selected={
                                    item.value ===
                                    formatFile(row.item.value, isMb)
                                }
                            >
                                {item.value}
                            </option>
                        ))}
                    </select>
                    &nbsp;
                    <select onChange={(e) => setLimitType(e.target.value)}>
                        <option key={0} value="kb" selected={isMb === "KB"}>
                            KB
                        </option>
                        <option key={1} value="mb" selected={isMb === "MB"}>
                            MB
                        </option>
                    </select>
                </div>
            );
        }

        return <span>{formatFile(row?.item?.value, "KB")} KB</span>;
    };
    const columns = useMemo(() => [
        {
            name: "No",
            width: "50px",
            selector: (row) => <span className="col col-sm-1">{row.no}</span>,
        },
        {
            name: "Nama Limitasi",
            selector: (row) => <span>{row?.item?.name}</span>,
        },
        {
            name: "Ukuran File (KB)",
            selector: (row) => generateFileLimitRow(row),
        },
        {
            name: "Deskripsi",
            selector: (row) => <span>{row?.item?.description}</span>,
        },
        {
            name: "Aksi",
            selector: (row) => {
                const isSelectedRow = action === "update" && row.id === fileId;
                return (
                    <div className="d-flex flex-row justify-between">
                        <button
                            className={`btn ${
                                isSelectedRow ? "btn-info" : "bg-purple"
                            } text-white text-sm ml-3`}
                            disabled={action === "update" && row.id !== fileId}
                            onClick={() => handleAction(row)}
                        >
                            {isSelectedRow ? "Simpan" : "Ubah"}
                        </button>
                    </div>
                );
            },
        },
    ]);

    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>
                <div className="text-end">
                    <Input
                        type="text"
                        placeholder="Cari..."
                        style={{ width: 300, display: "inline" }}
                        className="my-3"
                    />
                </div>
            </div>

            <div className="px-2 text-sm legend">
                <strong>* File 1MB = 1024 KB</strong>
            </div>
            <DataTable
                columns={columns}
                data={dokumen?.length > 0 ? dokumen : []}
                progressPending={loading}
                customStyles={TABLE_STYLE}
            />

            <Footer />
        </React.Fragment>
    );
}
