import { useNavigate } from "react-router-dom";
import { Submission } from "../../services";
import { useAuth } from "../../util/ProtectedRoute";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { downloadReport } from "../../helper/utils";
import DataTable from "react-data-table-component";
import { MAX_FILE_SIZE, TABLE_STYLE } from "../../helper/constants";
import { toast } from "react-toastify";

export default function ListReport({ submissionId, fileReport }) {
    const submission_service = new Submission();
    const hiddenFileInput = useRef([]);
    const [reportPath, setReportPath] = useState(fileReport);
    const ctx = useAuth();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    // With dokumen
    const [loading, setLoading] = useState(false);
    const downloadFile = async (filePath) => {
        const file = await downloadReport(filePath);
        window.open(file);
    };

    const uploadDocument = async (e) => {
        const fileUploaded = e.target.files[0];
        if (fileUploaded.size > MAX_FILE_SIZE) {
            toast.error(
                "Maksimal file hanya 10MB, pastikan ukuran berkas tidak lebih dari 10MB"
            );
            return;
        }

        const fd = new FormData();
        fd.append("report", fileUploaded);

        try {
            setLoading(true);
            const uploadRes = await submission_service.uploadSubmissionReport(
                submissionId,
                fd
            );
            setReportPath(uploadRes.data.path);
            toast.success("Data berhasil diupload");
            setLoading(false);
        } catch (e) {
            console.error("error: ", e);
            toast.error(JSON.stringify(e));
            setLoading(false);
        }
    };
    const renderIcon = (status) => {
        if (status) {
            return (
                <div className="text-success">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-check-circle"
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                    &nbsp;
                    <span className="pl-3 badge bg-success"> Uploaded</span>
                </div>
            );
        }

        return <></>;
    };

    const columns = useMemo(() => [
        {
            name: "No",
            width: "50px",
            selector: (row) => (
                <span className="col col-sm-1">
                    {row.id}
                </span>
            ),
        },
        {
            name: "Nama Dokumen",
            selector: (row) => <span>{row?.name}</span>,
        },
        {
            name: "Aksi",
            textAlign: "center",
            selector: (row) => {
                const isMahasiswa = user.level === 'MHS';
                return (
                    <div className="d-flex flex-row justify-between">
                        {!isMahasiswa && (
                            <button
                                disabled={!isMahasiswa && !row.status}
                                className="btn btn-dark text-white text-sm"
                                onClick={async () => {
                                    await downloadFile(row.file_path);
                                }}
                            >
                                Unduh
                            </button>
                        )}
                        {isMahasiswa && (
                            <React.Fragment>
                                &nbsp;&nbsp;
                                <button
                                    className="btn text-white text-sm ml-3 btn-info"
                                    onClick={() => {
                                        if (!loading) {
                                            hiddenFileInput.current[row.id].click();
                                        }        
                                    }}
                                >
                                    Unggah
                                </button>
                                <input
                                    type="file"
                                    ref={el => (hiddenFileInput.current[row.id] = el)}
                                    onChange={e => uploadDocument(e)}
                                    style={{ display: "none" }}
                                    accept=".pdf"
                                />
                            </React.Fragment>
                        )}
                    </div>
                )
            },
        },
        {
            name: "Status",
            selector: (row) => (
                <div className="d-flex" style={{ width: 150 }}>
                    <div className="col-md-2" style={{ width: 200 }}>
                        {renderIcon(row.status)}
                    </div>
                </div>
            ),
        },
    ]);

    const data = [
        {
            id: 1,
            name: 'Laporan',
            status: Boolean(reportPath),
            file_path: reportPath
        }
    ];

    return (
        <DataTable            
            paginationServer
            columns={columns}
            data={data}
            progressPending={loading}
            selectableRowsNoSelectAll={true} //I needed to remove the select all, because it would not work due to the mouse enter/leave workaround
            paginationRowsPerPageOptions={[5, 10, 15, 20]} //you can remove it later, just to have more pages           
            customStyles={TABLE_STYLE}
        />
    );
}
