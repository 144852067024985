import React, { useState } from "react";
import { SUBMISSION_STATUS } from "../../../../helper/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CKEditor, Select } from "../../../../components";

export default function Verif({ id, submission_service }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);    

    const [status, setStatus] = useState('')
    const [reason, setReason] = useState('')

    const verif = async () => {
        if (!verifEnabledButton) {
            return;
        }
        try {

            const verifPayload = { status, reason };
            await submission_service.verif(id, verifPayload);
            toast.success("Data berhasil dikirim");
            navigate("/homepage");
        } catch (e) {
            console.log({ e });
            setLoading(false);
            navigate(-1);
        }
    };

     const verifEnabledButton = () => {
        const verifPayloadIsValid = status && reason;

        return verifPayloadIsValid;
    };
    // 
    return (
        <React.Fragment>
            <div className="border-2 border-top mt-4">
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-md-1">
                            <label className="fw-bold">Status</label>
                        </div>
                        <div className="col-md-11">
                            <Select
                                options={SUBMISSION_STATUS}
                                onChangeValue={(val) => {
                                    setStatus(val);
                                    setReason(SUBMISSION_STATUS.find(
                                        (item) => item.value === val
                                    ).text);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-2 border-top mt-4">
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-md-12 pb-3">
                            <label className="fw-bold">
                                Alasan untuk mahasiswa
                            </label>
                        </div>
                        <div className="col-md-12">
                            <CKEditor
                                onChangeValue={(value) => {
                                    setReason(value);
                                }}
                                value={reason}
                            >
                                <label className="text-secondary text-sm-start">
                                    *Anda dapat merubah contoh template text di
                                    atas dengan pesan yang Anda inginkan.
                                </label>
                            </CKEditor>
                        </div>
                        <button
                            type="button"
                            className="btn btn-info my-3 text-white fw-bold"
                            onClick={verif}
                            disabled={!verifEnabledButton()}
                        >
                            KIRIM
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
