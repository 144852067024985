import { BackButton, Input, Navbar, Select } from "../../../../components";
import React, { useEffect } from "react";
import { useAuth } from "../../../../util/ProtectedRoute";
import "./View.css";
import { useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SBA } from "../../../../services";
import { toast } from "react-toastify";
import { SBA_STATUS } from "../../../../helper/constants";

export default function View() {
    const { id } = useParams();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const action = queryParams.get("action");
    const isRead = action === "read";

    const sba_service = new SBA();
    const [loading, setLoading] = useState(true);
    const [passwordShown, setPasswordShown] = useState({
        password: false,
        confirm_password: false
    });

    const ctx = useAuth();
    const [payload, setPayload] = useState({
        level: "SBA (Sub Bagian Administrasi)",
    });
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    useEffect(() => {
        const getSBA = async () => {
            if (["update", "read"].includes(action)) {
                try {
                    const resp = await sba_service.detail(id);
                    if (!resp.data) {
                        throw new Error("Data not found");
                    }

                    const sba = resp.data;
                    setPayload({ ...sba });
                    setLoading(false);
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    // navigate(-1);
                }
            } else {
                setLoading(false);
            }
        };

        getSBA();
    }, []);

    const generateDefaultValue = (state) => {
        switch (state) {
            case "status":
                return SBA_STATUS?.length > 0
                    ? SBA_STATUS.find((item) => item.value === payload[state])
                    : null;
            default:
                return null;
        }
    };

    const RenderForm = (data) => {
        const { type, state, options } = data;

        switch (type) {
            case "text":
            case "email":
            case "password":
                return type === "password" ? (
                    <Input
                        required
                        className="my-3 input-group"
                        type={`${passwordShown[state] ? "text" : "password"}`}
                        placeholder="Password"
                        onChangeValue={(value) =>
                            setPayload({ ...payload, [state]: value })
                        }
                    >
                        <button
                            type="button"
                            className="input-group-text"
                            id="input-group-right-example"
                            onClick={() =>
                                setPasswordShown({
                                    ...passwordShown,
                                    [state]: !passwordShown[state]
                                })
                            }
                        >
                            {passwordShown ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-eye"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-eye-slash"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                </svg>
                            )}
                        </button>
                    </Input>
                ) : (
                    <Input
                        disabled={isRead || state === "level"}
                        required
                        type={type}
                        value={payload[state]}
                        onChangeValue={(value) =>
                            setPayload({ ...payload, [state]: value })
                        }
                    />
                );
            case "select":
                return (
                    <Select
                        defaultValue={generateDefaultValue(state)}
                        isDisabled={isRead}
                        required
                        options={options}
                        onChangeValue={(value) =>
                            setPayload({ ...payload, [state]: value })
                        }
                    />
                );
            default:
                return <>test</>;
        }
    };

    let columns = useMemo(() => {
        return [
            {
                label: "Username",
                value: "",
                type: "text",
                state: "username",
            },
            {
                label: "Email",
                value: "",
                type: "email",
                state: "email",
            },
        ];
    });

    if (["add", "read"].includes(action)) {
        if (action === "read") {
            columns = [
                ...columns,
                {
                    label: "Status",
                    value: "",
                    type: "select",
                    state: "status",
                    options: SBA_STATUS,
                },
            ];
        }

        if (action === "add") {
            columns = [
                ...columns,
                {
                    label: "Password",
                    value: "",
                    type: "password",
                    state: "password",
                },
                {
                    label: "Confirm Password",
                    value: "",
                    type: "password",
                    state: "confirm_password",
                },
            ];
        }

        columns = [
            ...columns,
            {
                label: "Level",
                value: "SBA (Sub Bagian Administrasi)",
                type: "text",
                state: "level",
            },
        ];
    }

    const generateTitle = () => {
        switch (action) {
            case "read":
                return "Detail";
            case "update":
                return "Ubah";
            default:
                return "Tambah";
        }
    };

    const onSubmit = async (e) => {
        if (isRead) {
            return;
        }

        if (!["update", "add"].includes(action)) {
            return;
        }

        if (action === "update") {
            await updateSBA();
        } else if (action === "add") {
            await addSBA();
        }
    };

    const addSBA = async () => {
        if (payload.password !== payload.confirm_password) {
            toast.error("Password tidak sama, harap periksa kembali");
            return;
        }
        try {
            const data = {
                username: payload.username,
                email: payload.email,
                password: payload.password,
                status: "ACTIVE",
            };

            await sba_service.add(data);
            toast.success("Data berhasil ditambahkan");
            navigate("/management/sba");
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
    };

    const updateSBA = async () => {
        try {
            const data = {
                id: payload.id,
                username: payload.username,
                email: payload.email,
            };

            await sba_service.update(data);
            toast.success("Data berhasil diubah");
            navigate("/management/sba");
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
    };
    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <span className=" col-md-6 rounded-1 fs-4 text-uppercase text-center bg-blue text-white">
                        Menu {generateTitle()} SBA
                    </span>
                </div>

                {!loading &&
                    columns.map((col, key) => (
                        <div className="row col-md-9 my-4" key={key}>
                            <div className="col-md-4 justify-content-end pt-2 label">
                                <span className="fw-bold">{col.label}</span>
                            </div>
                            <div className="col-md-8">{RenderForm(col)}</div>
                        </div>
                    ))}
                {!isRead && (
                    <div className="row col-md-9 my-4 ">
                        <button
                            className="col-md-8 offset-md-4 btn btn-info text-white w-full text-uppercase fw-bold"
                            onClick={onSubmit}
                        >
                            Simpan
                        </button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
