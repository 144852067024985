import React, { useRef } from 'react';
import { onKeyPress, randomId } from '../../../helper';
import './Input.css';
import { MAX_FILE_SIZE } from '../../../helper/constants';
import { toast } from 'react-toastify';

export default function Input(props) {
    let fileRef = useRef();
    const {accept, onKeyPress, label, type, onChangeValue, className =  'mb-3', children, placeholder, value, disabled = false, style } = props;
    
    const onChange = (e) => {
        if (type === 'file') {
            const file = e.target.files[0];
            if (file.size > MAX_FILE_SIZE) {
                toast.error('Maksimal file hanya 10MB, pastikan ukuran berkas tidak lebih dari 10MB');
                onChangeValue(null);
                fileRef.value = "";
                return
            }
            onChangeValue(e.target.files[0]);
            return
        }

        const value = type === 'number' ? Number(e.target.value) : e.target.value;
        onChangeValue(value);
    };
    return (
        <div className={`${className}`}>

            {label?.length > 0 && <label htmlFor={label} className="form-label font-weight-bold">{label}</label>}
            <input 
                autoComplete='off'
                name={randomId()}
                type={type} 
                className="form-control" 
                onChange={onChange} 
                id={randomId()}
                placeholder={placeholder || label}
                value={value}
                disabled={disabled}             
                style={style}
                onKeyPress={onKeyPress}
                {...(type === "file" 
                    ? { 
                        accept: accept || "image/jpeg,image/gif,image/png,application/pdf,image/x-eps", 
                        ref: ref => fileRef = ref
                    } 
                    : {})}
            />
            {children}
        </div>
    )
}