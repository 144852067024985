import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Submission } from "../../../../../services";
import { TABLE_STYLE } from "../../../../../helper/constants";
import { useAuth } from "../../../../../util/ProtectedRoute";
import ModalPopup from "../../../../ModalPopup";
import { formatDate } from "../../../../../helper/utils";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

export default function DaftarSidang() {
    const submission_service = new Submission();
    const navigate = useNavigate();
    const ctx = useAuth();
    const { user } = ctx;
    const [submissions, setSubmissions] = useState([]);
    const [pagination, setPagination] = useState({
        limit: 5,
        page: 1,
        total: 0,
    });
    // With references
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5); //change to 10 after you remove paginationRowsPerPageOptions
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [submission, setSubmission] = useState(null);
    const [status, setStatus] = useState("");

    useEffect(() => {
        getDaftarSidang();
    }, []);

    const getDaftarSidang = async (page, limit) => {
        setLoading(true);
        try {
            const resp = await submission_service.list(
                page,
                limit,
                "",
                "",
                user?.dosen_id
            );
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            setSubmissions([...resp.data.list]);
            setPagination({ ...pagination, total: Number(resp.data.total) });
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        getDaftarSidang(page, rowsPerPage);
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        if (!submissions.length) return;
        getDaftarSidang(1, newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const setData = (data, status) => {
        setSubmission({ ...data });
        setStatus(status);
        setShowModal(!showModal);
    };

    const onSubmit = async () => {
        setLoading(true);

        try {
            await submission_service.scheduleDosenPenguji(
                submission.id,
                status
            );
            toast.success("Data berhasil disubmit");
            setLoading(false);
            setShowModal(!showModal);
            getDaftarSidang(pagination.page, pagination.limit);
        } catch (e) {
            console.log({ e });
            toast.error(e.message);
            setLoading(false);
        }
    };

    const getStatus = (row) => {
        if (row?.dosen_uji1.id === user?.dosen_id) {
            return row.dosen_uji1.status;
        } else if (row?.dosen_uji2.id === user?.dosen_id) {
            return row.dosen_uji2.status;
        } else {
            return "";
        }
    };

    const enabledGradingButton = (tanggalSidang) => {
        var today = new Date(tanggalSidang);
        today.setHours(0, 0, 0, 0); // Mengatur jam ke 00:00:00

        // Mendapatkan tanggal besok
        var tomorrow = new Date(tanggalSidang);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0); // Mengatur jam ke 00:00:00

        // Mendapatkan tanggal yang ingin diperiksa
        var dateToCheck = new Date();
        dateToCheck.setHours(0, 0, 0, 0); // Mengatur jam ke 00:00:00

        // Memeriksa apakah tanggal sama dengan hari ini atau besok
        var isTodayOrTomorrow =
            dateToCheck.getTime() === today.getTime() ||
            dateToCheck.getTime() === tomorrow.getTime();

        return isTodayOrTomorrow;
    };

    const navigateToGradingSubmission = (id, tanggalSidang) => {
        if (!enabledGradingButton(tanggalSidang)) {
            return
        }

        navigate(`/submission/grading/${id}`);
    }
    const columns = useMemo(() => [
        {
            name: "",
            width: "50px",
            selector: (row) => (
                <h1 className="text-danger text-end" style={{ fontSize: 50}}>
                    {row?.perlu_persetujuan ? "\u2022" : ""}
                </h1>
            ),
        },
        {
            name: "No",
            width: "50px",
            selector: (row) => <span className="col col-sm-1">{row.no}</span>,
        },
        {
            name: "Tanggal Sidang",
            width: "130px",
            selector: (row) => (
                <span className="col col-sm-1">
                    {formatDate(row.tanggal_sidang, false)}
                </span>
            ),
        },
        {
            name: "Nama Mahasiswa",
            width: "100px",
            selector: (row) => <span>{row.mahasiswa?.nama}</span>,
        },
        {
            name: "NPM",
            width: "140px",
            selector: (row) => <span>{row.mahasiswa?.nim}</span>,
        },
        {
            name: "Status",
            width: "130px",
            selector: (row) => <span>{row.status}</span>,
        },
        {
            name: "Detail",
            width: "100px",
            selector: (row) => (
                <Link
                    to={`/pengajuan-seminar-skripsi/${row.id}`}
                    className="btn btn-dark btn-sm"
                >
                    Lihat
                </Link>
            ),
        },
        {
            name: "Persetujuan",
            width: "170px",
            selector: (row) =>
                row.perlu_persetujuan ? (
                    <div className="d-flex flex-row" style={{ width: 500 }}>
                        <button
                            type="button"
                            className="btn btn-success btn-sm"
                            onClick={() => setData(row, true)}
                            disabled={loading}
                        >
                            Setujui
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => setData(row, false)}
                            disabled={loading}
                        >
                            Tolak
                        </button>
                    </div>
                ) : (
                    <>{getStatus(row)}</>
                ),
        },
        {
            name: "Input Nilai",
            selector: (row) => (
                <button
                    className="bg-purple btn btn-sm"
                    disabled={!enabledGradingButton(row.tanggal_sidang)}
                    onClick={() => navigateToGradingSubmission(row.id, row.tanggal_sidang)}
                >
                    Input
                </button>
            ),
        },
    ]);

    return (
        <React.Fragment>
            <ModalPopup
                visible={showModal}
                onClose={() => setShowModal(!showModal)}
                height={200}
            >
                <div className="mt-5 content">
                    <h2>
                        Apakah anda yakin ingin{" "}
                        {status ? "menyetujui" : "menolak"}{" "}
                        <b>{submission?.mahasiswa?.nama}</b> pada sidang ini?
                    </h2>
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-info mx-3"
                            disabled={loading}
                            onClick={onSubmit}
                        >
                            Ya
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setShowModal(!showModal)}
                            disabled={loading}
                        >
                            Tidak
                        </button>
                    </div>
                </div>
            </ModalPopup>
            <DataTable
                pagination
                paginationServer
                columns={columns}
                data={submissions?.length > 0 ? submissions : []}
                progressPending={loading}
                paginationTotalRows={pagination.total}
                selectableRowsNoSelectAll={true} //I needed to remove the select all, because it would not work due to the mouse enter/leave workaround
                paginationDefaultPage={currentPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20]} //you can remove it later, just to have more pages
                paginationPerPage={rowsPerPage}
                onChangePage={handlePageChange}
                customStyles={TABLE_STYLE}
                onChangeRowsPerPage={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
}
