import React, { useEffect, useRef, useState } from "react";
import {
    BackButton,
    ChangePassword,
    DetailBiodata,
    Footer,
    Navbar,
    UpdateBiodata,
} from "../../components";
import { useAuth } from "../../util/ProtectedRoute";
import "./Biodata.css";
import { getImage } from "../../helper/utils";
import { Auth, Users } from "../../services";
import { useLocalStorage } from "../../util/useLocalStorage";
import avatar from "../../images/avatar.png";
import { RotatingLines } from "react-loader-spinner";
import { MAX_FILE_SIZE } from "../../helper/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Biodata() {
    const navigate = useNavigate();
    const hiddenFileInput = useRef(null);
    const ctx = useAuth();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }
    const users = new Users();
    const auth = new Auth();
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState("");
    
    const isMahasiswa = user.level === 'MHS';
    // eslint-disable-next-line no-unused-vars
    const [_, setUser] = useLocalStorage("user", null); 

    const [menu, setMenu] = useState(0);
    const [userData, setUserData] = useState(user);

    useEffect(() => {
        (async () => getUserImage())();

        setLoading(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getUserImage = async () => {
        const img = await getImage(user?.profile_picture);
        setImage(img);
    };
    const RenderContent = () => {
        switch (menu) {
            case 0:
                return (
                    isMahasiswa && <DetailBiodata user={userData} setUserData={setUserData} />
                );
            case 1:
                return (
                    isMahasiswa && <UpdateBiodata user={userData} setUserData={setUserData} />
                );
            case 2:
                return (
                    <ChangePassword user={userData} setUserData={setUserData} />
                );
            default:
                return null;
        }
    };

    const upload = async (e) => {
        const fileUploaded = e.target.files[0];
        if (fileUploaded.size > MAX_FILE_SIZE) {
            toast.error('Maksimal file hanya 10MB, pastikan ukuran berkas tidak lebih dari 10MB');
            return
        }

        const fd = new FormData();
        fd.append("avatar", fileUploaded);

        try {
            setLoading(true);
            await users.update_avatar(fd);

            const token = await localStorage.getItem("token");
            const resp = await auth.check_user_token(token);
            const img = await getImage(resp.data.profile_picture);

            setUser({ ...resp.data });
            setUserData({ ...resp.data });
            setImage(img);
            setLoading(false);
            navigate(0);
        } catch (e) {
            console.error("error: ", e);
            toast.error(e.message);
            setLoading(false);
        }
    };

    let LIST_MENU = [];
    if(isMahasiswa) {
        LIST_MENU = [
            {
                text: "Detail",
                state: 0,
            },
            {
                text: "Ubah Biodata",
                state: 1,
            }
        ]
    }

    LIST_MENU = [
        ...LIST_MENU,        
        {
            text: "Ubah Password",
            state: 2,
        },
    ];

    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={userData} />
            <div
                className={`d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {userData?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="row my-3 col-md-12">
                <BackButton />
                <div className="col-md-4 text-center">
                    <div className="d-flex justify-content-center align-items-center">
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="140"
                            height="140"
                            visible={loading}
                            className="img-thumbnail mt-5"
                        />
                        {!loading && (
                            <img
                                loading="lazy"
                                src={userData.profile_picture ? image : avatar}
                                className="rounded-circle img-thumbnail mt-5"
                                alt="profile"
                                style={{
                                    height: 160,
                                    width: 160,
                                }}
                            />
                        )}
                    </div>
                    <div className="upload">
                        <button
                            onClick={() => {
                                if (!loading) {
                                    hiddenFileInput.current.click();
                                }
                            }}
                            className="btn btn-warning mt-3 text-blue"
                            style={{ fontSize: 12 }}
                            disabled={loading}
                        >
                            Upload Foto
                        </button>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={upload}
                            style={{ display: "none" }}
                            accept="image/jpeg,image/jpg,image/png"
                        />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="name row mt-5">
                        <label
                            className="col-md-12 text-blue font-weight-bold text-capitalize"
                            style={{ fontSize: 24 }}
                        >
                            {userData?.fullname}
                        </label>
                        <label
                            className="col-md-12 py-3 font-weight-bold"
                            style={{ fontSize: 14 }}
                        >
                            {userData?.nim}
                        </label>
                    </div>
                    <div className="menu row">
                        <div className="col-md-12">
                            {LIST_MENU.map((item, i) => (
                                <button
                                    key={i}
                                    className={`btn ${
                                        menu === item.state
                                            ? "bg-blue text-white"
                                            : "bg-navbar text-blue "
                                    } mx-1 menu`}
                                    onClick={() => setMenu(item.state)}
                                    style={{ fontSize: 12 }}
                                >
                                    {item.text}
                                </button>
                            ))}
                        </div>
                        <div className="col-md-12 mt-3">
                            <RenderContent />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}
