import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Submission } from "../../../../../services";
import { TABLE_STYLE } from "../../../../../helper/constants";
import { useAuth } from "../../../../../util/ProtectedRoute";
import { useNavigate } from "react-router-dom";

export default function DaftarBimbingan() {
    const navigate = useNavigate();
    const submission = new Submission();
    const ctx = useAuth();
    const { user } = ctx;
    const [submissions, setSubmissions] = useState([]);
    const [pagination, setPagination] = useState({
        limit: 5,
        page: 1,
        total: 0,
    });
    // With references
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5); //change to 10 after you remove paginationRowsPerPageOptions
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getDaftarBimbingan();
    }, []);

    const getDaftarBimbingan = async (page, limit) => {
        setLoading(true);
        try {
            const resp = await submission.list(page, limit, '', user?.dosen_id);
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            setSubmissions([...resp.data.list]);
            setPagination({ ...pagination, total: Number(resp.data.total) });
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        getDaftarBimbingan(page, rowsPerPage);
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        if (!submissions.length) return; 
        getDaftarBimbingan(1, newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const enabledGradingButton = (tanggalSidang) => {
        var today = new Date(tanggalSidang);
        today.setHours(0, 0, 0, 0); // Mengatur jam ke 00:00:00

        // Mendapatkan tanggal besok
        var tomorrow = new Date(tanggalSidang);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0); // Mengatur jam ke 00:00:00

        // Mendapatkan tanggal yang ingin diperiksa
        var dateToCheck = new Date();
        dateToCheck.setHours(0, 0, 0, 0); // Mengatur jam ke 00:00:00

        // Memeriksa apakah tanggal sama dengan hari ini atau besok
        var isTodayOrTomorrow =
            dateToCheck.getTime() === today.getTime() ||
            dateToCheck.getTime() === tomorrow.getTime();

        return isTodayOrTomorrow;
    };
    
    const navigateToGradingSubmission = (id, tanggalSidang) => {
        if (!enabledGradingButton(tanggalSidang)) {
            return
        }

        navigate(`/submission/grading/${id}?isDosenPembimbing=true`);
    }
    const columns = useMemo(() => [
        {
            name: "No",
            width: "50px",
            selector: (row) => <span className="col col-sm-1">{pagination.page < 1 ? row.no  : row.no}</span>,
        },
        {
            name: "Nama Mahasiswa",
            selector: (row) => <span>{row.mahasiswa?.nama}</span>,
        },
        {
            name: "NPM",
            selector: (row) => <span>{row.mahasiswa?.nim}</span>,
        },
        {
            name: "Status",
            selector: (row) => <span>{row.status}</span>,
        },
        {
            name: "Tahun",
            selector: (row) => <span>{row.tanggal_sidang?.split('-')[0]}</span>,
        },
        {
            name: "Input Nilai",
            selector: (row) => (
                <button
                    className="bg-purple btn btn-sm"
                    disabled={!enabledGradingButton(row.tanggal_sidang)}
                    onClick={() => navigateToGradingSubmission(row.id, row.tanggal_sidang)}
                >
                    Input
                </button>
            ),
        },
    ]);

    return (
        <DataTable
            pagination
            paginationServer
            columns={columns}
            data={submissions?.length > 0 ? submissions : []}
            progressPending={loading}
            paginationTotalRows={pagination.total}
            selectableRowsNoSelectAll={true} //I needed to remove the select all, because it would not work due to the mouse enter/leave workaround
            paginationDefaultPage={currentPage}
            paginationRowsPerPageOptions={[5, 10, 15, 20]} //you can remove it later, just to have more pages
            paginationPerPage={rowsPerPage}
            onChangePage={handlePageChange}
            customStyles={TABLE_STYLE}
            onChangeRowsPerPage={handleRowsPerPageChange}
        />
    );
}
