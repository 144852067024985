import React, { useRef, useState } from "react";
import { Alert, Footer, ModalPopup, Navbar, PrivacyPolicy } from "../../components";
import { Auth } from "../../services";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";


export default function Register() {
    const auth = new Auth();
    const [modalVisible, setModalVisible] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [alert, setAlert] = useState({
        type: '',
        message: '',
        showAlert: false,        
    });
    
    const [payload, setPayload] = useState({
        email: ''
    });

    const captchaRef = useRef(null);
    const captchaValidate = (value) => {
        if (value) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    const onSubmit = async () => {
        if (disabled) { return; }
        setDisabled(true);
        try {
            await auth.register(payload);

            setAlert({
                message:
                    <span className="font-weight-bold" style={{ fontSize: 11 }}>
                        CEK EMAIL!!! <br />
                        Silahkan cek email Anda, untuk melanjutkan verifikasi akun ChemOns.
                    </span>,
                type: 'primary',
                showAlert: true
            });

            setDisabled(false);
        } catch (e) {
            setAlert({ message: e.message, type: 'danger', showAlert: true });
            setDisabled(false);
        }
    };

    const onClose = (visible) => {
        setModalVisible(visible);
    };

    const { type, message, showAlert } = alert;
    return (
        <React.Fragment>
            <ModalPopup visible={modalVisible} onClose={onClose}>
                <PrivacyPolicy/>
            </ModalPopup>
            <Navbar isLandingPage={false} />
            <div className="d-flex align-items-center justify-content-center" style={{ height: '75vh' }}>
                <div className="col-md-4">
                    <div className="d-flex align-items-center justify-content-center col-md-12 bg-blue text-white text-uppercase" style={{ height: 43, fontSize: 12 }}>
                        <label className="">REGISTER AKUN</label>
                    </div>
                    <Alert type={type} message={message} showAlert={showAlert} />
                    <div className="form my-4">
                        <form>
                            <input
                                className="form-control form-control"
                                type="text"
                                placeholder="Email"
                                aria-label="Email"
                                onChange={e => setPayload({ email: e.target.value })}
                            />
                            <label className="pt-1" style={{ fontSize: 11 }}>
                                Pastikan penulisan alamat email anda sudah benar dan email Anda aktif. ChemOns akan mengirimkan link verifikasi ke email.
                            </label>
                        </form>
                    </div>
                    <div className="captcha my-2">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            ref={captchaRef}
                            onChange={captchaValidate}
                        />
                    </div>
                    <div className="text-center">
                        <label style={{ fontSize: 11 }}>
                            Dengan mendaftar, Anda telah menyetujui
                            <span className="text-decoration-none link-primary pe-auto" onClick={() => setModalVisible(true)}> Kebijakan Privasi.</span>
                        </label>
                    </div>
                    <div
                        className={`submit-login d-flex align-items-center justify-content-center col-md-12 btn bg-warning ${disabled ? 'disabled' : ''}`}
                        onClick={onSubmit}
                    >
                        <button
                            type="button"
                            className="btn bg-warning text-blue"
                        >
                            DAFTAR
                        </button>
                    </div>

                    <div className="text-center">
                        <label style={{ fontSize: 11 }}>
                            Sudah memiliki akun ChemOns?
                            <Link to="/login" className="text-decoration-none link-primary"> Masuk Disini</Link>
                        </label>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}