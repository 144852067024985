import { getGenderString } from "../../../helper";

export function DetailBiodata(props) {
    const { user } = props;
    const data = [
        {
            label: "Nama Lengkap:",
            value: <span className="text-capitalize">{user?.fullname}</span>,
        },
        {
            label: "NPM:",
            value: user?.nim,
        },
        {
            label: "Program Studi:",
            value: user?.study_program?.name,
        },
        {
            label: "Tahun Angkatan:",
            value: user?.batch_year,
        },
        {
            label: "Tempat Lahir:",
            value: user?.birth_place,
        },
        {
            label: "Tanggal Lahir:",
            value: user?.birth_date,
        },
        {
            label: "Jenis Kelamin:",
            value: getGenderString(user?.gender),
        },
        {
            label: "Alamat:",
            value: <span className="text-capitalize">{user?.address}</span>,
        },
        {
            label: "Telepon/Hp:",
            value: user?.phone,
        },
        {
            label: "Alamat Email:",
            value: user?.email,
        },
    ];

    return (
        <div className="container">
            {data.map((item, i) => (
                <div className="row my-3" key={i}>
                    <div className="col-md-4 font-weight-bold">
                        {item.label}
                    </div>
                    <div className="col-md-6">{item.value}</div>
                </div>
            ))}
        </div>
    );
}
