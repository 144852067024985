import { getAuthorization } from "../headers";

export default class Mahasiswa {
    list = async (page, limit, nidn = '', isPending = false, keyword = '') => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            let query = (page && limit) ? `page=${page}&limit=${limit}&pending=${isPending}&keyword=${keyword}` : 'all=true';
            if (nidn) {
                query = `${query}&keyword=${nidn}`;
            }
            fetch(`${process.env.REACT_APP_URL_SERVICE}/mahasiswa?${query}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
            })
                .then(res => {
                    res.json()
                        .then(data => {
                            if (data.status && data.status === 200) {
                                return resolve(data);
                            }
                            reject(data);
                        });
                })
                .catch(err => {
                    reject(err);
                });
        })
    }

    detail = async (id) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/mahasiswa/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
            })
                .then(res => {
                    res.json()
                        .then(data => {
                            if (data.status && data.status === 200) {
                                return resolve(data);
                            }
                            reject(data);
                        });
                })
                .catch(err => {
                    reject(err);
                });
        })
    }

    update = async (payload) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/mahasiswa/${payload.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
                body: JSON.stringify(payload)
            })
                .then(res => {
                    res.json()
                        .then(data => {
                            if (data.status && data.status === 200) {
                                return resolve(data);
                            }
                            reject(data);
                        });
                })
                .catch(err => {
                    reject(err);
                });
        })
    }


    add = async (payload) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/mahasiswa`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
                body: JSON.stringify(payload)
            })
                .then(res => {
                    res.json()
                        .then(data => {
                            if (data.status && data.status === 200) {
                                return resolve(data);
                            }
                            reject(data);
                        });
                })
                .catch(err => {
                    reject(err);
                });
        })
    }


    delete = async (id) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/mahasiswa/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                }
            })
                .then(res => {
                    res.json()
                        .then(data => {
                            if (data.status && data.status === 200) {
                                return resolve(data);
                            }
                            reject(data);
                        });
                })
                .catch(err => {
                    reject(err);
                });
        })
    }

    validate = async (id, action) => {
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/mahasiswa/${action}/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                }
            })
                .then(res => {
                    res.json()
                        .then(data => {
                            if (data.status && data.status === 200) {
                                return resolve(data);
                            }
                            reject(data);
                        });
                })
                .catch(err => {
                    reject(err);
                });
        })
    }
}