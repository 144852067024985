import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getAuthorization } from "../../../services/headers";

export default function CKEditorForm(props) {
    const API_URL = "https://chemons.fumui.my.id/api/v1";

    const onChange = (value) => {
        onChangeValue(value);
    };

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then(async (file) => {
                        body.append("file", file);
                        const authorization = await getAuthorization();
                        console.log({ authorization });
                        fetch(`${API_URL}/public`, {
                            method: "post",
                            body: body,
                            headers: {
                                ...authorization,
                            },
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                resolve({
                                    default: `${API_URL}/${res.data.path}`,
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            },
        };
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const {
        label,
        onChangeValue,
        className = "mb-3",
        children,
        value,
        withImage,
    } = props;

    let toolbar = [
        "bold",
        "italic",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "link",
    ];

    if (withImage) {
        toolbar = [...toolbar, 'uploadImage']
    }

    return (
        <div className={`${className}`}>
            {label?.length > 0 && (
                <label htmlFor={label} className="form-label font-weight-bold">
                    {label}
                </label>
            )}
            <CKEditor
                editor={ClassicEditor}
                config={{
                    toolbar: toolbar,
                    extraPlugins: [uploadPlugin],
                }}
                data={value || ""}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onChange(data);
                }}
            />
            {children}
        </div>
    );
}
