import { getAuthorization } from "../services/headers";
import { GENDER_LIST, RESEARCH_SPACES } from "./constants";
import { useState, useEffect } from 'react';

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        // Set debouncedValue to the current value after the specified delay
        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup the timer when the component unmounts or when value changes
        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
}

const onKeyPress = (event) => {
    if (!/[0-9.]|\bTab\b/.test(event.key)) {
        event.preventDefault();
    }
};

const isEmptyObject = (obj) => {
    for (var key in obj) {
        if (obj[key] === null && obj[key] === "") {
            return true;
        }
    }
    return false;
};

const checkPassword = (str) => {
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
};

const randomId = () => {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = 10;
    let counter = 0;
    while (counter < 10) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
    }

    return result;
};

const getGenderString = (gender) => {
    const genderString = GENDER_LIST.find(
        (item) => item.value === gender
    ).label;
    return genderString;
};

const getImage = async (filename) => {
    if (!filename) return null;
    const headers = await getAuthorization();
    const r = await fetch(`${process.env.REACT_APP_URL_SERVICE}/${filename}`, {
        method: "GET",
        headers: { ...headers, responseType: "arraybuffer" },
    });
    const blob = await r.blob();

    const base64 = async () => {
        return new Promise((onSuccess, onError) => {
            try {
                const reader = new FileReader();
                reader.onload = function () {
                    onSuccess(this.result);
                };
                reader.readAsDataURL(blob);
            } catch (e) {
                onError(e);
            }
        });
    };

    const b64 = await base64();
    return b64;
};

const formatFile = (value, ext) => {
    switch (ext) {
        case 'KB':
            return value / 1024
        case 'MB':
            return value / 1024 / 1024
        default:
            return value;
    }
}


const downloadReport = async (filename) => {
    if (!filename) return null;
    const headers = await getAuthorization();
    const r = await fetch(`${process.env.REACT_APP_URL_SERVICE}/${filename}`, {
        method: "GET",
        headers: { ...headers, responseType: "arraybuffer" },
    });
    const blob = await r.blob();
    const file = window.URL.createObjectURL(blob);
    return file;
    // window.location.assign(file);
    // window.open(file, 'download')
}
const formatDate = (date, withTime = true) => {
    let opt = {
        day: "2-digit",
        month: "short",
        year: "numeric",
    }

    if (withTime) {
        opt = { ...opt, hour: "2-digit", minute: "2-digit" };
    }


    let dateFormat = new Intl.DateTimeFormat("id-ID", opt);
    return dateFormat.format(new Date(date));
};

const normalizedNavbarUsername = (fullname) => {
    let username = fullname.split(" ");
    return `${username[0]} ${username[1]}`;
};

const generateResearchSpaces = (researchSpace) => {
    return RESEARCH_SPACES.find(rs => rs.value === researchSpace)?.label;
}

export {
    onKeyPress,
    isEmptyObject,
    checkPassword,
    randomId,
    getGenderString,
    getImage,
    formatDate,
    normalizedNavbarUsername,
    generateResearchSpaces,
    downloadReport,
    formatFile,
    useDebounce
};
