import React, { useEffect, useState } from "react";
import { BackButton, Footer, ModalPopup, Navbar } from "../../../components";
import { useAuth } from "../../../util/ProtectedRoute";
import { Reference, Submission } from "../../../services";
import { useNavigate, useParams } from "react-router-dom";
import { downloadReport, formatDate, getImage } from "../../../helper/utils";
import { Grading, Schedule, Verif } from "./components";
import ListDocument from "../../DetailSubmission/ListDocument";
import ListReport from "../../DetailSubmission/ListReport";

export default function DetailSubmissionSBA() {
    const submission_service = new Submission();
    const reference_service = new Reference();

    const { id } = useParams();
    const navigate = useNavigate();
    const ctx = useAuth();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [submission, setSubmission] = useState(null);
    const [dosenList, setDosenList] = useState(null);
    const [irsImage, setIrsImage] = useState(null);
    const [type, setType] = useState("");
    const [reportUrl, setReportUrl] = useState('');

    useEffect(() => {
        (async () => getSubmission())();
        (async () => getDosenList())();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getSubmission = async () => {
        try {
            const resp = await submission_service.detail(id);
            const irsFile = await getImage(resp.data.irs_file);
            const reportFile = resp.data.laporan_file?.length > 0 ? await downloadReport(resp.data.laporan_file) : '';

            setSubmission({ ...resp.data });
            setIrsImage(irsFile);
            setReportUrl(reportFile);
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
            navigate(-1);
        }
    };

    const getDosenList = async () => {
        try {
            const resp = await reference_service.dosen_list(1, 9999);
            const data = resp.data.list.map((item) => ({
                value: item.id,
                label: item.nama,
                nidn: item.nidn,
            }));

            setDosenList([...data]);
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
            navigate(-1);
        }
    }

    const RenderContent = () => {
        switch (type) {
            case "VERIF":
                return <Verif id={id} submission_service={submission_service} />;
            case "JADWAL_SIDANG":
                return <Schedule dosenList={dosenList} id={id} submission={submission} />
            case "NILAI_SIDANG":
                return <Grading id={id} />
            case "LAPORAN":
                return <ListReport id={id} fileReport={submission?.laporan_file} />
            case "DOKUMEN":
                return <ListDocument submissionId={id} />
            default:
                return <></>
        }
    };

    const showDocument = () => {
        setShowModal(!showModal);
    };

    const disabledReportButton = () => {
        return ['PENDING', 'DISETUJUI', 'DITOLAK', 'REVISI'].includes(submission?.status)
    }

    const catatan_dosen_p1 = submission?.catatan_penilaian?.find(x => x.id_dosen == submission?.dosen_p1?.id)?.catatan;
    const catatan_dosen_p2 = submission?.catatan_penilaian?.find(x => x.id_dosen == submission?.dosen_p2?.id)?.catatan;
    const catatan_dosen_uji1 = submission?.catatan_penilaian?.find(x => x.id_dosen == submission?.dosen_uji1?.id)?.catatan;
    const catatan_dosen_uji2 = submission?.catatan_penilaian?.find(x => x.id_dosen == submission?.dosen_uji2?.id)?.catatan;
    const data = {
        "Nama Mahasiswa": submission?.mahasiswa?.nama,
        NPM: submission?.mahasiswa?.nim,
        "Program Studi": submission?.mahasiswa?.jurusan?.nama,
        "Tanggal Pengajuan":
            submission?.created_at ? formatDate(submission?.created_at) : '',
        "Jenis Pengajuan": submission?.tipe,
        "IPK Terakhir": submission?.ipk,
        "Jumlah SKS Lulus": submission?.sks_lulus,
        "Bidang Penelitian": submission?.bidang_penelitian?.name,
        "Topik Yang Diajukan": submission?.topik,
        "Deskripsi Rencana Penelitian": (
            <div dangerouslySetInnerHTML={{ __html: submission?.deskripsi }} />
        ),
        "Judul Dalam Bahasa Indonesia": submission?.judul_id,
        "Judul Dalam Bahasa Inggris": submission?.judul_en,
        "Skema Penelitian": submission?.skema_penelitian,
        "Abstract Dalam Bahasa Indonesia": (
            <div dangerouslySetInnerHTML={{ __html: submission?.abstrak_id }} />
        ),
        "Abstract Dalam Bahasa Inggris": (
            <div dangerouslySetInnerHTML={{ __html: submission?.abstrak_en }} />
        ),
        "Dosen Ketua Grup Riset": submission?.dosen_ketua?.nama,
        "Pengambilan Ke": submission?.pengambilan_ke,
        "Dosen Pembimbing Sebelumnya":
            submission?.dosen_pembimbing_sebelumnya?.nama,
        "Alasan Mengulang": submission?.alasan_mengulang,
        'Dosen Pembimbing 1': submission?.dosen_p1?.nama,
        'Dosen Pembimbing 2': submission?.dosen_p2?.nama,
        'Dosen Penguji 1': submission?.dosen_uji1?.nama,
        'Dosen Penguji 2': submission?.dosen_uji2?.nama,
        "Jadwal Sidang":
            submission?.tanggal_sidang &&
            `${formatDate(new Date(submission?.tanggal_sidang).toISOString(), true)} WIB`,
        "Ruang Sidang": submission?.ruang,
        Status: submission?.status,
        "Catatan Dosen Pembimbing 1": catatan_dosen_p1 ? <div dangerouslySetInnerHTML={{ __html: catatan_dosen_p1}}/> : "-",
        "Catatan Dosen Pembimbing 2": catatan_dosen_p2 ? <div dangerouslySetInnerHTML={{ __html: catatan_dosen_p2}}/> : "-",
        "Catatan Dosen Penguji 1": catatan_dosen_uji1 ? <div dangerouslySetInnerHTML={{ __html: catatan_dosen_uji1}}/> : "-",
        "Catatan Dosen Penguji 2": catatan_dosen_uji2 ? <div dangerouslySetInnerHTML={{ __html: catatan_dosen_uji2}}/> : "-",
        "Bukti Pengisian IRS Siak-NG": (
            <>
                <div className="btn btn-info text-white" onClick={showDocument}>
                    Lihat
                </div>
            </>
        ),
    };

    const buttonsHeader = [
        {
            label: "Verifikasi",
            md: "col-md-1",
            type: "VERIF",
        },
        {
            label: "Jadwal Sidang",
            md: "col-md-2",
            type: "JADWAL_SIDANG",
        },
        {
            label: "Dokumen",
            md: "col-md-2",
            type: "DOKUMEN",
        },
        {
            label: "Laporan",
            md: "col-md-2",
            type: "LAPORAN",
        },
        {
            label: "Nilai Sidang",
            md: "col-md-2",
            type: "NILAI_SIDANG",
        },
    ];

    const disabledButtonHeader = (menu) => {
        switch (menu) {
            case 'JADWAL_SIDANG':
                const disabledButton = submission?.status === 'TERJADWAL' && submission?.dosen_uji1?.status === 'DISETUJUI' && submission?.dosen_uji2?.status === 'DISETUJUI';
                return ['PENDING', 'DITOLAK', 'REVISI', 'LULUS', 'TIDAK_LULUS'].includes(submission?.status) || disabledButton;
            case 'DOKUMEN':
                return disabledReportButton();
            case 'NILAI_SIDANG':
                return !(['TERJADWAL', 'LULUS'].includes(submission?.status));
            case 'LAPORAN':
                return !(submission?.status === 'LULUS')
        }

        return false
    }
    return (
        <React.Fragment>
            <ModalPopup
                visible={showModal}
                onClose={() => setShowModal(!showDocument)}
                height={600}
            >
                <div className="mt-5 content">
                    <img
                        src={irsImage}
                        className="img img-thumbnail"
                        alt="irs_file"
                    />
                </div>
            </ModalPopup>

            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>{submission?.judul_id}</label>
            </div>
            <BackButton />
            <div className="d-flex justify-content-around my-3 flex-wrap">
                {buttonsHeader.map((button, key) => (
                    <button
                        disabled={disabledButtonHeader(button.type)}
                        className={`btn btn-info text-white fw-bold ${button.md}`}
                        key={key}
                        onClick={() => setType(button.type)}
                    >
                        {button.label}
                    </button>
                ))}
            </div>
            {RenderContent()}
            {!loading &&
                submission &&
                type === "" &&
                Object.entries(data).map(([key, value]) => (
                    <div className="border-2 border-top mt-4" key={key}>
                        <div className="container pt-4">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="fw-bold">{key}</label>
                                </div>
                                <div className="col-md-8">{value}</div>
                            </div>
                        </div>
                    </div>
                ))}
            <Footer />
        </React.Fragment>
    );
}
