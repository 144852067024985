import React from "react";
import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
    const captions = [
        {
            sub_title: 'Informasi yang Kami Kumpulkan',
            text: 'Kami dapat mengumpulkan informasi pribadi seperti nama, alamat email, nomor telepon, dan informasi pembayaran ketika pengguna mengunjungi situs web kami, mendaftar akun, memesan produk atau layanan, atau berinteraksi dengan fitur situs web kami. Kami juga dapat mengumpulkan informasi non-pribadi seperti jenis browser, alamat IP, halaman yang dikunjungi, dan waktu akses situs web.'
        },
        {
            sub_title: 'Penggunaan Informasi',
            text: 'Informasi pribadi yang kami kumpulkan dapat digunakan untuk mengelola akun pengguna, memproses pembayaran, mengirim informasi tentang produk atau layanan kami, mengirim informasi pemasaran atau promosi, dan memperbaiki pengalaman pengguna pada situs web kami. Kami tidak akan menjual atau menyewakan informasi pribadi pengguna kepada pihak lain.'
        },
        {
            sub_title: 'Perlindungan Informasi',
            text: 'Kami mempertahankan langkah-langkah keamanan teknologi dan organisasi untuk melindungi informasi pribadi pengguna dari akses, penggunaan, atau pengungkapan yang tidak sah. Kami juga membatasi akses ke informasi pribadi pengguna hanya untuk karyawan yang membutuhkan informasi tersebut untuk menjalankan tugas-tugas tertentu.'
        },
        {
            sub_title: 'Cookie dan Teknologi Pelacakan',
            text: 'Situs web ChemOns menggunakan cookie dan teknologi pelacakan lainnya untuk memahami bagaimana pengguna berinteraksi dengan situs web kami dan untuk mempersonalisasi pengalaman pengguna. Cookie juga digunakan untuk memudahkan proses login, menyimpan preferensi pengguna, dan menyediakan informasi tentang iklan yang relevan dengan pengguna. Pengguna dapat memilih untuk menolak cookie melalui pengaturan browser mereka, tetapi ini dapat membatasi akses pengguna ke beberapa fitur situs web kami.'
        },
        {
            sub_title: 'Perubahan Kebijakan Privasi',
            text: 'Kami dapat mengubah kebijakan privasi ini dari waktu ke waktu dengan memberikan pemberitahuan kepada pengguna melalui situs web atau melalui kontak yang tersedia. Pengguna disarankan untuk memeriksa kebijakan privasi secara berkala untuk memastikan pemahaman yang tepat tentang informasi yang kami kumpulkan dan cara penggunaannya.'
        },
        {
            sub_title: 'Kontak',
            text: 'Jika pengguna memiliki pertanyaan atau kekhawatiran tentang kebijakan privasi kami atau penggunaan informasi pribadi mereka, silakan hubungi kami melalui informasi kontak yang tersedia di situs web ChemOns.'
        },

    ]
    return (
        <div className="overflow-scroll content">
            <div className="title text-center font-weight-bold py-2" style={{ fontSize: 12 }}>
                <span>Kebijakan Privasi ChemOns</span>
            </div>
            <div className="container" style={{ fontSize: 12 }}>
                <label className="py-4">
                    ChemOns menghargai privasi pengguna dan berkomitmen untuk melindungi informasi pribadi yang dikumpulkan dari pengguna saat mengakses situs web ChemOns. Kebijakan privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan melindungi informasi pribadi pengguna.
                </label>
                {captions.map((item, key) => (
                    <div className="point py-2" key={key}>
                        <label className="font-weight-bold">{item.sub_title}</label>
                        <label>{item.text}</label>
                    </div>                
                ))}
            </div>
        </div>
    )
}