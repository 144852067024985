import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Footer, Input, Navbar } from "../../components";
import { Auth } from "../../services";
import "./ForgotPassword.css";

export default function ForgotPassword() {
  const auth = new Auth();
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    email: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(
    <Alert
      type="primary"
      message={
        <span className="font-weight-bold" style={{ fontSize: 11 }}>
          CEK EMAIL!! <br />
          Kami sudah mengirim surel yang berisi tautan
        </span>
      }
      showAlert={true}
    />
  );
  const captchaRef = useRef(null);
  const captchaValidate = (value) => {
    if (value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const enabledButton = () => {
    const payloadIsValid = Object.values(payload).every(
      (v) => v !== "" && v !== null
    );
    return payloadIsValid && !disabled;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      // TODO Hit API...
      await auth.reset_password(payload);
      setAlertMessage(
        <Alert
          type="primary"
          message={
            <span className="font-weight-bold" style={{ fontSize: 11 }}>
              CEK EMAIL!! <br />
              Kami sudah mengirim surel yang berisi tautan
            </span>
          }
          showAlert={true}
        />
      );
    } catch (e) {
      console.error({ e });
      setAlertMessage(
        <Alert
          type="danger"
          message={
            <span className="font-weight-bold" style={{ fontSize: 11 }}>
              Email tidak tersedia <br />
              Masukkan email yang sudah terdaftar
            </span>
          }
          showAlert={true}
        />
      );
      toast.error(`${e.message}`);
    };

    
    setShowAlert(true);
  };

  return (
    <React.Fragment>
      <Navbar isLandingPage={true} />
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "75vh" }}
      >
        <div className="col-md-4">
          <div
            className="d-flex align-items-center justify-content-center col-md-12 bg-blue text-white text-uppercase"
            style={{ height: 43, fontSize: 12 }}
          >
            <label className="">Atur ulang kata sandi</label>
          </div>
          {showAlert && alertMessage}
          <form onSubmit={onSubmit}>
            <div className="form mt-3">
              <Input
                required
                type="email"
                placeholder="Email"
                onChangeValue={(value) => setPayload({ email: value })}
              >
                <label style={{ fontSize: 10 }}>
                  Masukkan email Anda yang digunakan untuk mendaftar
                </label>
              </Input>
            </div>

            <div className="captcha my-2">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                onChange={captchaValidate}
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <button
                  type="button"
                  onClick={() => navigate("/login")}
                  className="btn text-white font-weight-bold submit-login d-flex align-items-center justify-content-center col-md-12 btn btn-secondary"
                >
                  BATAL
                </button>
              </div>
              <div className="col-md-6">
                <button
                  type="submit"
                  className="btn text-white font-weight-bold submit-login d-flex align-items-center justify-content-center col-md-12 btn bg-bluesky"
                  disabled={!enabledButton()}
                >
                  KIRIM
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
