import React, { useMemo } from "react";
import {
    Footer,
    Navbar,
} from "../../components";
import { useState } from "react";
import { useEffect } from "react";
import { Submission as SubmissionService } from "../../services";
import DataTable from "react-data-table-component";
import { TABLE_STYLE } from "../../helper/constants";
import { formatDate } from "../../helper/utils";

export default function ScheduleList() {
    


    const submissions = new SubmissionService();
    const [user, setUser] = useState();
    const [isLogin, setIsLogin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [schedules, setSchedules] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5); //change to 10 after you remove paginationRowsPerPageOptions
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState({
        limit: 5,
        page: 1,
        total: 0,
    });
    
    
    useEffect(() => {
        const userStr = localStorage.getItem('user');
        if (userStr) {
            setUser(JSON.parse(userStr));
            setIsLogin(true);
        }
        (async () => getScheduleList(pagination.page, pagination.limit))();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getScheduleList = async (page, limit) => {
        try {          
            const resp = await submissions.schedule_list(page, limit);
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            setSchedules([...resp.data.list]);
            setPagination({ ...pagination, total: Number(resp.data.total) });
            setLoading(false);
                
        } catch (e) {
            console.log({ e });            
            setLoading(false)
        }
    };

    const handlePageChange = (page) => {
        getScheduleList(page, rowsPerPage);
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        if (!schedules.length) return;
        getScheduleList(1, newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const columns = useMemo(() => [
        {
            name: "No",
            width: "50px",
            selector: (row) => (
                <span className="col col-sm-1">
                    {pagination.page < 1 ? row.no : row.no}
                </span>
            ),
        },
        {
            name: "Nama Mahasiswa",
            selector: (row) => <span>{row?.mahasiswa?.nama}</span>,
        },
        {
            name: "NPM",
            selector: (row) => <span>{row?.mahasiswa?.nim}</span>,
        },
        {
            name: "Program Studi",
            selector: (row) => <span>{row?.mahasiswa?.jurusan}</span>,
        },
        {
            name: "Jenis",
            selector: (row) => <span>{row?.tipe}</span>,
        },        
        {
            name: "Tanggal",
            selector: (row) => <span>{formatDate(row?.tanggal_sidang, true)}</span>,
        },                
        {
            name: "Ruang",
            selector: (row) => <span>{row?.ruang}</span>,
        },                
        {
            name: "Dosen Penguji 1",
            selector: (row) => <span>{row?.dosen_uji1?.nama}</span>,
        },                
        {
            name: "Dosen Penguji 2",
            selector: (row) => <span>{row?.dosen_uji2?.nama}</span>,
        },                
    ]);
    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={isLogin} user={user}  />
            <div className={`d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}>
                <label>Selamat Datang Di Chemons Fakultas Teknik Universitas Indonesia</label>
            </div>
            <DataTable
                pagination
                paginationServer
                columns={columns}
                data={schedules?.length > 0 ? schedules : []}
                progressPending={loading}
                paginationTotalRows={pagination.total}
                selectableRowsNoSelectAll={true} //I needed to remove the select all, because it would not work due to the mouse enter/leave workaround
                paginationDefaultPage={currentPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20]} //you can remove it later, just to have more pages
                paginationPerPage={rowsPerPage}
                onChangePage={handlePageChange}
                customStyles={TABLE_STYLE}
                onChangeRowsPerPage={handleRowsPerPageChange}
            />        
            <Footer />
        </React.Fragment>
    );
}
