import { useNavigate } from "react-router-dom";
import { Submission } from "../../services";
import { useAuth } from "../../util/ProtectedRoute";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { downloadReport } from "../../helper/utils";
import DataTable from "react-data-table-component";
import { MAX_FILE_SIZE, TABLE_STYLE } from "../../helper/constants";
import { toast } from "react-toastify";
import './ListDocument.css';

export default function ListDocument({ submissionId }) {
    const submission_service = new Submission();
    const hiddenFileInput = useRef([]);
    const ctx = useAuth();
    const navigate = useNavigate();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    const [deadline, setDeadline] = useState({
        day: '0',
        hour: '0',
        minutes: '0',
    });

    const [isDeadline, setIsDeadline] = useState(true);

    const [modalVisible, setModalVisible] = useState(false);
    const [dokumenId, setDokumenId] = useState("");
    const [dokumen, setDokumen] = useState([]);
    const [pagination, setPagination] = useState({
        limit: 5,
        page: 1,
        total: 0,
    });

    // With dokumen
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5); //change to 10 after you remove paginationRowsPerPageOptions
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getDokumen();
    }, []);

    const getDokumen = async () => {
        setLoading(true);
        try {
            const resp = await submission_service.list_documents(submissionId);
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            handleDeadline(resp.data.deadline);
            setDokumen([...resp.data.list]);
            setPagination({ ...pagination, total: Number(resp.data.total) });
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        getDokumen(page, rowsPerPage);
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        if (!dokumen.length) return;
        getDokumen(1, newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const downloadFile = async (filePath) => {
        const file = await downloadReport(filePath);
        window.open(file);
    };

    const uploadDocument = async (e, documentId) => {
        const fileUploaded = e.target.files[0];
        if (fileUploaded.size > MAX_FILE_SIZE) {
            toast.error(
                "Maksimal file hanya 10MB, pastikan ukuran berkas tidak lebih dari 10MB"
            );
            return;
        }

        const fd = new FormData();
        fd.append("file", fileUploaded);

        try {
            setLoading(true);
            await submission_service.upload_document(
                submissionId,
                documentId,
                fd
            );

            toast.success("Data berhasil diupload");
            await getDokumen();
        } catch (e) {
            console.error("error: ", e);
            toast.error(e.message);
            setLoading(false);
        }
    };

    const renderIcon = (status) => {
        if (status) {
            return (
                <div className="text-success">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-check-circle"
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                    &nbsp;
                    <span className="pl-3 badge bg-success"> Uploaded</span>
                </div>
            );
        }

        return <></>;
    };

    const handleDeadline = (deadline) => {
        // Tanggal deadline awal
        var deadlineDate = new Date(deadline);
        deadlineDate.setDate(deadlineDate.getDate());

        // Tanggal sekarang
        var currentDate = new Date();

        // Selisih waktu antara tanggal sekarang dan tanggal deadline
        var diff = deadlineDate - currentDate;

        // Hitung jumlah hari, jam, dan menit dari selisih waktu
        var day = Math.floor(diff / (1000 * 60 * 60 * 24));
        var time = diff % (1000 * 60 * 60 * 24);
        var hour = Math.floor(time / (1000 * 60 * 60));
        time %= (1000 * 60 * 60);
        var minutes = Math.floor(time / (1000 * 60));

        // Format hasil
        if (day < 0) {
            return
        }
        var hasil = day + " hari " + hour + " jam " + minutes + " menit";
        console.log({ hasil });

        setIsDeadline(false);
        setDeadline({ day, hour, minutes });

    };

    const columns = useMemo(() => [
        {
            name: "No",
            width: "50px",
            selector: (row) => (
                <span className="col col-sm-1">
                    {pagination.page < 1 ? row.no : row.no}
                </span>
            ),
        },
        {
            name: "Nama Dokumen",
            selector: (row) => <span>{row?.dokumen?.nama}</span>,
        },
        {
            name: "Aksi",
            textAlign: "center",
            selector: (row) => {
                const isMahasiswa = user.level === 'MHS';
                return (
                    <div className="d-flex flex-row justify-between">
                        <button
                            disabled={!isMahasiswa && !row.status}
                            className="btn btn-dark text-white text-sm"
                            onClick={async () => {
                                const path = isMahasiswa ? row.dokumen.file_path : row.file_path;
                                await downloadFile(path);
                            }}
                        >
                            Unduh
                        </button>
                        <React.Fragment>
                            &nbsp;&nbsp;
                            <button
                                className={`btn text-white text-sm ml-3 btn-info ${(isMahasiswa && isDeadline) && 'disabled'}`}
                                onClick={() => {
                                    if (!loading) {
                                        hiddenFileInput.current[row.dokumen.id].click();
                                    }
                                }}
                            >
                                Unggah
                            </button>
                            <input
                                disabled={isMahasiswa ? isDeadline : false}
                                type="file"
                                ref={el => (hiddenFileInput.current[row.dokumen.id] = el)}
                                onChange={e => {
                                    if (isMahasiswa && isDeadline) {
                                        return false
                                    }
                                    uploadDocument(e, row.dokumen.id)
                                }}
                                style={{ display: "none" }}
                                accept=".pdf"
                            />
                        </React.Fragment>
                    </div>
                )
            },
        },
        {
            name: "Status",
            selector: (row) => (
                <div className="d-flex" style={{ width: 150 }}>
                    <div className="col-md-2" style={{ width: 200 }}>
                        {renderIcon(row.status)}
                    </div>
                </div>
            ),
        },
    ]);

    const deadlines = [
        {
            value: deadline.day,
            label: 'Hari'
        },
        {
            value: deadline.hour,
            label: 'Jam'
        },
        {
            value: deadline.minutes,
            label: 'Menit'
        }
    ]
    return (
        <React.Fragment>
            {user?.level === 'MHS' && (
                <React.Fragment>

                    <div className="d-flex justify-content-center">
                        <span className="btn btn-warning deadline text-white fw-bold text-uppercase">Batas Upload Dokumen</span>
                    </div>
                    <div className="d-flex flex-row mt-3 justify-content-center gap-2">
                        {
                            deadlines.map((dl, key) => (
                                <div className="time-wrapper rounded justify-content-center d-flex flex-column align-items-center" key={key}>
                                    <div className="time-deadline mt-2 h5 fw-bold rounded d-flex justify-content-center align-items-center flex-column text-white">
                                        {dl.value}
                                    </div>
                                    <div className="word fw-bold text-white">{dl.label} </div>
                                </div>
                            ))
                        }
                    </div>
                </React.Fragment>
            )}
            <DataTable
                pagination
                paginationServer
                columns={columns}
                data={dokumen?.length > 0 ? dokumen : []}
                progressPending={loading}
                paginationTotalRows={pagination.total}
                selectableRowsNoSelectAll={true} //I needed to remove the select all, because it would not work due to the mouse enter/leave workaround
                paginationDefaultPage={currentPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20]} //you can remove it later, just to have more pages
                paginationPerPage={rowsPerPage}
                onChangePage={handlePageChange}
                customStyles={TABLE_STYLE}
                onChangeRowsPerPage={handleRowsPerPageChange}
            />
        </React.Fragment>
    );
}
