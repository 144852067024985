import React from "react";
import { BackButton, Footer, Input, ModalPopup, Navbar } from "../../../components";
import { useAuth } from "../../../util/ProtectedRoute";
import { useMemo } from "react";
import { Mahasiswa as MahasiswaService} from "../../../services";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { GENDER_LIST, TABLE_STYLE } from "../../../helper/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDate } from "../../../helper/utils";
import { debounce } from "../../../util/debounce";

export default function Mahasiswa() {
    const mahasiswa_service = new MahasiswaService();
    const ctx = useAuth();
    const navigate = useNavigate();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    const [modalVisible, setModalVisible] = useState(false);
    const [mahasiswaId, setMahasiswaId] = useState('');
    const [keyword, setKeyword] = useState('');
    const [references, setReferences] = useState([]);
    const [pagination, setPagination] = useState({
        limit: 5,
        page: 1,
        total: 0,
    });
    // With references
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5); //change to 10 after you remove paginationRowsPerPageOptions
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getMahasiswa(pagination.page, pagination.limit);
    }, []);

    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
        getMahasiswa(1, pagination.limit, keyword);
    }, [keyword]);

    const getMahasiswa = async (page, limit, keyword = '') => {
        setLoading(true);
        try {
            const resp = await mahasiswa_service.list(page, limit, '', false, keyword);
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            setReferences([...resp.data.list]);
            setPagination({ ...pagination, total: Number(resp.data.total) });
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        getMahasiswa(page, rowsPerPage);
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        if (!references.length) return;
        getMahasiswa(1, newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const columns = useMemo(() => [
        {
            name: "No",
            width: "50px",
            selector: (row) => (
                <span className="col col-sm-1">
                    {pagination.page < 1 ? row.no : row.no}
                </span>
            ),
        },
        {
            name: "NIM",
            width: '150px',
            selector: (row) => <span>{row?.nim}</span>,
        },
        {
            name: "Nama",
            selector: (row) => <span>{row?.nama}</span>,
        },
        {
            name: "Jurusan",
            selector: (row) => <span>{row?.jurusan?.nama}</span>,
        },        
        {
            name: "Tahun Masuk",
            selector: (row) => <span>{row?.tahun_masuk}</span>,
        },
        {
            name: "Jenis Kelamin",
            selector: (row) => <span>{GENDER_LIST.find(gender => gender.value === row?.jenis_kelamin)?.label}</span>,            
        },
        {
            name: "Tempat Lahir",
            selector: (row) => <span>{row?.tempat_lahir}</span>,
        },
        {
            name: "Tanggal Lahir",
            selector: (row) => <span>{formatDate(row?.tanggal_lahir, false)}</span>,
        },
        {
            name: "Alamat",
            selector: (row) => <span>{row?.alamat}</span>,
        },
        {
            name: "No Telp",
            selector: (row) => <span>{row?.no_telp}</span>,
        },
        {
            name: "Status",
            selector: (row) => <span>{row?.user?.status}</span>,
        },
        {
            name: "Aksi",
            selector: (row) => (
                <div className="d-flex flex-column">
                    <span className="btn btn-dark text-white text-sm" onClick={() => navigate(`/management/mahasiswa/${row.id}?action=read`)}>Lihat</span>
                    <span className="btn btn-danger text-white text-sm" onClick={() => navigate(`/management/mahasiswa/${row.id}?action=update`)}>Ubah</span>
                    <span className="btn btn-danger text-white text-sm" onClick={() => {
                        setModalVisible(!modalVisible);
                        setMahasiswaId(row.id);
                    }}>Hapus</span>
                </div>
            ),

        }
    ]);

    const deleteMahasiswa = async () => {
        try {
            await mahasiswa_service.delete(mahasiswaId);
            toast.success('Data berhasil dihapus');
            getMahasiswa(pagination.page, pagination.limit);
            setModalVisible(!modalVisible);
        } catch(e) {
            console.log(e);
            toast.error(e);
        }
    }
    return (
        <React.Fragment>
            <ModalPopup visible={modalVisible} onClose={() => setModalVisible(!modalVisible)} height={150} width={500}>
                <label>Kamu yakin ingin menghapus mahasiswa ini?</label>
                <div className="text-end mt-5">
                    <button type="button" className="btn btn-info mx-3" onClick={() => deleteMahasiswa()}>Ya</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setModalVisible(!modalVisible)}>Batal</button>
                </div>
            </ModalPopup>
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>
                <div className="text-end">
                    <Input
                        type="text"
                        placeholder="Cari..."
                        style={{ width: 300, display: "inline" }}
                        className="my-3"
                        onChangeValue={debounce((val) => setKeyword(val), 500)}
                    />

                    <input
                        type="button"
                        className="btn bg-green"
                        value="Tambah mahasiswa"
                        onClick={() => navigate(`/management/mahasiswa/add?action=add`)}
                    />
                </div>
            </div>
            <DataTable
                pagination
                paginationServer
                columns={columns}
                data={references?.length > 0 ? references : []}
                progressPending={loading}
                paginationTotalRows={pagination.total}
                selectableRowsNoSelectAll={true} //I needed to remove the select all, because it would not work due to the mouse enter/leave workaround
                paginationDefaultPage={currentPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20]} //you can remove it later, just to have more pages
                paginationPerPage={rowsPerPage}
                onChangePage={handlePageChange}
                customStyles={TABLE_STYLE}
                onChangeRowsPerPage={handleRowsPerPageChange}
            />

            <Footer />
        </React.Fragment>
    );
}
