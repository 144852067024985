import { getAuthorization } from "../headers";

export default class Users {
    update_user = async (payload) => {
        const p = { ...payload };
        delete p.email;
        delete p.nim;

        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/user/current`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    ...headers,
                },
                body: JSON.stringify(p),
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    update_avatar = async (payload) => {
      const headers = await getAuthorization();
      return new Promise((resolve, reject) => {
          fetch(`${process.env.REACT_APP_URL_SERVICE}/user/avatar`, {
              method: "PUT",
              headers: {
                  ...headers,
              },
              body: payload,
          })
              .then((res) => {
                  res.json().then((data) => {
                      if (data.status && data.status === 200) {
                          return resolve(data);
                      }
                      reject(data);
                  });
              })
              .catch((err) => {
                  reject(err);
              });
      });
  };
}
