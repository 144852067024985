import { getAuthorization } from "../headers";

export default class Config {
    list = async (type) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/config?type=${type}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
            })            
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }

    detail = async (id) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/config/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }   

    update = async (payload) => {        
        const headers = await getAuthorization();
        const data = { ...payload };
        delete data.id;

        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/config/${payload.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                },
                method: "PUT",
                body: JSON.stringify(data),
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }   

    
    add = async (payload) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {
            const fd = new FormData();
            for (const [key, value] of Object.entries(payload)) {
                fd.append(key, value);
            }
            fetch(`${process.env.REACT_APP_URL_SERVICE}/config`, {
                headers,
                method: "POST",
                body: fd,
            })
                .then((res) => {
                    res.json().then((data) => {
                        if (data.status && data.status === 200) {
                            return resolve(data);
                        }
                        reject(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    
    delete = async (id) => {        
        const headers = await getAuthorization();
        return new Promise((resolve, reject) => {            
            fetch(`${process.env.REACT_APP_URL_SERVICE}/config/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers
                }
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    }
}