import React from "react";
import { BackButton, Footer, Input, Navbar } from "../../../components";
import { useAuth } from "../../../util/ProtectedRoute";
import { useMemo } from "react";
import { Config as ConfigService } from "../../../services";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { CONFIG_TYPE, TABLE_STYLE } from "../../../helper/constants";
import { useNavigate } from "react-router-dom";

export default function Page() {
    const config_service = new ConfigService();
    const ctx = useAuth();
    const navigate = useNavigate();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    const [dokumen, setDokumen] = useState([]);    

    // With dokumen
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = async () => {
        setLoading(true);
        try {
            const resp = await config_service.list(CONFIG_TYPE.page);
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            setDokumen([...resp.data.list]);
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };    

    const columns = useMemo(() => [
        {
            name: "No",
            width: "50px",
            selector: (row) => (
                <span className="col col-sm-1">
                    {row.no}
                </span>
            ),
        },
        {
            name: "Nama Halaman",
            selector: (row) => <span>{row?.item?.name}</span>,
        },       
        {
            name: "Aksi",
            selector: (row) => (
                <div className="d-flex flex-row justify-between">
                    <span 
                        className="btn btn-dark text-white text-sm ml-3" 
                        onClick={() => navigate(`/management/page/${row.id}?action=read`)}>Lihat</span>
                    &nbsp;
                    <span 
                        className="btn bg-purple text-white text-sm ml-3" 
                        onClick={() => navigate(`/management/page/${row.id}?action=update`)}>Ubah</span>
                </div>
            ),

        }
    ]);
    
    return (
        <React.Fragment>            
            <Navbar isLandingPage={false} isLogin={true} user={user} />
            <div
                className={`col-md-12 d-grid bg-blue justify-content-center align-items-center text-white welcome text-uppercase`}
                style={{ fontSize: 16 }}
            >
                <label>
                    Selamat Datang {user?.fullname} Di Chemons Teknik Kimia
                    Universitas Indonesia
                </label>
            </div>
            <div className="container">
                <div className="my-3">
                    <BackButton />
                </div>
                <div className="text-end">
                    <Input
                        type="text"
                        placeholder="Cari..."
                        style={{ width: 300, display: "inline" }}
                        className="my-3"
                    />                    
                </div>
            </div>
            <DataTable
                columns={columns}
                data={dokumen?.length > 0 ? dokumen : []}
                progressPending={loading}                
                customStyles={TABLE_STYLE}
            />

            <Footer />
        </React.Fragment>
    );
}
