export default class Auth {
    login = async (payload) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    };
    register = async (payload) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    };
    submit_biodata = async (registerId, payload) => {
        return new Promise((resolve, reject) => {
            payload.batch_year = parseInt(payload.batch_year);

            fetch(`${process.env.REACT_APP_URL_SERVICE}/register/biodata/${registerId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    };
    check_user_token = async (token) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/user/current`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    };
    reset_password = async (payload) => {
        return new Promise((resolve, reject) => {

            fetch(`${process.env.REACT_APP_URL_SERVICE}/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    };
    reset_token = async (token) => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/reset-token/${token}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    };
    set_password = async (payload) => {
        const { user_id } = payload;
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_URL_SERVICE}/set-password/${user_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            })
            .then(res => {
                res.json()
                .then(data => {
                    if(data.status && data.status === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        })
    };




}