import React from "react";
import {
    BackButton,
    CKEditor,
    Footer,
    Input,
    ModalPopup,
    Navbar,
    Select,
} from "../../components";
import { useAuth } from "../../util/ProtectedRoute";
import { useState } from "react";
import { useEffect } from "react";
import { BidangPenelitian, Reference, Submission as SubmissionService } from "../../services";
import { toast } from "react-toastify";
import {
    RESEARCH_SCHEMAS,
} from "../../helper/constants";
import { useNavigate } from "react-router-dom";
import { onKeyPress } from "../../helper";

export default function Submission({ type }) {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const STATE_NOT_REQUIRED = [
        "research_plan",
        "title_en",
        "abstract_id",
        "abstract_en",
    ];
    const STATE_FOR_REPEATERS = ["last_guiding_prof", "retake_reason"];
    const riset_grup = new BidangPenelitian();
    const reference = new Reference();
    const submission = new SubmissionService();
    const ctx = useAuth();
    const { user } = ctx;
    if (!user) {
        window.location.reload();
    }

    const [payload, setPayload] = useState({
        type: type,
        irs: null, // form upload
        ipk: "",
        sks: "",
        research_space: "",
        research_topic: "",
        research_plan: "",
        title_id: "",
        title_en: "",
        research_schema: "",
        abstract_id: "",
        abstract_en: "",
        guiding_prof_1: "",
        guiding_prof_2: "",
        take_no: "",
        last_guiding_prof: "",
        retake_reason: "",
    });

    const [dosen, setDosen] = useState(null);
    const [risetGrup, setRisetGrup] = useState(null);
    const [dosenGuidingProfList, setDosenGuidingProfList] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => getRisetGrup())();
        (async () => getDosenList(""))();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getDosenList = async (researchSpace = "") => {
        try {
            const resp = await reference.dosen_list("", "", "", researchSpace);
            let data = [
                {
                    value: "",
                    label: "Tidak ada pilihan",
                },
            ];
            data = [
                ...data,
                ...resp.data.list.map((item) => ({
                    value: item.id,
                    label: item.nama,
                    nidn: item.nidn,
                })),
            ];
            if (!researchSpace) {
                setDosen([...data]);
            }

            return data;
        } catch (e) {
            console.log({ e });
        }
    };

    const getRisetGrup = async () => {
        try {
            const resp = await reference.research_space(1, 10000);
            let data = [
                {
                    value: "",
                    label: "Tidak ada pilihan",
                },
            ];
            data = [
                ...data,
                ...resp.data.list.map((item) => ({
                    value: item.id,
                    label: item.name,
                })),
            ];
            setRisetGrup([...data])

            return data;
        } catch (e) {
            console.log({ e });
        }
    };

    const handlePayload = (state, value) => {
        setPayload({ ...payload, [state]: value });
    };

    const getDosenByResearchSpace = async (researchSpace) => {
        const dosenGuidingProf = await getDosenList(researchSpace);
        setDosenGuidingProfList(dosenGuidingProf);
    };

    const showTnc = () => {
        setShowModal(!showModal);
    };

    const RenderForm = (form) => {
        const { type, label, state, options } = form;
        const fontSize = 10;
        switch (type) {
            case "text":
            case "number":
            case "file":
                return (
                    <Input
                        {...(type !== "file" ? { value: payload[state] } : {})}
                        label={label}
                        type={type === 'number' ? 'text' : type}
                        {...(type === "number" ? { onKeyPress: onKeyPress } : {})}
                        onChangeValue={(value) => {
                            const val = type === 'number' && value.length > 0 && value[value.length - 1] !== '.' ? Number(value) : value;
                            handlePayload(state, val)
                        }}
                        {...(type == "file" ? { accept: 'image/jpeg,image/jpg,image/png' } : {})}

                    >
                        {/* {STATE_NOT_REQUIRED.includes(state) && (
                            <label
                                className="text-secondary text-sm-start"
                                style={{ fontSize }}
                            >
                                *Bisa Menyusul
                            </label>
                        )} */}
                        {STATE_FOR_REPEATERS.includes(state) && (
                            <label
                                className="text-secondary text-sm-start"
                                style={{ fontSize }}
                            >
                                *Bagi yang mengulang
                            </label>
                        )}
                        {state === "irs" && (
                            <label
                                className="text-secondary text-sm-start"
                                style={{ fontSize }}
                            >
                                *Unggah bukti IRS Siak-NG dalam format jpg,jpeg,
                                png atau PDF (cukup dengan screenshot, foto atau
                                pdf). Contoh bukti IRS di SIAK-NG{" "}
                                <a
                                    className="text-blue"
                                    style={{ cursor: "pointer" }}
                                    onClick={showTnc}
                                >
                                    klik disini.
                                </a>
                            </label>
                        )}
                    </Input>
                );
            case "ckeditor":
                return (
                    <CKEditor
                        value={payload[state]}
                        label={label}
                        onChangeValue={(value) => handlePayload(state, value)}
                    >
                        {/* {STATE_NOT_REQUIRED.includes(state) && (
                            <label className="text-secondary text-sm-start">
                                *Bisa Menyusul
                            </label>
                        )} */}
                        {STATE_FOR_REPEATERS.includes(state) && (
                            <label className="text-secondary text-sm-start">
                                *Bagi yang mengulang
                            </label>
                        )}
                    </CKEditor>
                );
            case "select":
                return (
                    <Select
                        required={state !== "last_guiding_prof"}
                        options={
                            state === "guiding_prof_1"
                                ? dosenGuidingProfList
                                : options
                        }
                        label={label}
                        onChangeValue={(value) => {
                            handlePayload(state, value);
                            if (state === "research_space") {
                                getDosenByResearchSpace(value);
                            }
                        }}
                    />
                );

            default:
                return <></>;
        }
    };

    const enabledButton = () => {
        const formRequired = { ...payload };

        if (Number(payload["take_no"]) === 1) {
            delete formRequired.retake_reason;
            delete formRequired.last_guiding_prof;
        }

        delete formRequired.guiding_prof_2

        const payloadIsValid = Object.values(formRequired).every(
            (v) => v !== "" && v !== null
        );
        return payloadIsValid;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!enabledButton()) {
            return;
        }
        try {
            setLoading(true);
            const resp = await submission.submit_submission(payload);
            if (resp.status === 200) {
                toast.success("Data berhasil dikirim");
                navigate("/homepage");
            }
        } catch (e) {
            console.error({ e });
            toast.error(e.message);
        }

        setLoading(false);
    };

    const forms = [
        {
            label: "IPK Terakhir",
            type: "number",
            state: "ipk",
        },
        {
            label: "Jumlah SKS Lulus",
            type: "number",
            state: "sks",
        },
        {
            label: "Bidang Penelitian",
            type: "select",
            state: "research_space",
            options: risetGrup?.length > 0 ? risetGrup : [{}]
        },
        {
            label: "Topik Yang Diajukan",
            type: "text",
            state: "research_topic",
        },
        {
            label: "Deskripsi Rencana Penelitian",
            type: "ckeditor",
            state: "research_plan",
        },
        {
            label: "Judul Dalam Bahasa Indonesia",
            type: "text",
            state: "title_id",
        },
        {
            label: "Judul Dalam Bahasa Inggris",
            type: "text",
            state: "title_en",
        },
        {
            label: "Skema Penelitian",
            type: "select",
            state: "research_schema",
            options: RESEARCH_SCHEMAS,
        },
        {
            label: "Abstrak Dalam Bahasa Indonesia",
            type: "ckeditor",
            state: "abstract_id",
        },
        {
            label: "Abstrak Dalam Bahasa Inggris",
            type: "ckeditor",
            state: "abstract_en",
        },
        {
            label: "Dosen Pembimbing 1",
            type: "select",
            state: "guiding_prof_1",
            options: dosen,
        },
        {
            label: "Dosen Pembimbing 2",
            type: "select",
            state: "guiding_prof_2",
            options: dosen,
        },
        {
            label: "Kepala Laboratorium",
            type: "select",
            state: "lead_prof",
            options: dosen,
        },
        {
            label: "Pengambilan Ke",
            type: "number",
            state: "take_no",
        },
        {
            label: "Dosen Pembimbing Sebelumnya",
            type: "select",
            state: "last_guiding_prof",
            options: dosen,
        },
        {
            label: "Alasan Mengulang",
            type: "text",
            state: "retake_reason",
        },
        {
            label: "Unggah bukti pengisian IRS Matakuliah Spesial di Siak -NG",
            type: "file",
            state: "irs",
        },
    ];

    return (
        <React.Fragment>
            <Navbar isLandingPage={false} isLogin={true} user={user} />

            <div className="container my-3">
                <ModalPopup
                    visible={showModal}
                    onClose={() => setShowModal(!showModal)}
                >
                    <img src="/siang.jpeg" className="mt-5 img img-thumbnail" />
                </ModalPopup>
                <BackButton />
                {forms.map((form, key) => {
                    return (
                        <React.Fragment key={key}>
                            {RenderForm(form)}
                        </React.Fragment>
                    );
                })}
                <div className="row">
                    <button
                        onClick={onSubmit}
                        disabled={!enabledButton() || loading}
                        type="button"
                        className="row btn btn-info text-white fw-bold"
                    >
                        KIRIM
                    </button>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}
