import DataTable from "react-data-table-component";
import { Input } from "../../Form";
import "./SBA.css";
import React, { useEffect, useMemo, useState } from "react";
import { SBA as SBAService, Submission, Reference } from "../../../services";
import { formatDate, useDebounce } from "../../../helper/utils";
import { Link } from "react-router-dom";
import { SUBMISSION_TYPE, TABLE_STYLE } from "../../../helper/constants";
import ModalPopup from "../../ModalPopup";
import { toast } from "react-toastify";
import Filter from "./components";
import moment from "moment";

export default function SBA() {
    const sba_service = new SBAService();
    const submission = new Submission();
    const reference = new Reference();

    const [modalVisible, setModalVisible] = useState(false);
    const [submissionId, setSubmissionId] = useState("");
    const [submissions, setSubmissions] = useState([]);
    const [studyProgram, setStudyProgram] = useState(null);
    const [stat, setStat] = useState(null);
    const [filter, setFilter] = useState({
        from: '',
        until: '',
        study_program: '',
        type: '',
        research_space: '',
        limit: 10,
        page: 1
    });

    const [pagination, setPagination] = useState({
        limit: 5,
        page: 1,
        total: 0,
    });
    // With references
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5); //change to 10 after you remove paginationRowsPerPageOptions
    const [currentPage, setCurrentPage] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [risetGrup, setRisetGrup] = useState(null);

    const debounceSearchTerm = useDebounce(keyword, 500);
    useEffect(() => {
        const getDashboardStat = async () => {
            try {
                const resp = await sba_service.dashboard_stat();
                setStat(resp.data);
            } catch (e) {
                console.error(e);
            }
        };

        getDashboardStat();
        getSubmissions(1, 5);
        getStudyProgramList();
        getRisetGrup();
    }, []);

    useEffect(() => {
        getSubmissions(1, rowsPerPage, '', debounceSearchTerm)
        setCurrentPage(1)
    }, [debounceSearchTerm]);

    const getStudyProgramList = async () => {
        try {
            const resp = await reference.study_program_list();
            const sp = resp.data.list.map((item) => ({
                value: item.id,
                label: item.nama,
                code: item.kode,
            }));

            console.log({ sp });
            setStudyProgram([...sp]);
        } catch (e) {
            console.log({ e });
        }
    };

    const getSubmissions = async (page, limit, type = "", keyword = "") => {
        setLoading(true);
        try {
            const resp = await submission.list(page, limit, type, '', '', keyword);
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            setSubmissions([...resp.data.list]);
            setPagination({ ...pagination, total: Number(resp.data.total) });
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };

    const getSubmissionsWithFilter = async (filter) => {
        setLoading(true);
        try {
            const resp = await submission.list_with_filter(filter);
            resp.data.list = [
                ...resp.data.list.map((item, key) => {
                    return {
                        ...item,
                        no: key + 1,
                    };
                }),
            ];

            console.log("[getSubmissionsWithFilter] resp: ", resp);
            setSubmissions([...resp.data.list]);
            setPagination({ ...pagination, total: Number(resp.data.total) });
            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };
    const getRisetGrup = async () => {
        try {
            const resp = await reference.research_space(1, 10000);
            let data = [
                {
                    value: "",
                    label: "Tidak ada pilihan",
                },
            ];
            data = [
                ...data,
                ...resp.data.list.map((item) => ({
                    value: item.id,
                    label: item.name,
                })),
            ];
            setRisetGrup([...data])

            return data;
        } catch (e) {
            console.log({ e });
        }
    };
    const downloadFile = async (fileType) => {
        try {
            let filename = 'Data Export';
            let startDate, endDate, label = '';
            const { from, until, type } = filter;

            if (from && until) {
                startDate = moment(from).format('YYMMDD');
                endDate = moment(until).format('YYMMDD');
                filename = `${filename}_${startDate}-${endDate}`;
            }

            if (type) {
                label = `DATA_${type}`;
                filename = `${label}`
            }

            if (from && until && type) {
                filename = `${label}_${startDate}-${endDate}.${fileType}`;
            }

            const blobData = await submission.downloadFile(filter, fileType);
            const blobUrl = URL.createObjectURL(blobData);
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(blobUrl);

            setLoading(false);
        } catch (e) {
            console.log({ e });
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        getSubmissions(page, rowsPerPage, '', keyword);
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        if (!submissions.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        getSubmissions(1, newRowsPerPage, '', keyword);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const deleteSubmission = async () => {
        try {
            await submission.delete(submissionId);
            toast.success("Data berhasil dihapus");
            getSubmissions(pagination.page, pagination.limit, '', keyword);
            setModalVisible(!modalVisible);
        } catch (e) {
            console.log(e);
            toast.error(e);
        }
    };

    const cards = [
        {
            title: "Seminar Skripsi",
            total: stat?.seminar_count || 0,
            path: "",
            color: "#1FB5AD",
            onClick: () => {
                getSubmissions(
                    pagination.page,
                    pagination.limit,
                    SUBMISSION_TYPE.THESIS_SEMINAR
                );
            },
        },
        {
            title: "Skripsi",
            total: stat?.sidang_count || 0,
            path: "",
            color: "#FA8564",
            onClick: () => {
                getSubmissions(
                    pagination.page,
                    pagination.limit,
                    SUBMISSION_TYPE.THESIS
                );
            },
        },
        {
            title: "Mahasiswa",
            total: stat?.mahasiswa_count || 0,
            path: "/management/mahasiswa",
            color: "#3FC5FF",
            onClick: () => {
                console.log("cliked");
            },
        },
        {
            title: "Dosen",
            total: stat?.dosen_count || 0,
            path: "/management/dosen",
            color: "#9466B5",
            onClick: () => {
                console.log("cliked");
            },
        },
    ];

    const renderIcon = (type) => {
        if (type) {
            return (
                <div className="text-success">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-check-circle"
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                </div>
            );
        }

        return (
            <div className="text-danger">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x-circle"
                    viewBox="0 0 16 16"
                >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
        );
    };

    const isDisabled = (status) => {
        return status !== "DITOLAK";
    };

    const handleSearch = e => {
        setKeyword(e.target.value);
    }
    const columns = useMemo(() => [
        {
            name: "No",
            width: "50px",
            selector: (row) => <span className="col col-sm-1">{row.no}</span>,
        },
        {
            name: "Tanggal Pengajuan",
            width: "130px",
            selector: (row) => (
                <span className="col col-lg-1">
                    {formatDate(row.created_at, false)}
                </span>
            ),
        },
        {
            name: "Nama Mahasiswa",
            selector: (row) => (
                <span className="col col-lg-1">{row.mahasiswa?.nama}</span>
            ),
        },
        {
            name: "NPM",
            width: "130px",
            selector: (row) => (
                <span className="col col-lg-1">{row.mahasiswa?.nim}</span>
            ),
        },
        {
            name: "Program Studi",
            selector: (row) => (
                <span className="col col-lg-1">{row.mahasiswa?.jurusan}</span>
            ),
        },
        {
            name: "Judul",
            width: "130px",
            selector: (row) => (
                <span className="col col-lg-1">{row.judul_id}</span>
            ),
        },
        {
            name: "Jenis Matakuliah Spesial",
            width: "130px",
            selector: (row) => <span className="col col-lg-1">{row.tipe}</span>,
        },
        {
            name: "Status",
            width: "130px",
            selector: (row) => (
                <span className="col col-lg-1">{row.status}</span>
            ),
        },
        {
            name: "Penguji",
            width: "130px",
            selector: (row) => (
                <div className="d-flex" style={{ width: 150 }}>
                    <div className="col-md-2">
                        {renderIcon(row.dosen_uji1?.status === "DISETUJUI")}
                    </div>
                    <div className="col-md-2">
                        {renderIcon(row.dosen_uji2?.status === "DISETUJUI")}
                    </div>
                </div>
            ),
        },
        {
            name: "",
            selector: (row) => (
                <div className="d-flex flex-column">
                    <Link
                        className="btn btn-dark bg-dark"
                        to={`/sba/pengajuan-seminar-skripsi/${row.id}`}
                    >
                        Lihat
                    </Link>
                    <button
                        className={`btn btn-danger bg-danger ${isDisabled(row.status) && "disabled"
                            }`}
                        disabled={isDisabled(row.status)}
                        onClick={() => {
                            if (isDisabled(row.status)) {
                                return;
                            }
                            setModalVisible(!modalVisible);
                            setSubmissionId(row.id);
                        }}
                    >
                        Hapus
                    </button>
                </div>
            ),
        },
    ]);

    return (
        <div className="container my-2">
            <ModalPopup
                visible={modalVisible}
                onClose={() => setModalVisible(!modalVisible)}
                height={150}
                width={500}
            >
                <label>Kamu yakin ingin menghapus Pengajuan ini?</label>
                <div className="text-end mt-5">
                    <button
                        type="button"
                        className="btn btn-info mx-3"
                        onClick={() => deleteSubmission()}
                    >
                        Ya
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setModalVisible(!modalVisible)}
                    >
                        Batal
                    </button>
                </div>
            </ModalPopup>
            <div className="cards d-flex flex-row justify-content-between flex-wrap">
                {cards.map((card, key) => (
                    <div
                        className="card"
                        style={{
                            background: card.color,
                            height: 165,
                            width: 300,
                        }}
                        key={key}
                    >
                        <div className="card-body">
                            <div className="text-center">
                                <label className="title  h4 text-white text-uppercase">
                                    {card.title}
                                </label>
                                <br />
                                <label className="total h1 text-white fw-bold py-2">
                                    {card.total}
                                </label>
                                <br />
                            </div>
                            <div
                                className="text-end text-blue"
                                style={{ cursor: "pointer" }}
                            >
                                <Link to={card.path} onClick={card.onClick}>
                                    <label className="view-all">View All</label>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* search box and export button*/}
            <div className="d-flex justify-content-end align-items-right my-3">
                <input
                    type="text"
                    placeholder="Cari..."
                    style={{ width: 400, display: "inline" }}
                    className="mx-3 form-control"
                    onChange={handleSearch}
                />
                <button
                    type="button"
                    className="btn bg-bluesky text-white fw-bold me-2"
                    onClick={() => setShowFilter(!showFilter)}
                >
                    Filter
                </button>
                <button
                    className="btn bg-bluesky"
                    type="button"
                    id="exportButton"
                    onClick={() => downloadFile("xlsx")}
                >
                    Export Data
                </button>

            </div>

            {showFilter && (
                <div className="d-flex justify-content-end align-items-right my-3">
                    <div className="bg-default">
                        <Filter
                            risetGrup={risetGrup}
                            studyProgram={studyProgram}
                            limit={pagination.limit}
                            page={pagination.page}
                            onSubmitFilter={(filter) => {
                                setFilter({ ...filter });
                                getSubmissionsWithFilter(filter);
                            }}
                        />
                    </div>
                </div>
            )}
            {/* Data table */}
            <DataTable
                pagination
                paginationServer
                columns={columns}
                data={submissions?.length > 0 ? submissions : []}
                progressPending={loading}
                paginationTotalRows={pagination.total}
                selectableRowsNoSelectAll={true} //I needed to remove the select all, because it would not work due to the mouse enter/leave workaround
                paginationDefaultPage={currentPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20]} //you can remove it later, just to have more pages
                paginationPerPage={rowsPerPage}
                onChangePage={handlePageChange}
                customStyles={TABLE_STYLE}
                onChangeRowsPerPage={handleRowsPerPageChange}
            />
        </div>
    );
}
