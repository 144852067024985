import React from "react";
import "./Dosen.css";
import book from "../../../images/book.png";
import {DaftarBimbingan, DaftarSidang} from "./components";


export default function Dosen({ user }) {
    return (
        <div className="my-2">
            <div className="d-flex flex-row justify-content-around flex-wrap gap-2">
                <div className="col-md-12 row">
                    <div className="col-md-6">
                        <div className="card shadow-lg">
                            <div className="text-center py-3 card-mahasiswa bg-purple">
                                <img src={book} alt="book" />
                                <br />
                                <label
                                    className="text-white"
                                    style={{ fontSize: 40 }}
                                >
                                    Daftar Bimbingan
                                </label>
                            </div>
                            <div className="card-body text-center">                            
                                <DaftarBimbingan/>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card shadow-lg">
                            <div className="bg-green text-center py-3 card-mahasiswa">
                                <img src={book} alt="book" />
                                <br />
                                <label
                                    className="text-white"
                                    style={{ fontSize: 40 }}
                                >
                                    Daftar Sidang
                                </label>
                            </div>
                            <div className="card-body text-center">
                                <DaftarSidang/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
